<app-navbar-one></app-navbar-one>
<div *ngIf="load">    <div class="container-fluid">
        <div class="row justify-content-center">
            <div class="col-lg-12 m-3 lds-ripple"><div></div><div></div></div>
        </div>
    </div>
</div>
<div *ngIf="!load">


<div id="mainContent" class="">
    <body>
        <div>
            <div class="pdtop" *ngIf="error">
                <div class="container-fluid">
                    <div class="row justify-content-center">
                        <div class="col-lg-12 m-3 lds-ripple"><div></div><div></div></div>
                    </div>
                </div>
            </div>
            <div dir="ltr" *ngIf="detailJob1">
                <div class="css-qjy8kv">
                    <div class="css-gj3t6y">
                        <div class="css-1vosg8">
                            <div class="css-141ntgo">                  
                                <div class="container">
                                    <div class="col-lg-8">
                                        <div class="css-gk87zv">
                                            <div class="css-e23il0">
                                                <div class="css-mm5m1q">
                                                    <div class="css-xhbetn">
                                                        <h2 class="css-7papts">
                                                            {{detailJob1.title}}
                                                        </h2>
                                                        <div class="css-37urdo">
                                                            <div class="css-ipzqjw">
                                                                <div class="css-b3pn3b">
                                                                    <div class="css-b3pn3b">
                                                                        <a class="css-16xevfn" (click)="enableApply()">Apply</a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            
                                                            <div class=" row css-1dw6sgr" *ngIf="apply">
                                                                <div class="css-1x2kh9a col-lg-12 m-3">
                                                                    <input class="form-control css-19qf6mn" [(ngModel)]="full_name" placeholder="Full name">
                                                                </div>
                                                                <div class="css-1x2kh9a col-lg-12 m-3">
                                                                    <input class="form-control css-19qf6mn" [(ngModel)]="phone" placeholder="Phone">
                                                                </div>
                                                                <div class="css-1x2kh9a col-lg-12 m-3">
                                                                    <input class="form-control css-19qf6mn" [(ngModel)]="email" placeholder="E-mail">
                                                                </div>
                                                                <div class="css-1x2kh9a col-lg-12 m-3">
                                                                    <input type="file" name="myFile" (change)="fileEvent2($event)"  class="form-control mb-4 mb-md-0"/>
                                                                </div>
                                                                <div class="css-1x2kh9a col-lg-12 m-3">
                                                                    <input type="file" name="myFile" (change)="fileEvent2($event)"  class="form-control mb-4 mb-md-0"/>
                                                                </div>
                                                                <div class="css-1x2kh9a col-lg-12 m-3">
                                                                    <input type="file" name="myFile" (change)="fileEvent2($event)"  class="form-control mb-4 mb-md-0"/>
                                                                </div>
                                                                <div class="css-1x2kh9a col-lg-12 m-3">
                                                                    <input type="file" name="myFile" (change)="fileEvent2($event)"  class="form-control mb-4 mb-md-0"/>
                                                                </div>
                                                                <div class="css-1x2kh9a col-lg-12 m-3">
                                                                    <input type="file" name="myFile" (change)="fileEvent2($event)"  class="form-control mb-4 mb-md-0"/>
                                                                </div>
                                                                <div class="css-b3pn3b">
                                                                    <a class="css-16xevfn" (click)="onSubmitform()">Send</a>
                                                                </div>
                                                                <h6 class="css-7papts">
                                                                    {{errorM}}
                                                                </h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="css-11p01j8">
                                                    <div class="css-1pv4c4t">
                                                        <div class="css-cygeeu">
                                                            <div class="css-k008qs">
                                                                <dl>
                                                                    <dt class="css-y8qsrx">
                                                                            
                                                                    </dt>
                                                                    <dd class="css-129m7dg">
                                                                        <div class="css-ey7qxc">
                                                                            <div class="css-8idjik1">
                                                                                <h2>Job tiltle: {{detailJob1.title}}</h2>
                                                                            </div>
                                                                        </div>
                                                                    </dd>
                                                                </dl>
                                                            </div>
                                                        </div>
                                                        <div class="css-cygeeu">
                                                            <div class="css-k008qs">
                                                                <div class="css-kij4qr">
                                                                    <span class="css-wwkk48"><i class="flaticon-maps-and-flags"></i></span>
                                                                </div>
                                                                <dl>
                                                                    <dd class="css-129m7dg">
                                                                        Location: {{detailJob1.location}}
                                                                    </dd>
                                                                </dl>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <div class="css-19idom">
                                                                <div class="css-k008qs">
                                                                    <div class="css-kij4qr">
                                                                        <span class="css-wwkk48"><img src="../../assets/img/work.png" width="20" height="20"></span>
                                                                    </div>
                                                                    <dl>
                                                                        <dd class="css-129m7dg">
                                                                            {{detailJob1.type2+" "+detailJob1.category}}
                                                                        </dd>
                                                                    </dl>
                                                                </div>
                                                            </div>
                                                            <div class="css-19idom">
                                                                <div class="css-k008qs">
                                                                    <div class="css-kij4qr">
                                                                        <span class="css-wwkk48"><img src="../../assets/img/time.png" width="20" height="20"></span>
                                                                    </div>
                                                                    <dl>
                                                                        <dd class="css-129m7dg">
                                                                            {{detailJob1.type1}}
                                                                        </dd>
                                                                    </dl>
                                                                </div>
                                                            </div>
                                                            <div class="css-19idom">
                                                                <div class="css-k008qs">
                                                                    <div class="css-kij4qr">
                                                                        <span class="css-wwkk48"><img src="../../assets/img/id.jpg" width="20" height="20"></span>
                                                                    </div>
                                                                    <dl>
                                                                        <dt class="css-y8qsrx">
                                                                            job requisition id
                                                                        </dt>
                                                                        <dd class="css-129m7dg">
                                                                            {{detailJob1.id_car}}
                                                                        </dd>
                                                                    </dl>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="css-ey7qxc">
                                                        <div class="css-8idjik1">
                                
                                                                <h2><strong>Job Area:</strong></h2>
                                                                <div style="padding-bottom: 6px;"></div>
                                                                <div [innerHTML]="detailJob1.job_area"></div>
                                                                <div style="padding-bottom: 17px;"></div>
                                                                <h2><strong>General Summary:</strong></h2>
                                                                <div style="padding-bottom: 6px;"></div>
                                                                <div [innerHTML]="detailJob1.general_summary"></div>
                                                                <div style="padding-bottom: 17px;"></div>
                                                                <h2><strong>Preferred Skills/experience:</strong></h2>
                                                                <div style="padding-bottom: 6px;"></div>
                                                                <div [innerHTML]="detailJob1.preferred_skills"></div>
                                                                <div style="padding-bottom: 17px;"></div>
                                                                <h2><strong>Minimum Qualifications:</strong></h2>
                                                                <div style="padding-bottom: 6px;"></div>
                                                                <div [innerHTML]="detailJob1.minimum_qualifications"></div>
                                                                <div style="padding-bottom: 17px;"></div>
                                                                <h2><strong>Responsibilities:</strong></h2>
                                                                <div style="padding-bottom: 6px;"></div>
                                                                <div [innerHTML]="detailJob1.responsibilities"></div>
                                                            
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4">
                                        <div class="css-9bbna8">
                                            
                                            <div class="css-1gw0f0n">
                                                <ul>
                                                    <h3 class="css-1uppmgb">
                                                        Similar Jobs
                                                    </h3>
                                                    <li class="" *ngFor="let a of car | search: location:time:category:type;let i =index">
                                                        <div class="css-1q2dra3" *ngIf="i<10 && a.id_car!=this.id_job">
                                                            <div class="css-qiqmbt" (click)="detailJob(a)" >
                                                                <div class="css-b3pn3b">
                                                                    <div class="css-b3pn3b">
                                                                        <h3>
                                                                            <a class="css-19uc56f">{{a.title}}</a>
                                                                        </h3>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="css-248241" (click)="detailJob(a)" >
                                                                <div class="css-1y87fhn">
                                                                    <div class="css-k008qs">
                                                                        <div class="css-kij4qr">
                                                                            <span class="css-wwkk48"><i class="flaticon-maps-and-flags"></i></span>
                                                                        </div>
                                                                        <dl>
                                                                            <dt class="css-y8qsrx">
                                                                                locations
                                                                            </dt>
                                                                            <dd class="css-129m7dg">
                                                                                {{a.location}}
                                                                            </dd>
                                                                        </dl>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="css-zoser8" (click)="detailJob(a)" >
                                                                <div class="css-1y87fhn">
                                                                    <div class="css-k008qs">
                                                                        <div class="css-kij4qr">
                                                                            <span class="css-wwkk48"><img src="../../assets/img/time.png" width="15" height="15"></span>
                                                                        </div>
                                                                        <dl>
                                                                            <dt class="css-y8qsrx">
                                                                                posted on
                                                                            </dt>
                                                                            <dd class="css-129m7dg">
                                                                                <span *ngIf="today==generateDate(a.created_at)">Posted today</span>
                                                                                <span *ngIf="today!=generateDate(a.created_at)">{{generateDate(a.created_at)}}</span>
                                                                            </dd>
                                                                        </dl>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <ul class="css-14a0imc" (click)="detailJob(a)" >
                                                                <li class="css-h2nt8k">
                                                                    {{a.id_car}}
                                                                </li>
                                                            </ul>

                                                        </div>
                                                    </li>
                                                </ul>
                                                <div class="css-vr2o4s">
                                                    <div class="css-cx1qpg">
                                                        <div class="css-8idjik">
                                                            <p style="padding-top: 35px;padding-bottom: 120px;">
                                                                <span class="css-1uppmgb">Check our privacy policy</span>
                                                                <a routerLink="/privacy-policy" class="css-ldauis">Exceliacom Privacy Policy</a>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </div>
    </body>
    
<app-footer></app-footer>
    </div>
</div>
