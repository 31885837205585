import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ServiceService {

  constructor(private http : HttpClient) {}

  getTiran(){
    return this.http.post<any>(this.getPath()+"/getTiran.php",{});
  }

  getImages(){    
    return this.http.post<any>(this.getPath()+"/getOne.php",{});
  }
  question(a0,a1,a2,a3,a4,a5,a6,a7,a8,a9,a10,a11){    
    return this.http.post<any>(this.getPath()+"/question.php",{a0,a1,a2,a3,a4,a5,a6,a7,a8,a9,a10,a11});
  }
  check(question1:string,img:string){
    return this.http.post<any>(this.getPath()+"/check.php",{question1,img});
  }
  send1(email:string){
    return this.http.post<any>(this.getPath()+"/send1.php",{email});
  }
  getCareers(){
    return this.http.post<any>(this.getPath1()+"/api/participation/getCareers",{});
  }
  getCareersID(id){
    return this.http.post<any>(this.getPath1()+"/api/participation/getCareersID",{id:id});
  }
  getNews(){
    return this.http.post<any>(this.getPath1()+"/api/participation/getNews",{});
  }
  path1 ="https://exceliacom.platformlttop.com/website_exceliacom1/public";
  getPath1()
  {
    return this.path1;
  }
  path ="https://exceliacom.platformlttop.com/website_exceliacom";
  getPath()
  {
    return this.path;
  }

  pathImage ="https://exceliacom.platformlttop.com/website_exceliacom1/storage/app/public/";
  getPathImage()
  {
    return this.pathImage;
  }

  pathExceliacom ="https://face.exceliacom.com/";
  getPathExceliacom()
  {
    return this.pathExceliacom;
  }

}