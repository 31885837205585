<router-outlet>
</router-outlet>
<a (click)="up()" *ngIf="enable" [class]="topclick1?'topc':'top'"><img src="../assets/img/up.png"></a>
<div class="bottom" id="alert" *ngIf="cookies1">
    <div id="CybotCookiebotDialogBody">
        <div class="CybotCookiebotDialogPoweredbyLink">
            <img class="CybotCookiebotDialogPoweredbyImage" src="../assets/img/logo.png">
        </div>
        <div class="CybotCookiebotDialogBodyContent">
            <h2 id="CybotCookiebotDialogBodyContentTitle">
                This website uses cookies
            </h2>
            <div id="CybotCookiebotDialogBodyContentText">
                We use cookies to personalise content and ads, to provide social media features and to analyse our traffic. We also share information about your use of our site with our social media, advertising and analytics partners who may combine it with other information
                that you’ve provided to them or that they’ve collected from your use of their services.
            </div>
        </div>
        <div id="CybotCookiebotDialogBodyButtons">
            <div class="col-lg-4">
                <a id="CybotCookiebotDialogBodyButtonDecline" class="CybotCookiebotDialogBodyButton style-9fv7s buttoncookiesdesktop" (click)="allow()">
                  Use necessary cookies only 
                </a>
            </div>
            <div class="col-lg-4" (click)="allow()">
                <a id="CybotCookiebotDialogBodyButtonAccept" class="CybotCookiebotDialogBodyButton style-7JLd9 buttoncookiesdesktop">
                  Allow all cookies 
                </a>
            </div>
            <div class="col-lg-4">
                <a id="CybotCookiebotDialogBodyButtonDetails" class="CybotCookiebotDialogBodyLink buttoncookiesdesktop">
                  Show details
                </a>
            </div>
            <div class="row justify-content-end" (click)="allow()">
                <a id="CybotCookiebotDialogBodyButtonDecline" class="CybotCookiebotDialogBodyButton style-9fv7s1 buttoncookiesmobile" (click)="allow()">
                  Use necessary cookies only 
                </a>
                <a id="CybotCookiebotDialogBodyButtonAccept" class="CybotCookiebotDialogBodyButton style-7JLd91 buttoncookiesmobile" (click)="allow()">
                  Allow all cookies 
                </a>
                <a id="CybotCookiebotDialogBodyButtonDetails" class="CybotCookiebotDialogBodyLink buttoncookiesmobile">
                  Show details
                </a>
            </div>
        </div>
    </div>

</div>