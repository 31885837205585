import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-proe',
  templateUrl: './proe.component.html',
  styleUrls: ['./proe.component.scss']
})
export class ProeComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
