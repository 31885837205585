import { Component, OnInit } from '@angular/core';
import { ServiceService } from '../service.service';

@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.scss']
})
export class EventsComponent implements OnInit {

a1:boolean=true
a2:boolean=true
a3:boolean=true
a4:boolean=true
search=""
  
  constructor(public service: ServiceService) { }
  searched=false
  enablesearch()
  {
    this.searched=!this.searched
  }

  error=true
  news:any
  getNews()
  {
    this.error=true
    this.service.getNews().subscribe(
      data => {
        this.news=data
        this.error=false
        console.log(data)
      },
      error => {
        this.news=[]
        this.error=false
        console.log(error)
      }
    )
  }

  load=true 
  ngOnInit(): void {
    setTimeout(() => {
      this.load=false
    }, 100);
    this.getNews()
  }
  removeTags(str) {
    if ((str===null) || (str===''))
        return false;
    else
        str = str.toString();
          
    // Regular expression to identify HTML tags in
    // the input string. Replacing the identified
    // HTML tag with a null string.
    return str.replace( /(<([^>]+)>)/ig, '');
}
  
}
