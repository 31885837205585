import { Component, OnInit } from '@angular/core';
import { Router, NavigationStart, NavigationCancel, NavigationEnd, ActivatedRoute } from '@angular/router';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { filter } from 'rxjs/operators';
import { Title } from '@angular/platform-browser';
declare let $: any;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    providers: [
        Location, {
            provide: LocationStrategy,
            useClass: PathLocationStrategy
        }
    ]
})
export class AppComponent implements OnInit {
    location: any;
    routerSubscription: any;

    constructor(private router: Router, 
        private activatedRoute: ActivatedRoute,  
        private titleService: Title) {
    }



    getChild(activatedRoute: ActivatedRoute) {  
        if (activatedRoute.firstChild) {  
            return this.getChild(activatedRoute.firstChild);  
        } else {  
            return activatedRoute;  
        } 
    }  

    cookies1=true
    allow()
    {
        this.cookies1=false
    }
    enable=false
    topclick1=false

    ngOnInit(){
        this.router.events.pipe(  
        filter(event => event instanceof NavigationEnd),).subscribe(() => {  
            const rt = this.getChild(this.activatedRoute);  
            rt.data.subscribe(data => {  
            console.log(data);  
            this.titleService.setTitle(data.title)});  
        });  
        this.recallJsFuntions();
        setInterval(() => {
            //console.log(window.scrollY)
            if(window.scrollY>=400 && this.enable==false)
            {
              this.enable=true
            }
            if(window.scrollY<400 && this.enable==true)
            {
              this.enable=false
            }
          }, 250);
    }

    up()
    {
        window.scrollTo(0, 0);
        this.topclick1=true
        setTimeout(() => {
            this.topclick1=false
        }, 2000)
    }
    recallJsFuntions() {
        this.router.events
        .subscribe((event) => {
            if ( event instanceof NavigationStart ) {
                $('.loader-content').fadeIn('slow');
            }
        });
        this.routerSubscription = this.router.events
        .pipe(filter(event => event instanceof NavigationEnd || event instanceof NavigationCancel))
        .subscribe(event => {
            $.getScript('../assets/js/custom.js');
            $('.loader-content').fadeOut('slow');
            this.location = this.router.url;
            if (!(event instanceof NavigationEnd)) {
                return;
            }
            window.scrollTo(0, 0);
        });
    }
}