import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeOneComponent } from './components/pages/home-one/home-one.component';
import { ContactComponent } from './components/pages/contact/contact.component';
import { ErrorComponent } from './components/pages/error/error.component';
import { PricingComponent } from './components/pages/pricing/pricing.component';
import { PortfolioComponent } from './components/pages/portfolio/portfolio.component';
import { ServiceComponent } from './components/pages/service/service.component';
import { ServiceDetailsComponent } from './components/pages/service-details/service-details.component';
import { AboutComponent } from './components/pages/about/about.component';
import { TrainingbComponent } from './trainingb/trainingb.component';
import { TrainingcComponent } from './trainingc/trainingc.component';
import { TrainingdComponent } from './trainingd/trainingd.component';
import { TrainingeComponent } from './traininge/traininge.component';
import { TrainingfComponent } from './trainingf/trainingf.component';
import { TraininggComponent } from './trainingg/trainingg.component';
import { ProbComponent } from './prob/prob.component';
import { ProcComponent } from './proc/proc.component';
import { ProdComponent } from './prod/prod.component';
import { ProeComponent } from './proe/proe.component';
import { ProfComponent } from './prof/prof.component';
import { ProgComponent } from './prog/prog.component';
import { ProhComponent } from './proh/proh.component';
import { About1Component } from './about1/about1.component';
import { EventsComponent } from './events/events.component';
import { CareersComponent } from './careers/careers.component';
import { JobsComponent } from './jobs/jobs.component';
import { DetaillComponent } from './detaill/detaill.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { NewsroomComponent } from './newsroom/newsroom.component';
import { DetaillNewComponent } from './detaill-new/detaill-new.component';
import { SearchComponent } from './search/search.component';
import { BlogComponent } from './blog/blog.component';
import { DetaillBlogComponent } from './detaill-blog/detaill-blog.component';

const routes: Routes = [
  {path: '', component: About1Component},
    {path: 'about-us', component: About1Component,data: {title: 'About Us | Exceliacom'}},
    {path: 'news-media', component: EventsComponent,data: {title: 'News & Media | Exceliacom'}},
    {path: 'careers', component: CareersComponent,data: {title: 'Careers | Exceliacom'}},
    {path: 'jobs', component: JobsComponent,data: {title: 'Jobs | Exceliacom'}},
    {path: 'job-detail/:id', component: DetaillComponent,data: {title: 'Job Detail | Exceliacom'}},
    {path: 'about1', component: ServiceComponent,data: {title: 'about1 | Exceliacom'}},
    {path: 'about2', component: ServiceDetailsComponent,data: {title: 'about2 | Exceliacom'}},
    {path: 'privacy-policy', component: PrivacyPolicyComponent,data: {title: 'Privacy Policy | Exceliacom'}},
    {path: 'training', component: PortfolioComponent},
    {path: 'trainingb', component: TrainingbComponent},
    {path: 'trainingc', component: TrainingcComponent},
    {path: 'trainingd', component: TrainingdComponent},
    {path: 'traininge', component: TrainingeComponent},
    {path: 'trainingf', component: TrainingfComponent},
    {path: 'trainingg', component: TraininggComponent},
    {path: 'pro', component: PricingComponent},
    {path: 'prob', component: ProbComponent},
    {path: 'proc', component: ProcComponent},
    {path: 'prod', component: ProdComponent},
    {path: 'proe', component: ProeComponent},
    {path: 'prof', component: ProfComponent},
    {path: 'prog', component: ProgComponent},
    {path: 'proh', component: ProhComponent},
    {path: 'error', component: ErrorComponent,data: {title: 'Error'}},
    {path: 'contact-us', component: ContactComponent,data: {title: 'Contact Us | Exceliacom'}},
    {path: 'newsroom', component: NewsroomComponent,data: {title: 'News Room | Exceliacom'}},
    {path: 'news-media-detail/:id', component: DetaillNewComponent,data: {title: 'News & Media Detail | Exceliacom'}},
    {path: 'search', component: SearchComponent,data: {title: 'Search | Exceliacom'}},
    {path: 'e-Blog', component: BlogComponent,data: {title: '∊-Blog | Exceliacom'}},
    {path: 'e-blog-detail/:id', component: DetaillBlogComponent,data: {title: 'e-blog Detail | Exceliacom'}},
    // Here add new pages component

    {path: '**', component: ErrorComponent} // This line will remain down from the whole pages component list
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'top'})],
  exports: [RouterModule]
})
export class AppRoutingModule {}