import { Component, OnInit } from '@angular/core';
import { ServiceService } from '../service.service';

@Component({
  selector: 'app-jobs',
  templateUrl: './jobs.component.html',
  styleUrls: ['./jobs.component.scss']
})
export class JobsComponent implements OnInit {

  time=""
  category=""
  type=""
  location=""
  p: number = 1;
  nElement: number = 10

  constructor(private service: ServiceService) { }

  searched=false
  enablesearch()
  {
    this.searched=!this.searched
  }
  error=true
  car:any
  getCareers()
  {
    this.error=true
    this.service.getCareers().subscribe(
      data => {
        this.car=data
        this.error=false
        console.log(data)
      },
      error => {
        this.car=[]
        this.error=false
        console.log(error)
      }
    )
  }

  addZero(n:any)
  {
    n=Number(n)
    if(n<10)
    {
      return '0'+n
    }
    else
    {
      return n
    }
  }

  generateDate(date:string)
  {
    let myDate: Date = new Date(date);
    return myDate.getFullYear()+'-'+this.addZero(myDate.getMonth()+1)+'-'+this.addZero(myDate.getDate())
  }

  mois1:number
  annee1:number
  day1:number
  today=""
  load=true 
  ngOnInit(): void {
    setTimeout(() => {
      this.load=false
    }, 100);
    this.getCareers()
    let myDate: Date = new Date();
    this.mois1=this.addZero(myDate.getMonth()+1)
    this.annee1=myDate.getFullYear()
    this.day1=this.addZero(myDate.getDate())
    this.today=this.annee1+'-'+this.mois1+'-'+this.day1
  }
}
