<app-navbar-one></app-navbar-one>
<div *ngIf="load">
    <div class="container-fluid">
        <div class="row justify-content-center">
            <div class="col-lg-12 m-3 lds-ripple">
                <div></div>
                <div></div>
            </div>
        </div>
    </div>
</div>
<div *ngIf="!load">



    <div class="container2">
        <div class="container">
            <input type="radio" checked>
            <label for="tab1" routerLink="/about-us"> About Us</label>
            <input type="radio">
            <label class="label10" for="tab2" routerLink="/careers"> Careers</label>
            <input type="radio">
            <label class="label10 mediawidth" for="tab3" routerLink="/news-media"> News & Media</label>
        </div>



        <section class="portfolio-section1 portfolio-style-two">
            <div class=" mix online-business">
                <div class="portfolio-item">
                    <div class="portfolio-img">
                        <img src="../../assets/img/about.jpg" alt="portfolio image">
                        <div class="portfolio-text1">
                            <h3>Exceliacom simplifies technology, it delivers you the software, the hardware and the tools you need to ensure efficient usage of either your communications service, IoT, or renewable energy infrastructure.</h3>
                        </div>
                        <div class="portfolio-text">
                            <h3>Providing communications solutions is in our DNA. Today, we are at the cross-road of IoT and renewable energies to alleviate the challenge of net-zero emissions.</h3>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <app-guidage></app-guidage>
        <div class="container">
            <div class="section-title text-left">
                <span></span>

                <div class="row">
                    <div class="col-lg-6">
                        <div class="new5"></div>
                        <h2 class="h4-title">Networks Expertise</h2>
                        <h4 class="h4-desc">With over ten years of experience in telecommunications market, Exceliacom continues to deliver products and solutions in the areas of communications service control, network infrastructure, and broadcasting.</h4>
                        <br>
                        <h4 class="h4-desc">Our products and solutions have been successfully deployed and are currently used in telecom markets in North and West Africa. Check our products and solutions to start your next successful story.</h4>
                    </div>
                    <div class="col-lg-6">
                        <img class="rcorners1" src="../../assets/img/about1.jpeg">
                    </div>
                </div>
            </div>
        </div>
        <div class="container">
            <div class="section-title text-left">
                <span></span>

                <div class="row">
                    <div class="col-lg-6">
                        <div class="new5"></div>
                        <h2 class="h4-title">IoT Products</h2>
                        <h4 class="h4-desc">Rely on Exceliacom IoT products to manage your industrial assets, or monitor your green energy power plant. Our IoT platforms are available either on cloud or out of the shelf.</h4>
                    </div>
                    <div class="col-lg-6">
                        <img class="rcorners1" src="../../assets/img/about2.jpeg">
                    </div>
                </div>
            </div>
        </div>
        <div class="container">
            <div class="section-title text-left">
                <span></span>

                <div class="row">
                    <div class="col-lg-6">
                        <div class="new5"></div>
                        <h2 class="h4-title">Renewable Energies</h2>
                        <h4 class="h4-desc">Our one stop-shop concept for solar solutions allows our customers to benefit from plant design, proven civil engineering works and tier-1 solar products with advanced features such as hybrid operations and interoperability technology
                            with smart grids.</h4>
                    </div>
                    <div class="col-lg-6">
                        <img class="rcorners1" src="../../assets/img/about3.jpeg">
                    </div>
                </div>
            </div>
        </div>
        <div class="separet"></div>
        <div class="container">
            <div class="section-title text-left">
                <span></span>
                <div class="new5"></div>
                <h2 class="h4-title">Quick Facts</h2>
            </div>
        </div>
        <div class="countermobile">
            <div class="col-lg-4" style="padding: 0;">
                <div class="counter1">
                    <section class="features-section">
                        <div class="container">
                            <div class="row">
                                <div>
                                    <div class="features-card feature-one">
                                        <img style="width: 115px !important; height: 115px !important;padding-bottom: 24px;" src="../../assets/img/icon2.png">
                                        <h3>{{count}}+</h3>
                                        <p><strong>Customers</strong></p>
                                        <p> have deployed our <strong>telecom</strong> and <strong>broadcast</strong> solutions </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
            <div class="col-lg-4" style="padding: 0;">
                <div class="counter1">
                    <section class="features-section">
                        <div class="container">
                            <div class="row">
                                <div>
                                    <div class="features-card feature-one">
                                        <img style="width: 115px !important; height: 115px !important;padding-bottom: 24px; padding-left: 15px;" src="../../assets/img/icon3.png">
                                        <h3>&nbsp;{{count1}}+</h3>
                                        <p><strong>IoT Sensors</strong></p>
                                        <p> we deployed during the last<strong> two</strong><br> years</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
            <div class="col-lg-4" style="padding: 0;">
                <div class="counter">
                    <section class="features-section">
                        <div class="container">
                            <div class="row">
                                <div>
                                    <div class="features-card feature-one">
                                        <img style="width: 115px !important; height: 115px !important;padding-bottom: 24px;" src="../../assets/img/icon1.png">
                                        <h3>{{count2}} MW+</h3>
                                        <p><strong>Solar Power</strong></p>
                                        <p> we deployed during the last <strong>two</strong><br> years</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </div>
        <div class="counterdesc counter2">
            <div class="container">
                <div class="col-lg-4" style="padding-left: 0px;">
                    <div>
                        <section class="features-section">
                            <div class="row">
                                <div>
                                    <div class="features-card feature-one">
                                        <img style="width: 115px !important; height: 115px !important;padding-bottom: 24px;" src="../../assets/img/icon2.png">
                                        <h3>{{count}}+</h3>
                                        <p><strong>Customers</strong></p>
                                        <p> have deployed our <strong>telecom</strong> and <strong>broadcast</strong> solutions </p>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
                <div class="col-lg-4" style="padding: 0px 7px;">
                    <div>
                        <section class="features-section">
                            <div class="row">
                                <div>
                                    <div class="features-card feature-one">
                                        <img style="width: 115px !important; height: 115px !important;padding-bottom: 24px; padding-left: 15px;" src="../../assets/img/icon3.png">
                                        <h3>&nbsp;{{count1}}+</h3>
                                        <p><strong>IoT Sensors</strong></p>
                                        <p> we deployed during the last<br><strong> two</strong> years</p>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
                <div class="col-lg-4" style="padding-right: 0px;">
                    <div>
                        <section class="features-section">
                            <div class="row">
                                <div>
                                    <div class="features-card feature-one">
                                        <img style="width: 115px !important; height: 115px !important;padding-bottom: 24px;" src="../../assets/img/icon1.png">
                                        <h3>{{count2}} MW+</h3>
                                        <p><strong>Solar Power</strong></p>
                                        <p> we deployed during the last<br> <strong>two</strong> years</p>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </div>
        <section class="about-section pb-100">
            <div class="container">
                <div class="section-title text-left">
                    <span></span>
                    <div class="new5"></div>
                    <h2 class="h4-title">Who We Are?</h2>
                </div>
                <div class="row align-items-center">

                    <div class="col-lg-6 offset-lg-1">
                        <div class="about-text">

                            <h4 class="h4-desc">Our team is a group of dedicated managers, administrators, engineers, and technicians. We are bringing diverse backgrounds together to build innovative solutions and make every day a better experience for our customers.</h4>
                            <br>
                            <h4 class="h4-desc">We believe that industry has to evolve towards net-zero emissions and this can only be achieved through multidisciplinary innovations gathering digital, IoT and renewable energy solutions. Our people are striving for making
                                this a reality.</h4>

                            <div class="section-title">

                            </div>
                        </div>
                    </div>
                    <div class="col-lg-5">
                        <div class="about-image">
                            <img src="assets/img/2.jpg" class="imgWho" alt="about image">
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <app-footer></app-footer>
    </div>
</div>