import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'newsroom'
})
export class NewsroomPipe implements PipeTransform {

  transform(items: any[], search="", a1=false, a2=false, a3=false, a4=false): any {
    if(!items) return [];
    var aa1=""
    var aa2=""
    var aa3=""
    var aa4=""
    if(a1)
    {
      aa1="Press Notes"
    }
    if(a2)
    {
      aa2="Press Release"
    }
    if(a3)
    {
      aa3="In the Media"
    }
    if(a4)
    {
      aa4="Blog"
    }
    
    search = search.toLowerCase();
console.log(search)
    return items.filter(item => 
      (item.title.toLowerCase().indexOf(search) !== -1) && (
      (item.type==aa1) ||
      (item.type==aa2) ||
      (item.type==aa3) ||
      (item.type==aa4))
      );
  }

}
