

<app-navbar-one></app-navbar-one>
<div *ngIf="load">    <div class="container-fluid">
        <div class="row justify-content-center">
            <div class="col-lg-12 m-3 lds-ripple"><div></div><div></div></div>
        </div>
    </div>
</div>
<div *ngIf="!load">


        <section class="elementor-section elementor-element elementor-element-34f7434 elementor-section-boxed">
            <div class="container">
              <div class="elementor-column elementor-col-100 elementor-element">
                <div class="elementor-widget-wrap elementor-element-populated">
                  <div class="elementor-element elementor-widget">
                    <div class="elementor-widget-container">
                      <h1 class="elementor-heading-title">
                        Site Search
                      </h1>
                    </div>
                  </div>
                  <div class="elementor-element elementor-element-7ae908b elementor-widget">
                    <div class="elementor-widget-container">

                            <div class="col-lg-10 m-0">
                                <div class="jet-ajax-search__fields-holder">
                                <div class="jet-ajax-search__field-wrapper">
                                    <input class="jet-ajax-search__field" type="search" placeholder="Search ..." value="" name="s">
                                    <input type="hidden" value="{&quot;results_order_by&quot;:&quot;relevance&quot;,&quot;results_order&quot;:&quot;asc&quot;,&quot;search_source&quot;:&quot;any&quot;}" name="jet_ajax_search_settings">
                                </div>
                                </div>
                            </div>
                            <div class="col-lg-2 m-0">
                                <button class="jet-ajax-search__submit btnsub" type="submit">
                                <span class="jet-ajax-search__submit-icon jet-ajax-search-icon">
                                    <i class="fas fa-search">
                                    </i>
                                </span>
                                <span class="jet-ajax-search__submit-label">
                                    Search
                                </span>
                                </button>
                            </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
                      
            <app-footer></app-footer>

</div>