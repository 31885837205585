<app-navbar-one></app-navbar-one>
<div *ngIf="load">    <div class="container-fluid">
        <div class="row justify-content-center">
            <div class="col-lg-12 m-3 lds-ripple"><div></div><div></div></div>
        </div>
    </div>
</div>
<div *ngIf="!load">



<div class="container">
<div class="card">
    <div class="card-body">
        <h1>
            Privacy Policy
        </h1>
        <p>
            In this Privacy Policy (“Policy”), we, Exceliacom Engineering,SARL, and our
            subsidiaries (collectively “we”, “us”, or “our”), provide information about
            how we collect, use, process, and transfer personal data. Exceliacom
            Engineering and our subsidiaries are sharing the data we manage across
            businesses (legal entities), business processes, and information systems we
            use. Therefore, for this Policy, we use the term “personal data” broadly to
            cover the many privacy and data protection laws applicable to us; generally
            “personal data” means information related to an identified natural person
            or that could reasonably be used (by itself or in combination with other
            data available) to identify a natural person.
        </p>
        <h1>
            Scope
        </h1>
        <p>
            This Policy applies to our website, products, services, software, or apps
            on which we post a direct link to this Policy or refer to within this
            Policy (collectively the "Services"). This Policy covers data collected
            through the Services and not any data collection or processing practices of
            other services that maintain their own, separate privacy policies.
            Occasionally, we refer to this Policy in relation to surveys and
            special-purpose web pages, for example, our Careers website.
        </p>
        <h1>
            Types of Data and Collection Methods
        </h1>
        <p>
            The personal data we collect depends on how you interact with us, the
            services you use, and the choices you make.
        </p>
        <h2>
            (i) Website 
        </h2>
        <p>
            When you visit our website, we collect some information automatically.
        </p>
        <h2>
            (ii) Marketing Campaigns
        </h2>
        <p>
            When you participate in our campaigns, we collect and process the personal
            data you provide to us. Those information remain in our servers and are
            solely used by our marketing department.
        </p>
        <h2>
            (iii) Software, Applications, and Services
        </h2>
        <p>
            We offer a variety of software, applications, and services that collect or
            process data. Some such software, applications, and services, may include a
            data exchange with our servers to enable a functionality, deliver a
            service, enable research and development, or other business purpose. The
            personal data collected from software, applications, and services remains
            in our servers and is not used by any other third party.
        </p>
        <h2>
            (iv) Other Data You Submit
        </h2>
        <p>
            We may collect other personal data you submitted to us. Below lists a few
            examples.
        </p>
        <p>
            <strong>Contact or Registration Information:</strong>
            We collect your name, email address(es), mailing address(es), telephone
            number(s), and data related to the business you represent when you request
            information from us, register, order a product or service, visit our
            offices, or otherwise intentionally provide us such information.
        </p>
        <p>
            <strong>Employment Application: </strong>
            If you apply for employment or other role as a worker for Exceliacom and/or
            one of its affiliates, we will collect your name, contact information,
            application, job or role for which you are applying, and any information
            you provide to us such as your resume, CV, reference letters or other
            information.
        </p>
        <h1>
            Collection Purposes, Use of Data Methods
        </h1>
        <p>
            We collect and process personal data about you with your consent and/or as
            necessary to provide the products you use, operate our business, meet our
            contractual and legal obligations, protect the security of our systems and
            our customers, or fulfil other legitimate interests. The purposes for which
            we collect and use each of the categories of personal data include:
        </p>
        <p>
         <li> To respond to the request that you sent us such as your request for
            information, or your request to subscribe to a service or purchase a
            product;
        </li>
        </p>
        <p>
         <li> To provide, manage, maintain, and secure the Service(s) you request;
        </li>
        </p>
        <p>
         <li> To enable you to participate in our marketing campaigns;
        </li>
        </p>
        <p>
         <li> To operate and improve our business, including to administer, protect,
            and improve our services and our systems, to develop new products and
            services, and for other internal business purposes;
        </li>
        </p>
        <p>
         <li> To better understand the preferences of the users of our Services,
            compile aggregated statistics about usage of our Services, and help
            personalize your experience of our website and Services;</li>
        <li>
            To provide you with information about our technologies, product or service releases, news,
            and other communications;
        </li>
        </p>
        <p>
         <li> To comply with our legal obligations; and
        </li>
        </p>
        <p>
         <li> Any other use we describe in this Policy or at the point where we collect
            the data. To achieve these purposes, and to the extent permitted by
            applicable law, we may combine the various types of data that we collect
            from different on and offline sources. In addition, we may de-identify
            and/or anonymize the personal data we collect such that it no longer can be
            used to identify you and, in such case, the de-identified and/or anonymized
            data is no longer subject to this Policy and we use such data for our
            business purposes.
        </p>
        <h1>
            Where We Store and Process Data
        </h1>
        <p>
            We store and process data in Morocco and on servers around in the world,
            including, without limitation, the United States, Asia and the European
            Economic Area. Wherever we store or process personal data pursuant to this
            Policy, we take measures designed to ensure that the data is processed in
            accordance with this Policy and in compliance with applicable law.
        </p>
        <h1>
            Disclosures of Personal Data
        </h1>
        <p>
            We may disclose each of the categories of personal data we collect with the
            following types of third parties.
        </p>
        <p>
         <li> Exceliacom subsidiaries may have access to personal data we collect, for
            example, where we share common data systems, where access is needed to
            provide our Services or operate our business, or when we share your request
            to subscribe to a newsletter regarding certain products or services with
            the applicable Exceliacom subsidiary.
        </li>
        </p>
        <p>
         <li> We may also offer training or other Services to the employees and workers
            of our business customers. In such cases, we may share data relating to the
            use of our training and other Services with the identified business
            customers for their business use.
        </li>
        </p>
        <p>
         <li> We may also disclose your data as required by law or in the interest of
            protecting or exercising our or others' legal rights, protecting the
            security of our systems or those of our customers, and protecting people’s
            physical safety. Such disclosures may occur, e.g., without limitation, in
            connection with requests from law enforcement officials and in connection
            with court proceedings.
        </li>
        <li> We may share or transfer your data in connection
            with a prospective or actual sale, merger, transfer or other reorganization
            of all or parts of our business, including related negotiations.
        </li>
        </p>
        <p>
         <li> We may also share your personal data where you have granted us
            permission.
        </li>
        </p>
        <p>
         <li> Finally, we process, use, and exploit data as that is defined by the
            Moroccan Commission Nationale de contrôle de la protection des Données à
            Caractère Personnel (CNDP).
        </p>
        <h1>
            Your Choices
        </h1>
        <p>
            <strong>Opt-In/Out:</strong>
            In some circumstances, such as email marketing and newsletters, we offer
            you the ability to opt-in or opt-out of some kinds of data collection, use,
            or sharing. In such circumstances, we will respect your choice.
        </p>
        <p>
            <strong>Data Subject Rights: </strong>
            In case you want to request access, correct, delete, restrict, or apply
            other rights with respect to your personal data, you may contact us using
            the information at the bottom of this Policy. Please note that to provide
            or delete specific pieces of personal data we will require you to provide
            information sufficient to verify your identity to the degree of certainty
            required by law.
        </p>
        <h1>
            Retention
        </h1>
        <p>
            We retain personal data while your account is active, or as needed to
            provide you Services, or you did not submit any explicit request to
            unsubscribe from our marketing services. We will delete or de-identify
            personal data within a reasonable time after the data is no longer
            necessary for the business purposes for which it was collected. We will,
            however, retain and use personal data as necessary to comply with our legal
            obligations, resolve disputes, and enforce our agreements.
        </p>
        <h1>
            Security
        </h1>
        <p>
            Transmissions over the Internet are never 100% secure or error-free.
            However, we take reasonable steps to protect your data from loss, misuse,
            and unauthorized access, disclosure, alteration, and destruction. It is
            your responsibility to safeguard your passwords and User IDs and to notify
            us using one of the contact methods listed below if you ever suspect that
            your password or User ID for one of our Services has been compromised. To
            help us protect personal data, we request that you use a strong password
            and never share your password with anyone or use the same password with
            other sites or accounts. You are solely responsible for any unauthorized
            use of our Services conducted via your password and User ID.
        </p>
        <h1>
            Amendments
        </h1>
        <p>
            We reserve the right to change, modify, add, or remove portions of this
            Policy at any time, but will alert you that changes have been made by
            indicating on the Policy the date it was last updated. If we change the
            Policy in a material way, we will provide appropriate online notice to you
            at least thirty days in advance and/or obtain your express consent as
            required by law. When you visit our websites or use our Services, you are
            accepting the current version of this Policy. We recommend that users
            revisit this Policy on occasion to learn of any changes.
        </p>
        <h1>
            Contact Us
        </h1>
        <p>
            To contact us, send us an email at privacy[at]exceliacom[dot]com, or write
            to us at Exceliacom Engineering SARL, Attn. Legal Department, 47, Bd Lalla
            Yacout, 5ème Étage, Casablanca, Morocco.
        </p>
        <p>
            Effective Date: January 23, 2023
        </p>
    </div>
</div>
    
</div>

<app-footer></app-footer>
</div>