import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-trainingg',
  templateUrl: './trainingg.component.html',
  styleUrls: ['./trainingg.component.scss']
})
export class TraininggComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
