import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-about1',
  templateUrl: './about1.component.html',
  styleUrls: ['./about1.component.scss']
})
export class About1Component implements OnInit {

  constructor(private router: Router){
  }
  
  count:number=0
  firststep=0
  count1:number=0
  firststep1=0
  count2:number=0
  firststep2=0
  load=true

  ngOnInit(){ 
    setTimeout(() => {
      this.load=false
    }, 10);
    
    console.log(window.screen.availHeight)
    setInterval(() => {
      
      if(window.screen.width<1000)
      {
        if(window.scrollY>=2080 && this.firststep==0)
        {
          this.firststep=1
        }
        if(window.scrollY>=2780 && this.firststep1==0)
        {
          this.firststep1=1
        }
        if(window.scrollY>=3080 && this.firststep2==0)
        {
          this.firststep2=1
        }
      }
      else{
        if(window.scrollY>=1600 && this.firststep==0)
        {
          this.firststep=1
        }
        if(window.scrollY>=1600 && this.firststep1==0)
        {
          this.firststep1=1
        }
        if(window.scrollY>=1600 && this.firststep2==0)
        {
          this.firststep2=1
        }
      }
    }, 250);
    setInterval(() => {
      if(this.firststep==1)
      {
      this.myCount(15);
      }
    }, 120);
    setInterval(() => {
      if(this.firststep1==1)
      {
        this.myCount1(5000);
      }
    }, 0.0001);
    setInterval(() => {
      if(this.firststep2==1)
      {
        this.myCount2(10);
      }
    }, 120);
  }
  
  myCount(n) {
    if (this.count >= n) {
          this.count = n;
    }
    else
    this.count++;
  }

  myCount1(n) {
    if (this.count1 >= n) {
          this.count1 = n;
    }
    else
    this.count1=this.count1+10;
  }
  myCount2(n) {
    if (this.count2 >= n) {
          this.count2 = n;
    }
    else
    this.count2++;
  }

}
