<app-navbar-one></app-navbar-one>
<div *ngIf="load">
    <div class="container-fluid">
        <div class="row justify-content-center">
            <div class="col-lg-12 m-3 lds-ripple">
                <div></div>
                <div></div>
            </div>
        </div>
    </div>
</div>
<div *ngIf="!load">

    <div class="row justify-content-center" *ngIf="error">
        <div class="lds-ripple">
            <div></div>
            <div></div>
        </div>
    </div>
    <div *ngIf="!error && news[index1]">

        <div [ngClass]="'gkOfNl3'">
            <div class="container">
                <div class="cjYGBp iTDWfP fEZJEa">
                    <div class="row">
                        <h2 class="fStkLY1 durGnI">
                            <img src="../../assets/img/logoblanc.png">-Blog &nbsp;&nbsp; — <span class="colorblue">Insights and up-to-date articles on technologies and innovations that empower the hyper-connected world, IoT, and renewable energies.</span>

                        </h2>
                    </div>

                </div>
            </div>
        </div>

        <div class="container">
            <div class="col-lg-8">
                <div>
                    <article class="brEgEx fZafGH">
                        <div>
                        </div>
                        <header class="kiusKR">
                            <h1 class="hlaZOO bxdMrS">
                                {{removeTags(news[index1].title)}}
                            </h1>
                        </header>
                        <div class="fqahvR">
                            <p class="RdUAC">
                                {{news[index1].created_at | date : "MMM dd, yyyy"}}
                                <!--<span class="eoDnLD">
                        {{news[index1].location}}
                        </span>-->
                            </p>
                        </div>
                    </article>
                </div>

                <div class="ioHAlX" style="margin: 0px;">
                    <article class="brEgEx fZafGH" id="articleContainer">
                        <div>
                            <div>
                                <div class="aem-Grid aem-Grid--12 aem-Grid--default--12">
                                    <div class="">
                                        <div class="aem-Grid aem-Grid--6 aem-Grid--default--6">
                                            <div class="aem-GridColumn aem-GridColumn--default--6">
                                                <div class="gOGeHl rte-supplemental-blockQuote">
                                                    <div class="cMjyLO">
                                                        <div class="cjYGBp cmMLgH richText">
                                                            <div>
                                                                <img *ngIf="news[index1].type=='Blog'" class="cqrtwT1" [src]="this.service.getPathImage()+''+news[index1].image">

                                                                <div id="richTextContainer" *ngIf="news[index1]">
                                                                    <span class="classp" [innerHTML]="(news[index1].created_at | date :  'MMM dd, yyyy')+' - '+replacePbySpan(news[index1].description)"></span>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                        </div>
                                    </div>
                                    <div class="aem-GridColumn aem-GridColumn--offset--default--0 aem-GridColumn--default--none">
                                        <div class="aem-Grid">
                                        </div>
                                        <div>
                                        </div>
                                    </div>
                                    <div class="">
                                        <div class="aem-Grid aem-Grid--6 aem-Grid--default--6">
                                            <div class="aem-GridColumn aem-GridColumn--default--6">
                                                <div class="pressReleaseFooter">
                                                    <footer class="fsshvX">
                                                        <div>
                                                            <div>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <div class="cjYGBp cmMLgH">
                                                            </div>
                                                        </div>
                                                        <div class="beHfpg">

                                                        </div>
                                                    </footer>
                                                </div>
                                                <div>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                </div>
                            </div>
                        </div>

                        <div class="hbXIxr">
                            <div class="jxNaSW jLxSjI">
                                <div>
                                    <h4 class="relatedtext" *ngIf="news[index1].type=='In the Media'"> Related Media News </h4>
                                    <h4 class="relatedtext" *ngIf="news[index1].type=='Press Notes'"> Related Press Notes </h4>
                                    <h4 class="relatedtext" *ngIf="news[index1].type=='Press Release'"> Related Press Releases </h4>
                                </div>
                            </div>
                        </div>
                    </article>
                </div>
            </div>
            <div class="col-lg-4">
                <div class="paddingside">
                    <a class="lnk" href="/author/hock-tan">
                        <div class="row">
                            <div class="authorBox col-lg-12">
                                <div class="boxHead">
                                    <span>
                            Author
                        </span>
                                </div>
                                <img src="../../assets/img/user.png" class="image">
                                <div class="name">
                                    <p>{{news[index1].posted_by}}</p>
                                    <p>{{news[index1].object}} </p>
                                    <p>{{news[index1].phone}} </p>
                                    <p>{{news[index1].email}}</p>
                                </div>
                            </div>
                        </div>
                    </a>
                    <div class="row">
                        <div class="related-posts">
                            <h3 class="bc--color_gray900 bc--fw_bold mb-2">
                                Related Blog
                            </h3>
                            <div class="loaded">


                                <div class="related-posts-des" *ngIf="newsFilter(news[index1].type)[0]">
                                    <a class="lnk" routerLink="/news-media-detail/{{newsFilter(news[index1].type)[0].id}}">
                                        <div class="row">
                                            <div class="col-lg-5">
                                                <img [src]="this.service.getPathImage()+''+newsFilter(news[index1].type)[0].image">
                                            </div>
                                            <div class="col-lg-7">
                                                <h3>
                                                    {{removeTags(newsFilter(news[index1].type)[0].title)}}
                                                </h3>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                                <div class="related-posts-des" *ngIf="newsFilter(news[index1].type)[1]">
                                    <a class="lnk" routerLink="/news-media-detail/{{newsFilter(news[index1].type)[1].id}}">
                                        <div class="row">
                                            <div class="col-lg-5">
                                                <img [src]="this.service.getPathImage()+''+newsFilter(news[index1].type)[1].image">
                                            </div>
                                            <div class="col-lg-7">
                                                <h3>
                                                    {{removeTags(newsFilter(news[index1].type)[1].title)}}
                                                </h3>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                                <div class="related-posts-des" *ngIf="newsFilter(news[index1].type)[2]">
                                    <a class="lnk" routerLink="/news-media-detail/{{newsFilter(news[index1].type)[2].id}}">
                                        <div class="row">
                                            <div class="col-lg-5">
                                                <img [src]="this.service.getPathImage()+''+newsFilter(news[index1].type)[2].image">
                                            </div>
                                            <div class="col-lg-7">
                                                <h3>
                                                    {{removeTags(newsFilter(news[index1].type)[2].title)}}
                                                </h3>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-lg-12">
                            <h3 class="bc--color_gray900 bc--fw_bold mb-2">
                                Share
                            </h3>
                            <div>
                                <ul class="social-share nav">
                                    <span>
                                <a target="_blank" [href]="'https://linkedin.com/shareArticle?url='+this.service.getPathExceliacom()+'e-blog-detail/'+id+'&mini=true&title='+removeTags(news[index1].title)+'&summary=exceliacom.com&source=Chillyfacts'"><i class="fa-brands fa-linkedin"></i></a>
                            
                        </span>
                                    <span>
                            <a target="_blank" [href]="'http://twitter.com/share?text=text goes here&url='+this.service.getPathExceliacom()+'e-blog-detail/'+id+'&mini=true&title='+removeTags(news[index1].title)+''"><i class="fa-brands fa-twitter"></i></a>

                            

                        </span>
                                    <li class="nav-item">
                                        <div class="pl-1 other-share">
                                            <button type="button" class="icon-bttn">
                                <span class="sr-only">
                                More
                                </span>
                                <span class="bi brcmicon-plus">
                                </span>
                            </button>
                                            <div class="dropdown-menu dropdown-menu">
                                                <span>
                                <div class="dropdown-item">
                                    <span class="bs_sprite bs_linkedin">
                                    </span>
                                                <button class="icon-bttn">
                                    LinkedIn
                                    </button>
                                            </div>
                                            </span>
                                            <span>
                                <div class="dropdown-item">
                                    <span class="bs_sprite bs_twitter">
                                    </span>
                                            <button class="icon-bttn">
                                    Twitter
                                    </button>
                                        </div>
                                        </span>
                                        <span>
                                <div class="dropdown-item">
                                    <span class="bs_sprite bs_facebook">
                                    </span>
                                        <button class="icon-bttn">
                                    Facebook
                                    </button>
                            </div>
                            </span>
                            <span>
                                <div class="dropdown-item">
                                    <span class="bs_sprite bs_googlebookmarks">
                                    </span>
                            <button class="icon-bttn">
                                    Google Bookmarks
                                    </button>
                        </div>
                        </span>
                        <span>
                                <div class="dropdown-item">
                                    <span class="bs_sprite bs_reddit">
                                    </span>
                        <button class="icon-bttn">
                                    Reddit
                                    </button>
                    </div>
                    </span>
                    <span>
                                <div class="dropdown-item">
                                    <span class="bs_sprite bs_pinterest">
                                    </span>
                    <button class="icon-bttn">
                                    Pinterest
                                    </button>
                </div>
                </span>
                <span>
                                <div class="dropdown-item">
                                    <span class="bs_sprite bs_blogger">
                                    </span>
                <button class="icon-bttn">
                                    Blogger
                                    </button>
            </div>
            </span>
            <span>
                                <div class="dropdown-item">
                                    <span class="bs_sprite bs_livejournal">
                                    </span>
            <button class="icon-bttn">
                                    LiveJournal
                                    </button>
        </div>
        </span>
        <span>
                                <div class="dropdown-item">
                                    <span class="bs_sprite">
                                    </span>
        <button class="icon-bttn">
                                    AddThis
                                    </button>
    </div>
    </span>
    <span>
                                <div class="dropdown-item">
                                    <span class="bs_sprite bs_getpocket">
                                    </span>
    <button class="icon-bttn">
                                    Pocket
                                    </button>
</div>
</span>
<span>
                                <div class="dropdown-item">
                                    <span class="bs_sprite bs_hackernews">
                                    </span>
<button class="icon-bttn">
                                    Hacker News
                                    </button>
</div>
</span>
<span>
                                <div class="dropdown-item">
                                    <span class="bs_sprite bs_stumbleupon">
                                    </span>
<button class="icon-bttn">
                                    StumbleUpon
                                    </button>
</div>
</span>
<span>
                                <div class="dropdown-item">
                                    <span class="bs_sprite bs_digg">
                                    </span>
<button class="icon-bttn">
                                    Digg
                                    </button>
</div>
</span>
<span>
                                <div class="dropdown-item">
                                    <span class="bs_sprite bs_buffer">
                                    </span>
<button class="icon-bttn">
                                    Buffer
                                    </button>
</div>
</span>
<span>
                                <div class="dropdown-item">
                                    <span class="bs_sprite bs_flipboard">
                                    </span>
<button class="icon-bttn">
                                    Flipboard
                                    </button>
</div>
</span>
<span>
                                <div class="dropdown-item">
                                    <span class="bs_sprite bs_instapaper">
                                    </span>
<button class="icon-bttn">
                                    Instapaper
                                    </button>
</div>
</span>
<span>
                                <div class="dropdown-item">
                                    <span class="bs_sprite bs_surfingbirdru">
                                    </span>
<button class="icon-bttn">
                                    SurfingBird.ru
                                    </button>
</div>
</span>
<span>
                                <div class="dropdown-item">
                                    <span class="bs_sprite bs_diaspora">
                                    </span>
<button class="icon-bttn">
                                    Diaspora
                                    </button>
</div>
</span>
<span>
                                <div class="dropdown-item">
                                    <span class="bs_sprite bs_qzone">
                                    </span>
<button class="icon-bttn">
                                    Qzone
                                    </button>
</div>
</span>
<span>
                                <div class="dropdown-item">
                                    <span class="bs_sprite bs_vk">
                                    </span>
<button class="icon-bttn">
                                    VK
                                    </button>
</div>
</span>
<span>
                                <div class="dropdown-item">
                                    <span class="bs_sprite bs_weibo">
                                    </span>
<button class="icon-bttn">
                                    Weibo
                                    </button>
</div>
</span>
<span>
                                <div class="dropdown-item">
                                    <span class="bs_sprite">
                                    </span>
<button class="icon-bttn">
                                    OK.ru
                                    </button>
</div>
</span>
<span>
                                <div class="dropdown-item">
                                    <span class="bs_sprite bs_douban">
                                    </span>
<button class="icon-bttn">
                                    Douban
                                    </button>
</div>
</span>
<span>
                                <div class="dropdown-item">
                                    <span class="bs_sprite bs_xing">
                                    </span>
<button class="icon-bttn">
                                    Xing
                                    </button>
</div>
</span>
<span>
                                <div class="dropdown-item">
                                    <span class="bs_sprite bs_renren">
                                    </span>
<button class="icon-bttn">
                                    RenRen
                                    </button>
</div>
</span>
<span>
                                <div class="dropdown-item">
                                    <span class="bs_sprite bs_sms">
                                    </span>
<button class="icon-bttn">
                                    SMS
                                    </button>
</div>
</span>
<span>
                                <div class="dropdown-item">
                                    <span class="bs_sprite bs_lineme">
                                    </span>
<button class="icon-bttn">
                                    Line.me
                                    </button>
</div>
</span>
<span>
                                <div class="dropdown-item">
                                    <span class="bs_sprite bs_skype">
                                    </span>
<button class="icon-bttn">
                                    Skype
                                    </button>
</div>
</span>
<span>
                                <div class="dropdown-item">
                                    <span class="bs_sprite bs_telegramme">
                                    </span>
<button class="icon-bttn">
                                    Telegram.me
                                    </button>
</div>
</span>
<span>
                                <div class="dropdown-item">
                                    <span class="bs_sprite bs_gmail">
                                    </span>
<button class="icon-bttn">
                                    Gmail
                                    </button>
</div>
</span>
<span>
                                <div class="dropdown-item">
                                    <span class="bs_sprite bs_yahoo">
                                    </span>
<button class="icon-bttn">
                                    Yahoo
                                    </button>
</div>
</span>
</div>
</div>
</li>
</ul>
</div>
</div>
</div>
</div>
</div>
</div>


<!--<div class="kOcQPs">
        
    <div class="tumMG">

            <div id="myCarousel" class="carousel slide mobile" data-ride="carousel" data-interval=1000000>
              <ol class="carousel-indicators">
                <li *ngIf="newsFilter(news[index1].type)[0]" data-target="#myCarousel" data-slide-to="0" class="active"></li>
                <li *ngIf="newsFilter(news[index1].type)[1]" data-target="#myCarousel" data-slide-to="1"></li>
                <li *ngIf="newsFilter(news[index1].type)[2]" data-target="#myCarousel" data-slide-to="2"></li>
                <li *ngIf="newsFilter(news[index1].type)[3]" data-target="#myCarousel" data-slide-to="3"></li>
                <li *ngIf="newsFilter(news[index1].type)[4]" data-target="#myCarousel" data-slide-to="4"></li>
              </ol>
          
              <div class="carousel-inner">
          
                <div class="item active" *ngIf="newsFilter(news[index1].type)[0]"  (click)="changeId(newsFilter(news[index1].type)[0].id)" routerLink="/news-media-detail/{{newsFilter(news[index1].type)[0].id}}" >
                  
                    <div class="kaVeKu" *ngIf="newsFilter(news[index1].type)[0].type!='Blog'">
                        <div class="dJuwOf">
                        <article [ngClass]="{'jRWwuk': newsFilter(news[index1].type)[0].type === 'Press Notes', 'jRWwuk1' : newsFilter(news[index1].type)[0].type === 'Press Release', 'jRWwuk2' : newsFilter(news[index1].type)[0].type === 'In the Media'  }">
                            <a class="kEzltY">
                            <div class="PctUQ">
                                <div class="fdUHvj">
                                <p class="dpkuuw">
                                    {{newsFilter(news[index1].type)[0].type}}
                                </p>
                                </div>
                                <span class="hbeOZQ">
                                {{newsFilter(news[index1].type)[0].created_at | date :  "MMM dd, yyyy"}}
                                </span>
                            </div>
                            <div class="fOTKLw">
                                <h2 class="fStkLY hLlol">
                                <div class="fUkExK">
                                    <div class="cjYGBp fyKMRa dWSsmD">
                                    {{removeTags(newsFilter(news[index1].type)[0].title)}}
                                    </div>
                                    
                                </div>
                                </h2>
                                <div class="dNaUyi">
                                </div>
                            </div>
                            </a>
                        </article>
                        </div>
                    </div>
                    <div class="gkduhq" *ngIf="newsFilter(news[index1].type)[0].type=='Blog'">
                        <article class="emKZKu" routerLink="/news-media-detail/{{newsFilter(news[index1].type)[0].id}}">
                          <a class="cnhNwd">
                            <div class="fPRcLx">
                              <div class="sc-bcXHqe fdUHvj snipcss0-5-5-6">
                                <p color="blue" data-test-id="news-item-label" class="sc-csuSiG dpkuuw snipcss0-6-6-7"><img class="elogo" src="../../assets/img/logoeblue.png">-{{newsFilter(news[index1].type)[0].type}}</p>
                                </div>
                                <span color="newsItemContentTextColor" data-test-id="news-item-date" class="sc-eDWCr hbeOZQ snipcss0-5-5-8">{{newsFilter(news[index1].type)[0].created_at | date :  "MMM dd, yyyy"}}</span>
                       
                            </div>
                            <div class="cXLmts">
                              <img class="cqrtwT" [src]="this.service.getPathImage()+''+newsFilter(news[index1].type)[0].image">
                            </div>
                            <div class="fIlstS">
                            </div>
                            <div class="lahaJw">
                              <h2 class="ceEGPo bhvNhv">
                                <div class="clXQjQ">
                                  <div class="eEYrZX isIbmM ldoCqD">
                                    {{removeTags(newsFilter(news[index1].type)[0].title)}}
                                  </div>
                                </div>
                              </h2>
                            </div>
                          </a>
                        </article>
                    </div>
              </div>
              <div class="item" *ngIf="newsFilter(news[index1].type)[1]"  (click)="changeId(newsFilter(news[index1].type)[1].id)" routerLink="/news-media-detail/{{newsFilter(news[index1].type)[1].id}}">
                  
                <div class="kaVeKu" *ngIf="newsFilter(news[index1].type)[0].type!='Blog'">
                    <div class="dJuwOf">
                    <article [ngClass]="{'jRWwuk': newsFilter(news[index1].type)[1].type === 'Press Notes', 'jRWwuk1' : newsFilter(news[index1].type)[1].type === 'Press Release', 'jRWwuk2' : newsFilter(news[index1].type)[1].type === 'In the Media'  }">
                        <a class="kEzltY">
                        <div class="PctUQ">
                            <div class="fdUHvj">
                            <p class="dpkuuw">
                                {{newsFilter(news[index1].type)[1].type}}
                            </p>
                            </div>
                            <span class="hbeOZQ">
                            {{newsFilter(news[index1].type)[1].created_at | date :  "MMM dd, yyyy"}}
                            </span>
                        </div>
                        <div class="fOTKLw">
                            <h2 class="fStkLY hLlol">
                            <div class="fUkExK">
                                <div class="cjYGBp fyKMRa dWSsmD">
                                {{removeTags(newsFilter(news[index1].type)[1].title)}}
                                </div>
                                
                            </div>
                            </h2>
                            <div class="dNaUyi">
                            </div>
                        </div>
                        </a>
                    </article>
                    </div>
                </div>
                
                <div class="gkduhq" *ngIf="newsFilter(news[index1].type)[1].type=='Blog'">
                    <article class="emKZKu" routerLink="/news-media-detail/{{newsFilter(news[index1].type)[1].id}}">
                      <a class="cnhNwd">
                        <div class="fPRcLx">
                          <div class="sc-bcXHqe fdUHvj snipcss0-5-5-6">
                            <p color="blue" data-test-id="news-item-label" class="sc-csuSiG dpkuuw snipcss0-6-6-7"><img class="elogo" src="../../assets/img/logoeblue.png">-{{newsFilter(news[index1].type)[1].type}}</p>
                            </div>
                            <span color="newsItemContentTextColor" data-test-id="news-item-date" class="sc-eDWCr hbeOZQ snipcss0-5-5-8">{{newsFilter(news[index1].type)[1].created_at | date :  "MMM dd, yyyy"}}</span>
                   
                        </div>
                        <div class="cXLmts">
                          <img class="cqrtwT" [src]="this.service.getPathImage()+''+newsFilter(news[index1].type)[1].image">
                        </div>
                        <div class="fIlstS">
                        </div>
                        <div class="lahaJw">
                          <h2 class="ceEGPo bhvNhv">
                            <div class="clXQjQ">
                              <div class="eEYrZX isIbmM ldoCqD">
                                {{removeTags(newsFilter(news[index1].type)[1].title)}}
                              </div>
                            </div>
                          </h2>
                        </div>
                      </a>
                    </article>
                </div>
              </div>
              <div class="item" *ngIf="newsFilter(news[index1].type)[2]"  (click)="changeId(newsFilter(news[index1].type)[2].id)" routerLink="/news-media-detail/{{newsFilter(news[index1].type)[2].id}}">
                  
                    <div class="kaVeKu" *ngIf="newsFilter(news[index1].type)[0].type!='Blog'">
                        <div class="dJuwOf">
                        <article [ngClass]="{'jRWwuk': newsFilter(news[index1].type)[2].type === 'Press Notes', 'jRWwuk1' : newsFilter(news[index1].type)[2].type === 'Press Release', 'jRWwuk2' : newsFilter(news[index1].type)[2].type === 'In the Media'  }">
                            <a class="kEzltY">
                            <div class="PctUQ">
                                <div class="fdUHvj">
                                <p class="dpkuuw">
                                    {{newsFilter(news[index1].type)[2].type}}
                                </p>
                                </div>
                                <span class="hbeOZQ">
                                {{newsFilter(news[index1].type)[2].created_at | date :  "MMM dd, yyyy"}}
                                </span>
                            </div>
                            <div class="fOTKLw">
                                <h2 class="fStkLY hLlol">
                                <div class="fUkExK">
                                    <div class="cjYGBp fyKMRa dWSsmD">
                                    {{removeTags(newsFilter(news[index1].type)[2].title)}}
                                    </div>
                                    
                                </div>
                                </h2>
                                <div class="dNaUyi">
                                </div>
                            </div>
                            </a>
                        </article>
                        </div>
                    </div>

                    <div class="gkduhq" *ngIf="newsFilter(news[index1].type)[2].type=='Blog'">
                        <article class="emKZKu" routerLink="/news-media-detail/{{newsFilter(news[index1].type)[2].id}}">
                          <a class="cnhNwd">
                            <div class="fPRcLx">
                              <div class="sc-bcXHqe fdUHvj snipcss0-5-5-6">
                                <p color="blue" data-test-id="news-item-label" class="sc-csuSiG dpkuuw snipcss0-6-6-7"><img class="elogo" src="../../assets/img/logoeblue.png">-{{newsFilter(news[index1].type)[2].type}}</p>
                                </div>
                                <span color="newsItemContentTextColor" data-test-id="news-item-date" class="sc-eDWCr hbeOZQ snipcss0-5-5-8">{{newsFilter(news[index1].type)[2].created_at | date :  "MMM dd, yyyy"}}</span>
                       
                            </div>
                            <div class="cXLmts">
                              <img class="cqrtwT" [src]="this.service.getPathImage()+''+newsFilter(news[index1].type)[2].image">
                            </div>
                            <div class="fIlstS">
                            </div>
                            <div class="lahaJw">
                              <h2 class="ceEGPo bhvNhv">
                                <div class="clXQjQ">
                                  <div class="eEYrZX isIbmM ldoCqD">
                                    {{removeTags(newsFilter(news[index1].type)[2].title)}}
                                  </div>
                                </div>
                              </h2>
                            </div>
                          </a>
                        </article>
                    </div>

              </div>
              <div class="item" *ngIf="newsFilter(news[index1].type)[3]"  (click)="changeId(newsFilter(news[index1].type)[3].id)" routerLink="/news-media-detail/{{newsFilter(news[index1].type)[3].id}}">
                  
                        <div class="kaVeKu" *ngIf="newsFilter(news[index1].type)[0].type!='Blog'">
                            <div class="dJuwOf">
                            <article [ngClass]="{'jRWwuk': newsFilter(news[index1].type)[3].type === 'Press Notes', 'jRWwuk1' : newsFilter(news[index1].type)[3].type === 'Press Release', 'jRWwuk2' : newsFilter(news[index1].type)[3].type === 'In the Media'  }">
                                <a class="kEzltY">
                                <div class="PctUQ">
                                    <div class="fdUHvj">
                                    <p class="dpkuuw">
                                        {{newsFilter(news[index1].type)[3].type}}
                                    </p>
                                    </div>
                                    <span class="hbeOZQ">
                                    {{newsFilter(news[index1].type)[3].created_at | date :  "MMM dd, yyyy"}}
                                    </span>
                                </div>
                                <div class="fOTKLw">
                                    <h2 class="fStkLY hLlol">
                                    <div class="fUkExK">
                                        <div class="cjYGBp fyKMRa dWSsmD">
                                        {{removeTags(newsFilter(news[index1].type)[3].title)}}
                                        </div>
                                        
                                    </div>
                                    </h2>
                                    <div class="dNaUyi">
                                    </div>
                                </div>
                                </a>
                            </article>
                            </div>
                        </div>

                        <div class="gkduhq" *ngIf="newsFilter(news[index1].type)[3].type=='Blog'">
                            <article class="emKZKu" routerLink="/news-media-detail/{{newsFilter(news[index1].type)[3].id}}">
                              <a class="cnhNwd">
                                <div class="fPRcLx">
                                  <div class="sc-bcXHqe fdUHvj snipcss0-5-5-6">
                                    <p color="blue" data-test-id="news-item-label" class="sc-csuSiG dpkuuw snipcss0-6-6-7"><img class="elogo" src="../../assets/img/logoeblue.png">-{{newsFilter(news[index1].type)[3].type}}</p>
                                    </div>
                                    <span color="newsItemContentTextColor" data-test-id="news-item-date" class="sc-eDWCr hbeOZQ snipcss0-5-5-8">{{newsFilter(news[index1].type)[3].created_at | date :  "MMM dd, yyyy"}}</span>
                           
                                </div>
                                <div class="cXLmts">
                                  <img class="cqrtwT" [src]="this.service.getPathImage()+''+newsFilter(news[index1].type)[3].image">
                                </div>
                                <div class="fIlstS">
                                </div>
                                <div class="lahaJw">
                                  <h2 class="ceEGPo bhvNhv">
                                    <div class="clXQjQ">
                                      <div class="eEYrZX isIbmM ldoCqD">
                                        {{removeTags(newsFilter(news[index1].type)[3].title)}}
                                      </div>
                                    </div>
                                  </h2>
                                </div>
                              </a>
                            </article>
                        </div>
              </div>
              <div class="item" *ngIf="newsFilter(news[index1].type)[4]"  (click)="changeId(newsFilter(news[index1].type)[4].id)" routerLink="/news-media-detail/{{newsFilter(news[index1].type)[4].id}}">
                  
                            <div class="kaVeKu" *ngIf="newsFilter(news[index1].type)[0].type!='Blog'">
                                <div class="dJuwOf">
                                <article [ngClass]="{'jRWwuk': newsFilter(news[index1].type)[4].type === 'Press Notes', 'jRWwuk1' : newsFilter(news[index1].type)[4].type === 'Press Release', 'jRWwuk2' : newsFilter(news[index1].type)[4].type === 'In the Media'  }">
                                    <a class="kEzltY">
                                    <div class="PctUQ">
                                        <div class="fdUHvj">
                                        <p class="dpkuuw">
                                            {{newsFilter(news[index1].type)[4].type}}
                                        </p>
                                        </div>
                                        <span class="hbeOZQ">
                                        {{newsFilter(news[index1].type)[4].created_at | date :  "MMM dd, yyyy"}}
                                        </span>
                                    </div>
                                    <div class="fOTKLw">
                                        <h2 class="fStkLY hLlol">
                                        <div class="fUkExK">
                                            <div class="cjYGBp fyKMRa dWSsmD">
                                            {{removeTags(newsFilter(news[index1].type)[4].title)}}
                                            </div>
                                            
                                        </div>
                                        </h2>
                                        <div class="dNaUyi">
                                        </div>
                                    </div>
                                    </a>
                                </article>
                                </div>
                            </div>

                            <div class="gkduhq" *ngIf="newsFilter(news[index1].type)[4].type=='Blog'">
                    <article class="emKZKu" routerLink="/news-media-detail/{{newsFilter(news[index1].type)[4].id}}">
                      <a class="cnhNwd">
                        <div class="fPRcLx">
                          <div class="sc-bcXHqe fdUHvj snipcss0-5-5-6">
                            <p color="blue" data-test-id="news-item-label" class="sc-csuSiG dpkuuw snipcss0-6-6-7"><img class="elogo" src="../../assets/img/logoeblue.png">-{{newsFilter(news[index1].type)[4].type}}</p>
                            </div>
                            <span color="newsItemContentTextColor" data-test-id="news-item-date" class="sc-eDWCr hbeOZQ snipcss0-5-5-8">{{newsFilter(news[index1].type)[4].created_at | date :  "MMM dd, yyyy"}}</span>
                   
                        </div>
                        <div class="cXLmts">
                          <img class="cqrtwT" [src]="this.service.getPathImage()+''+newsFilter(news[index1].type)[4].image">
                        </div>
                        <div class="fIlstS">
                        </div>
                        <div class="lahaJw">
                          <h2 class="ceEGPo bhvNhv">
                            <div class="clXQjQ">
                              <div class="eEYrZX isIbmM ldoCqD">
                                {{removeTags(newsFilter(news[index1].type)[4].title)}}
                              </div>
                            </div>
                          </h2>
                        </div>
                      </a>
                    </article>
                            </div>
              </div>
            
              </div>
          
             
              <a class="carousel-control-prev" href="#myCarousel" role="button" data-slide="prev">
                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                <span class="sr-only">Previous</span>
              </a>
              <a class="carousel-control-next" href="#myCarousel" role="button" data-slide="next">
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                <span class="sr-only">Next</span>
              </a>
            </div>
          </div>
          
      
              
        
        
<div class="container">
            <div id="myCarousel1" class="carousel slide desktop" data-ride="carousel" data-interval=1000000>
         
              <ol class="carousel-indicators">
                <li *ngIf="newsFilter(news[index1].type)[0]" data-target="#myCarousel1" data-slide-to="0" class="active"></li>
                <li *ngIf="newsFilter(news[index1].type)[3]" data-target="#myCarousel1" data-slide-to="1"></li>
                <li *ngIf="newsFilter(news[index1].type)[4]" data-target="#myCarousel1" data-slide-to="2"></li>
              </ol>
          
              <div class="row">
                <div class="col-lg-2">
                    </div>
                <div class="col-lg-8">
                    
                <div class="carousel-inner">
                    <div class="item active" *ngIf="newsFilter(news[index1].type)[0]">
                        <span *ngIf="newsFilter(news[index1].type)[0]"><div [ngClass]="{'kaVeKu1': newsFilter(news[index1].type).length === 1,'kaVeKu2': newsFilter(news[index1].type).length === 2}"></div></span>
                        <span *ngIf="newsFilter(news[index1].type)[0]">
                          <div class="kaVeKu" *ngIf="this.id!=newsFilter(news[index1].type)[0].id && newsFilter(news[index1].type)[0].type!='Blog'" (click)="changeId(newsFilter(news[index1].type)[0].id)" routerLink="/news-media-detail/{{newsFilter(news[index1].type)[0].id}}">
                              <div class="dJuwOf">
                              <article [ngClass]="{'jRWwuk': newsFilter(news[index1].type)[0].type === 'Press Notes', 'jRWwuk1' : newsFilter(news[index1].type)[0].type === 'Press Release', 'jRWwuk2' : newsFilter(news[index1].type)[0].type === 'In the Media'  }">
                                  <a class="kEzltY">
                                  <div class="PctUQ">
                                      <div class="fdUHvj">
                                      <p class="dpkuuw">
                                          {{newsFilter(news[index1].type)[0].type}}
                                      </p>
                                      </div>
                                      <span class="hbeOZQ">
                                      {{newsFilter(news[index1].type)[0].created_at | date :  "MMM dd, yyyy"}}
                                      </span>
                                  </div>
                                  <div class="fOTKLw">
                                      <h2 class="fStkLY hLlol">
                                      <div class="fUkExK">
                                          <div class="cjYGBp fyKMRa dWSsmD">
                                          {{removeTags(newsFilter(news[index1].type)[0].title)}}
                                          </div>
                                          
                                      </div>
                                      </h2>
                                      <div class="dNaUyi">
                                      </div>
                                  </div>
                                  </a>
                              </article>
                              </div>
                          </div>
                          <div class="kaVeKu" *ngIf="this.id!=newsFilter(news[index1].type)[0].id && newsFilter(news[index1].type)[0].type=='Blog'" (click)="changeId(newsFilter(news[index1].type)[0].id)" routerLink="/news-media-detail/{{newsFilter(news[index1].type)[0].id}}">
                            <div class="dJuwOf">
                                <article class="emKZKu" routerLink="/news-media-detail/{{newsFilter(news[index1].type)[0].id}}">
                                    <a class="cnhNwd">
                                      <div class="fPRcLx">
                                        <div class="sc-bcXHqe fdUHvj snipcss0-5-5-6">
                                          <p color="blue" data-test-id="news-item-label" class="sc-csuSiG dpkuuw snipcss0-6-6-7"><img class="elogo" src="../../assets/img/logoeblue.png">-{{newsFilter(news[index1].type)[0].type}}</p>
                                          </div>
                                          <span color="newsItemContentTextColor" data-test-id="news-item-date" class="sc-eDWCr hbeOZQ snipcss0-5-5-8">{{newsFilter(news[index1].type)[0].created_at | date :  "MMM dd, yyyy"}}</span>
                                 
                                      </div>
                                      <div class="cXLmts">
                                        <img class="cqrtwT" [src]="this.service.getPathImage()+''+newsFilter(news[index1].type)[0].image">
                                      </div>
                                      <div class="fIlstS">
                                      </div>
                                      <div class="lahaJw">
                                        <h2 class="ceEGPo bhvNhv">
                                          <div class="clXQjQ">
                                            <div class="eEYrZX isIbmM ldoCqD">
                                              {{removeTags(newsFilter(news[index1].type)[0].title)}}
                                            </div>
                                          </div>
                                        </h2>
                                      </div>
                                    </a>
                                  </article>
                            </div>
                        </div>
                        </span>
                        <span *ngIf="newsFilter(news[index1].type)[1]">
                          <div class="kaVeKu" *ngIf="this.id!=newsFilter(news[index1].type)[1].id && newsFilter(news[index1].type)[1].type!='Blog'"  (click)="changeId(newsFilter(news[index1].type)[1].id)" routerLink="/news-media-detail/{{newsFilter(news[index1].type)[1].id}}">
                              <div class="dJuwOf">
                              <article [ngClass]="{'jRWwuk': newsFilter(news[index1].type)[1].type === 'Press Notes', 'jRWwuk1' : newsFilter(news[index1].type)[1].type === 'Press Release', 'jRWwuk2' : newsFilter(news[index1].type)[1].type === 'In the Media'  }">
                                  <a class="kEzltY">
                                  <div class="PctUQ">
                                      <div class="fdUHvj">
                                      <p class="dpkuuw">
                                          {{newsFilter(news[index1].type)[1].type}}
                                      </p>
                                      </div>
                                      <span class="hbeOZQ">
                                      {{newsFilter(news[index1].type)[1].created_at | date :  "MMM dd, yyyy"}}
                                      </span>
                                  </div>
                                  <div class="fOTKLw">
                                      <h2 class="fStkLY hLlol">
                                      <div class="fUkExK">
                                          <div class="cjYGBp fyKMRa dWSsmD">
                                          {{removeTags(newsFilter(news[index1].type)[1].title)}}
                                          </div>
                                          
                                      </div>
                                      </h2>
                                      <div class="dNaUyi">
                                      </div>
                                  </div>
                                  </a>
                              </article>
                              </div>
                          </div>
                          <div class="kaVeKu" *ngIf="this.id!=newsFilter(news[index1].type)[1].id && newsFilter(news[index1].type)[1].type=='Blog'"  (click)="changeId(newsFilter(news[index1].type)[1].id)" routerLink="/news-media-detail/{{newsFilter(news[index1].type)[1].id}}">
                            <div class="dJuwOf">
                                <article class="emKZKu" routerLink="/news-media-detail/{{newsFilter(news[index1].type)[1].id}}">
                                    <a class="cnhNwd">
                                      <div class="fPRcLx">
                                        <div class="sc-bcXHqe fdUHvj snipcss0-5-5-6">
                                          <p color="blue" data-test-id="news-item-label" class="sc-csuSiG dpkuuw snipcss0-6-6-7"><img class="elogo" src="../../assets/img/logoeblue.png">-{{newsFilter(news[index1].type)[1].type}}</p>
                                          </div>
                                          <span color="newsItemContentTextColor" data-test-id="news-item-date" class="sc-eDWCr hbeOZQ snipcss0-5-5-8">{{newsFilter(news[index1].type)[1].created_at | date :  "MMM dd, yyyy"}}</span>
                                 
                                      </div>
                                      <div class="cXLmts">
                                        <img class="cqrtwT" [src]="this.service.getPathImage()+''+newsFilter(news[index1].type)[1].image">
                                      </div>
                                      <div class="fIlstS">
                                      </div>
                                      <div class="lahaJw">
                                        <h2 class="ceEGPo bhvNhv">
                                          <div class="clXQjQ">
                                            <div class="eEYrZX isIbmM ldoCqD">
                                              {{removeTags(newsFilter(news[index1].type)[1].title)}}
                                            </div>
                                          </div>
                                        </h2>
                                      </div>
                                    </a>
                                  </article>
                            </div>
                        </div>
                        </span>
                        <span *ngIf="newsFilter(news[index1].type)[2]">
                          <div class="kaVeKu" *ngIf="this.id!=newsFilter(news[index1].type)[2].id && newsFilter(news[index1].type)[2].type!='Blog'"  (click)="changeId(newsFilter(news[index1].type)[2].id)" routerLink="/news-media-detail/{{newsFilter(news[index1].type)[2].id}}">
                              <div class="dJuwOf">
                              <article [ngClass]="{'jRWwuk': newsFilter(news[index1].type)[2].type === 'Press Notes', 'jRWwuk1' : newsFilter(news[index1].type)[2].type === 'Press Release', 'jRWwuk2' : newsFilter(news[index1].type)[2].type === 'In the Media'  }">
                                  <a class="kEzltY">
                                  <div class="PctUQ">
                                      <div class="fdUHvj">
                                      <p class="dpkuuw">
                                          {{newsFilter(news[index1].type)[2].type}}
                                      </p>
                                      </div>
                                      <span class="hbeOZQ">
                                      {{newsFilter(news[index1].type)[2].created_at | date :  "MMM dd, yyyy"}}
                                      </span>
                                  </div>
                                  <div class="fOTKLw">
                                      <h2 class="fStkLY hLlol">
                                      <div class="fUkExK">
                                          <div class="cjYGBp fyKMRa dWSsmD">
                                          {{removeTags(newsFilter(news[index1].type)[2].title)}}
                                          </div>
                                          
                                      </div>
                                      </h2>
                                      <div class="dNaUyi">
                                      </div>
                                  </div>
                                  </a>
                              </article>
                              </div>
                          </div>
                          <div class="kaVeKu" *ngIf="this.id!=newsFilter(news[index1].type)[2].id && newsFilter(news[index1].type)[2].type=='Blog'"  (click)="changeId(newsFilter(news[index1].type)[2].id)" routerLink="/news-media-detail/{{newsFilter(news[index1].type)[2].id}}">
                            <div class="dJuwOf">
                                <article class="emKZKu" routerLink="/news-media-detail/{{newsFilter(news[index1].type)[2].id}}">
                                    <a class="cnhNwd">
                                      <div class="fPRcLx">
                                        <div class="sc-bcXHqe fdUHvj snipcss0-5-5-6">
                                          <p color="blue" data-test-id="news-item-label" class="sc-csuSiG dpkuuw snipcss0-6-6-7"><img class="elogo" src="../../assets/img/logoeblue.png">-{{newsFilter(news[index1].type)[2].type}}</p>
                                          </div>
                                          <span color="newsItemContentTextColor" data-test-id="news-item-date" class="sc-eDWCr hbeOZQ snipcss0-5-5-8">{{newsFilter(news[index1].type)[2].created_at | date :  "MMM dd, yyyy"}}</span>
                                 
                                      </div>
                                      <div class="cXLmts">
                                        <img class="cqrtwT" [src]="this.service.getPathImage()+''+newsFilter(news[index1].type)[2].image">
                                      </div>
                                      <div class="fIlstS">
                                      </div>
                                      <div class="lahaJw">
                                        <h2 class="ceEGPo bhvNhv">
                                          <div class="clXQjQ">
                                            <div class="eEYrZX isIbmM ldoCqD">
                                              {{removeTags(newsFilter(news[index1].type)[2].title)}}
                                            </div>
                                          </div>
                                        </h2>
                                      </div>
                                    </a>
                                  </article>
                            </div>
                        </div>
                    </span>
                    </div>
                    <div class="item" *ngIf="newsFilter(news[index1].type)[3]">
                        <span *ngIf="newsFilter(news[index1].type)[0]"><div [ngClass]="{'kaVeKu1': newsFilter(news[index1].type).length === 2,'kaVeKu2': newsFilter(news[index1].type).length === 3}"></div></span>
                        <span *ngIf="newsFilter(news[index1].type)[1]">
                            <div class="kaVeKu" *ngIf="this.id!=newsFilter(news[index1].type)[1].id"  (click)="changeId(newsFilter(news[index1].type)[1].id)" routerLink="/news-media-detail/{{newsFilter(news[index1].type)[1].id}}">
                                <div class="dJuwOf">
                                <article [ngClass]="{'jRWwuk': newsFilter(news[index1].type)[1].type === 'Press Notes', 'jRWwuk1' : newsFilter(news[index1].type)[1].type === 'Press Release', 'jRWwuk2' : newsFilter(news[index1].type)[1].type === 'In the Media'  }">
                                    <a class="kEzltY">
                                    <div class="PctUQ">
                                        <div class="fdUHvj">
                                        <p class="dpkuuw">
                                            {{newsFilter(news[index1].type)[1].type}}
                                        </p>
                                        </div>
                                        <span class="hbeOZQ">
                                        {{newsFilter(news[index1].type)[1].created_at | date :  "MMM dd, yyyy"}}
                                        </span>
                                    </div>
                                    <div class="fOTKLw">
                                        <h2 class="fStkLY hLlol">
                                        <div class="fUkExK">
                                            <div class="cjYGBp fyKMRa dWSsmD">
                                            {{removeTags(newsFilter(news[index1].type)[1].title)}}
                                            </div>
                                            
                                        </div>
                                        </h2>
                                        <div class="dNaUyi">
                                        </div>
                                    </div>
                                    </a>
                                </article>
                                </div>
                            </div>
                            <div class="kaVeKu" *ngIf="this.id!=newsFilter(news[index1].type)[1].id && newsFilter(news[index1].type)[1].type=='Blog'"  (click)="changeId(newsFilter(news[index1].type)[1].id)" routerLink="/news-media-detail/{{newsFilter(news[index1].type)[2].id}}">
                                <div class="dJuwOf">
                                    <article class="emKZKu" routerLink="/news-media-detail/{{newsFilter(news[index1].type)[2].id}}">
                                        <a class="cnhNwd">
                                          <div class="fPRcLx">
                                            <div class="sc-bcXHqe fdUHvj snipcss0-5-5-6">
                                              <p color="blue" data-test-id="news-item-label" class="sc-csuSiG dpkuuw snipcss0-6-6-7"><img class="elogo" src="../../assets/img/logoeblue.png">-{{newsFilter(news[index1].type)[2].type}}</p>
                                              </div>
                                              <span color="newsItemContentTextColor" data-test-id="news-item-date" class="sc-eDWCr hbeOZQ snipcss0-5-5-8">{{newsFilter(news[index1].type)[2].created_at | date :  "MMM dd, yyyy"}}</span>
                                     
                                          </div>
                                          <div class="cXLmts">
                                            <img class="cqrtwT" [src]="this.service.getPathImage()+''+newsFilter(news[index1].type)[2].image">
                                          </div>
                                          <div class="fIlstS">
                                          </div>
                                          <div class="lahaJw">
                                            <h2 class="ceEGPo bhvNhv">
                                              <div class="clXQjQ">
                                                <div class="eEYrZX isIbmM ldoCqD">
                                                  {{removeTags(newsFilter(news[index1].type)[2].title)}}
                                                </div>
                                              </div>
                                            </h2>
                                          </div>
                                        </a>
                                      </article>
                                </div>
                            </div>
                        </span>
                        <span *ngIf="newsFilter(news[index1].type)[2]">
                            <div class="kaVeKu" *ngIf="this.id!=newsFilter(news[index1].type)[2].id"  (click)="changeId(newsFilter(news[index1].type)[2].id)" routerLink="/news-media-detail/{{newsFilter(news[index1].type)[2].id}}">
                                <div class="dJuwOf">
                                <article [ngClass]="{'jRWwuk': newsFilter(news[index1].type)[2].type === 'Press Notes', 'jRWwuk1' : newsFilter(news[index1].type)[2].type === 'Press Release', 'jRWwuk2' : newsFilter(news[index1].type)[2].type === 'In the Media'  }">
                                    <a class="kEzltY">
                                    <div class="PctUQ">
                                        <div class="fdUHvj">
                                        <p class="dpkuuw">
                                            {{newsFilter(news[index1].type)[2].type}}
                                        </p>
                                        </div>
                                        <span class="hbeOZQ">
                                        {{newsFilter(news[index1].type)[2].created_at | date :  "MMM dd, yyyy"}}
                                        </span>
                                    </div>
                                    <div class="fOTKLw">
                                        <h2 class="fStkLY hLlol">
                                        <div class="fUkExK">
                                            <div class="cjYGBp fyKMRa dWSsmD">
                                            {{removeTags(newsFilter(news[index1].type)[2].title)}}
                                            </div>
                                            
                                        </div>
                                        </h2>
                                        <div class="dNaUyi">
                                        </div>
                                    </div>
                                    </a>
                                </article>
                                </div>
                            </div>
                            <div class="kaVeKu" *ngIf="this.id!=newsFilter(news[index1].type)[2].id && newsFilter(news[index1].type)[2].type=='Blog'"  (click)="changeId(newsFilter(news[index1].type)[2].id)" routerLink="/news-media-detail/{{newsFilter(news[index1].type)[2].id}}">
                                <div class="dJuwOf">
                                    <article class="emKZKu" routerLink="/news-media-detail/{{newsFilter(news[index1].type)[2].id}}">
                                        <a class="cnhNwd">
                                        <div class="fPRcLx">
                                            <div class="sc-bcXHqe fdUHvj snipcss0-5-5-6">
                                            <p color="blue" data-test-id="news-item-label" class="sc-csuSiG dpkuuw snipcss0-6-6-7"><img class="elogo" src="../../assets/img/logoeblue.png">-{{newsFilter(news[index1].type)[2].type}}</p>
                                            </div>
                                            <span color="newsItemContentTextColor" data-test-id="news-item-date" class="sc-eDWCr hbeOZQ snipcss0-5-5-8">{{newsFilter(news[index1].type)[2].created_at | date :  "MMM dd, yyyy"}}</span>
                                    
                                        </div>
                                        <div class="cXLmts">
                                            <img class="cqrtwT" [src]="this.service.getPathImage()+''+newsFilter(news[index1].type)[2].image">
                                        </div>
                                        <div class="fIlstS">
                                        </div>
                                        <div class="lahaJw">
                                            <h2 class="ceEGPo bhvNhv">
                                            <div class="clXQjQ">
                                                <div class="eEYrZX isIbmM ldoCqD">
                                                {{removeTags(newsFilter(news[index1].type)[2].title)}}
                                                </div>
                                            </div>
                                            </h2>
                                        </div>
                                        </a>
                                    </article>
                                </div>
                            </div>
                        </span>
                        <span *ngIf="newsFilter(news[index1].type)[3]">
                            <div class="kaVeKu" *ngIf="this.id!=newsFilter(news[index1].type)[3].id"  (click)="changeId(newsFilter(news[index1].type)[3].id)" routerLink="/news-media-detail/{{newsFilter(news[index1].type)[3].id}}">
                                <div class="dJuwOf">
                                <article [ngClass]="{'jRWwuk': newsFilter(news[index1].type)[3].type === 'Press Notes', 'jRWwuk1' : newsFilter(news[index1].type)[3].type === 'Press Release', 'jRWwuk2' : newsFilter(news[index1].type)[3].type === 'In the Media'  }">
                                    <a class="kEzltY">
                                    <div class="PctUQ">
                                        <div class="fdUHvj">
                                        <p class="dpkuuw">
                                            {{newsFilter(news[index1].type)[3].type}}
                                        </p>
                                        </div>
                                        <span class="hbeOZQ">
                                        {{newsFilter(news[index1].type)[3].created_at | date :  "MMM dd, yyyy"}}
                                        </span>
                                    </div>
                                    <div class="fOTKLw">
                                        <h2 class="fStkLY hLlol">
                                        <div class="fUkExK">
                                            <div class="cjYGBp fyKMRa dWSsmD">
                                            {{removeTags(newsFilter(news[index1].type)[3].title)}}
                                            </div>
                                            
                                        </div>
                                        </h2>
                                        <div class="dNaUyi">
                                        </div>
                                    </div>
                                    </a>
                                </article>
                                </div>
                            </div>
                            <div class="kaVeKu" *ngIf="this.id!=newsFilter(news[index1].type)[3].id && newsFilter(news[index1].type)[3].type=='Blog'"  (click)="changeId(newsFilter(news[index1].type)[3].id)" routerLink="/news-media-detail/{{newsFilter(news[index1].type)[2].id}}">
                                <div class="dJuwOf">
                                    <article class="emKZKu" routerLink="/news-media-detail/{{newsFilter(news[index1].type)[2].id}}">
                                        <a class="cnhNwd">
                                        <div class="fPRcLx">
                                            <div class="sc-bcXHqe fdUHvj snipcss0-5-5-6">
                                            <p color="blue" data-test-id="news-item-label" class="sc-csuSiG dpkuuw snipcss0-6-6-7"><img class="elogo" src="../../assets/img/logoeblue.png">-{{newsFilter(news[index1].type)[2].type}}</p>
                                            </div>
                                            <span color="newsItemContentTextColor" data-test-id="news-item-date" class="sc-eDWCr hbeOZQ snipcss0-5-5-8">{{newsFilter(news[index1].type)[2].created_at | date :  "MMM dd, yyyy"}}</span>
                                    
                                        </div>
                                        <div class="cXLmts">
                                            <img class="cqrtwT" [src]="this.service.getPathImage()+''+newsFilter(news[index1].type)[2].image">
                                        </div>
                                        <div class="fIlstS">
                                        </div>
                                        <div class="lahaJw">
                                            <h2 class="ceEGPo bhvNhv">
                                            <div class="clXQjQ">
                                                <div class="eEYrZX isIbmM ldoCqD">
                                                {{removeTags(newsFilter(news[index1].type)[2].title)}}
                                                </div>
                                            </div>
                                            </h2>
                                        </div>
                                        </a>
                                    </article>
                                </div>
                            </div>
                        </span>
                    </div>
      
                    <div class="item" *ngIf="newsFilter(news[index1].type)[4]">
                        <span *ngIf="newsFilter(news[index1].type)[0]"><div [ngClass]="{'kaVeKu1': newsFilter(news[index1].type).length === 3,'kaVeKu2': newsFilter(news[index1].type).length === 4}"></div></span>
                        <span *ngIf="newsFilter(news[index1].type)[2]">
                            <div class="kaVeKu" *ngIf="this.id!=newsFilter(news[index1].type)[2].id"  (click)="changeId(newsFilter(news[index1].type)[2].id)" routerLink="/news-media-detail/{{newsFilter(news[index1].type)[2].id}}">
                                <div class="dJuwOf">
                                <article [ngClass]="{'jRWwuk': newsFilter(news[index1].type)[2].type === 'Press Notes', 'jRWwuk1' : newsFilter(news[index1].type)[2].type === 'Press Release', 'jRWwuk2' : newsFilter(news[index1].type)[2].type === 'In the Media'  }">
                                    <a class="kEzltY">
                                    <div class="PctUQ">
                                        <div class="fdUHvj">
                                        <p class="dpkuuw">
                                            {{newsFilter(news[index1].type)[2].type}}
                                        </p>
                                        </div>
                                        <span class="hbeOZQ">
                                        {{newsFilter(news[index1].type)[2].created_at | date :  "MMM dd, yyyy"}}
                                        </span>
                                    </div>
                                    <div class="fOTKLw">
                                        <h2 class="fStkLY hLlol">
                                        <div class="fUkExK">
                                            <div class="cjYGBp fyKMRa dWSsmD">
                                            {{removeTags(newsFilter(news[index1].type)[2].title)}}
                                            </div>
                                            
                                        </div>
                                        </h2>
                                        <div class="dNaUyi">
                                        </div>
                                    </div>
                                    </a>
                                </article>
                                </div>
                            </div>
                            <div class="kaVeKu" *ngIf="this.id!=newsFilter(news[index1].type)[2].id && newsFilter(news[index1].type)[2].type=='Blog'"  (click)="changeId(newsFilter(news[index1].type)[2].id)" routerLink="/news-media-detail/{{newsFilter(news[index1].type)[2].id}}">
                                <div class="dJuwOf">
                                    <article class="emKZKu" routerLink="/news-media-detail/{{newsFilter(news[index1].type)[2].id}}">
                                        <a class="cnhNwd">
                                        <div class="fPRcLx">
                                            <div class="sc-bcXHqe fdUHvj snipcss0-5-5-6">
                                            <p color="blue" data-test-id="news-item-label" class="sc-csuSiG dpkuuw snipcss0-6-6-7"><img class="elogo" src="../../assets/img/logoeblue.png">-{{newsFilter(news[index1].type)[2].type}}</p>
                                            </div>
                                            <span color="newsItemContentTextColor" data-test-id="news-item-date" class="sc-eDWCr hbeOZQ snipcss0-5-5-8">{{newsFilter(news[index1].type)[2].created_at | date :  "MMM dd, yyyy"}}</span>
                                    
                                        </div>
                                        <div class="cXLmts">
                                            <img class="cqrtwT" [src]="this.service.getPathImage()+''+newsFilter(news[index1].type)[2].image">
                                        </div>
                                        <div class="fIlstS">
                                        </div>
                                        <div class="lahaJw">
                                            <h2 class="ceEGPo bhvNhv">
                                            <div class="clXQjQ">
                                                <div class="eEYrZX isIbmM ldoCqD">
                                                {{removeTags(newsFilter(news[index1].type)[2].title)}}
                                                </div>
                                            </div>
                                            </h2>
                                        </div>
                                        </a>
                                    </article>
                                </div>
                            </div>
                        </span>
                        <span *ngIf="newsFilter(news[index1].type)[3]">
                            <div class="kaVeKu" *ngIf="this.id!=newsFilter(news[index1].type)[3].id"  (click)="changeId(newsFilter(news[index1].type)[3].id)" routerLink="/news-media-detail/{{newsFilter(news[index1].type)[3].id}}">
                                <div class="dJuwOf">
                                <article [ngClass]="{'jRWwuk': newsFilter(news[index1].type)[3].type === 'Press Notes', 'jRWwuk1' : newsFilter(news[index1].type)[3].type === 'Press Release', 'jRWwuk2' : newsFilter(news[index1].type)[3].type === 'In the Media'  }">
                                    <a class="kEzltY">
                                    <div class="PctUQ">
                                        <div class="fdUHvj">
                                        <p class="dpkuuw">
                                            {{newsFilter(news[index1].type)[3].type}}
                                        </p>
                                        </div>
                                        <span class="hbeOZQ">
                                        {{newsFilter(news[index1].type)[3].created_at | date :  "MMM dd, yyyy"}}
                                        </span>
                                    </div>
                                    <div class="fOTKLw">
                                        <h2 class="fStkLY hLlol">
                                        <div class="fUkExK">
                                            <div class="cjYGBp fyKMRa dWSsmD">
                                            {{removeTags(newsFilter(news[index1].type)[3].title)}}
                                            </div>
                                            
                                        </div>
                                        </h2>
                                        <div class="dNaUyi">
                                        </div>
                                    </div>
                                    </a>
                                </article>
                                </div>
                            </div>
                            <div class="kaVeKu" *ngIf="this.id!=newsFilter(news[index1].type)[3].id && newsFilter(news[index1].type)[3].type=='Blog'"  (click)="changeId(newsFilter(news[index1].type)[2].id)" routerLink="/news-media-detail/{{newsFilter(news[index1].type)[3].id}}">
                                <div class="dJuwOf">
                                    <article class="emKZKu" routerLink="/news-media-detail/{{newsFilter(news[index1].type)[2].id}}">
                                        <a class="cnhNwd">
                                        <div class="fPRcLx">
                                            <div class="sc-bcXHqe fdUHvj snipcss0-5-5-6">
                                            <p color="blue" data-test-id="news-item-label" class="sc-csuSiG dpkuuw snipcss0-6-6-7"><img class="elogo" src="../../assets/img/logoeblue.png">-{{newsFilter(news[index1].type)[2].type}}</p>
                                            </div>
                                            <span color="newsItemContentTextColor" data-test-id="news-item-date" class="sc-eDWCr hbeOZQ snipcss0-5-5-8">{{newsFilter(news[index1].type)[2].created_at | date :  "MMM dd, yyyy"}}</span>
                                    
                                        </div>
                                        <div class="cXLmts">
                                            <img class="cqrtwT" [src]="this.service.getPathImage()+''+newsFilter(news[index1].type)[2].image">
                                        </div>
                                        <div class="fIlstS">
                                        </div>
                                        <div class="lahaJw">
                                            <h2 class="ceEGPo bhvNhv">
                                            <div class="clXQjQ">
                                                <div class="eEYrZX isIbmM ldoCqD">
                                                {{removeTags(newsFilter(news[index1].type)[2].title)}}
                                                </div>
                                            </div>
                                            </h2>
                                        </div>
                                        </a>
                                    </article>
                                </div>
                            </div>
                        </span>
                        <span *ngIf="newsFilter(news[index1].type)[4]">
                            <div class="kaVeKu" *ngIf="this.id!=newsFilter(news[index1].type)[4].id"  (click)="changeId(newsFilter(news[index1].type)[4].id)" routerLink="/news-media-detail/{{newsFilter(news[index1].type)[4].id}}">
                                <div class="dJuwOf">
                                <article [ngClass]="{'jRWwuk': newsFilter(news[index1].type)[4].type === 'Press Notes', 'jRWwuk1' : newsFilter(news[index1].type)[4].type === 'Press Release', 'jRWwuk2' : newsFilter(news[index1].type)[4].type === 'In the Media'  }">
                                    <a class="kEzltY">
                                    <div class="PctUQ">
                                        <div class="fdUHvj">
                                        <p class="dpkuuw">
                                            {{newsFilter(news[index1].type)[4].type}}
                                        </p>
                                        </div>
                                        <span class="hbeOZQ">
                                        {{newsFilter(news[index1].type)[4].created_at | date :  "MMM dd, yyyy"}}
                                        </span>
                                    </div>
                                    <div class="fOTKLw">
                                        <h2 class="fStkLY hLlol">
                                        <div class="fUkExK">
                                            <div class="cjYGBp fyKMRa dWSsmD">
                                            {{removeTags(newsFilter(news[index1].type)[4].title)}}
                                            </div>
                                            
                                        </div>
                                        </h2>
                                        <div class="dNaUyi">
                                        </div>
                                    </div>
                                    </a>
                                </article>
                                </div>
                            </div>
                            <div class="kaVeKu" *ngIf="this.id!=newsFilter(news[index1].type)[4].id && newsFilter(news[index1].type)[4].type=='Blog'"  (click)="changeId(newsFilter(news[index1].type)[2].id)" routerLink="/news-media-detail/{{newsFilter(news[index1].type)[4].id}}">
                                <div class="dJuwOf">
                                    <article class="emKZKu" routerLink="/news-media-detail/{{newsFilter(news[index1].type)[2].id}}">
                                        <a class="cnhNwd">
                                        <div class="fPRcLx">
                                            <div class="sc-bcXHqe fdUHvj snipcss0-5-5-6">
                                            <p color="blue" data-test-id="news-item-label" class="sc-csuSiG dpkuuw snipcss0-6-6-7"><img class="elogo" src="../../assets/img/logoeblue.png">-{{newsFilter(news[index1].type)[2].type}}</p>
                                            </div>
                                            <span color="newsItemContentTextColor" data-test-id="news-item-date" class="sc-eDWCr hbeOZQ snipcss0-5-5-8">{{newsFilter(news[index1].type)[2].created_at | date :  "MMM dd, yyyy"}}</span>
                                    
                                        </div>
                                        <div class="cXLmts">
                                            <img class="cqrtwT" [src]="this.service.getPathImage()+''+newsFilter(news[index1].type)[2].image">
                                        </div>
                                        <div class="fIlstS">
                                        </div>
                                        <div class="lahaJw">
                                            <h2 class="ceEGPo bhvNhv">
                                            <div class="clXQjQ">
                                                <div class="eEYrZX isIbmM ldoCqD">
                                                {{removeTags(newsFilter(news[index1].type)[2].title)}}
                                                </div>
                                            </div>
                                            </h2>
                                        </div>
                                        </a>
                                    </article>
                                </div>
                            </div>
                        </span>
                    </div>
                </div>
                </div>
                <div class="col-lg-2">
                    </div>
               </div>
    
          
             
              <a class="carousel-control-prev" href="#myCarousel1" role="button" data-slide="prev">
                <span class="carousel-control-prev-icon1" aria-hidden="true"></span>
                <span class="sr-only">Previous</span>
              </a>
              <a class="carousel-control-next" href="#myCarousel1" role="button" data-slide="next">
                <span class="carousel-control-next-icon1" aria-hidden="true"></span>
                <span class="sr-only">Next</span>
              </a>
            </div>
</div>

    </div>-->

<app-newsletter></app-newsletter>
<app-footer></app-footer>
</div>
</div>