import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-prob',
  templateUrl: './prob.component.html',
  styleUrls: ['./prob.component.scss']
})
export class ProbComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
