import { AfterViewInit, Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-careers',
  templateUrl: './careers.component.html',
  styleUrls: ['./careers.component.scss']
})
export class CareersComponent implements OnInit {

  constructor(){
  }
  count:number=0
  firststep=0
  count1:number=0
  firststep1=0
  count2:number=0
  firststep2=0

  load=true
  ngOnInit(){ 
    setTimeout(() => {
      this.load=false
    }, 10);
    
    setInterval(() => {
      if(window.screen.width<1000)
      {
        if(window.scrollY>=2080 && this.firststep==0)
        {
          this.firststep=1
        }
        if(window.scrollY>=2780 && this.firststep1==0)
        {
          this.firststep1=1
        }
        if(window.scrollY>=3080 && this.firststep2==0)
        {
          this.firststep2=1
        }
      }
      else{
        if(window.scrollY>=1600 && this.firststep==0)
        {
          this.firststep=1
        }
        if(window.scrollY>=1600 && this.firststep1==0)
        {
          this.firststep1=1
        }
        if(window.scrollY>=1600 && this.firststep2==0)
        {
          this.firststep2=1
        }
      }
    }, 250);
    setInterval(() => {
      if(this.firststep==1)
      {
      this.myCount(15);
      }
    }, 120);
    setInterval(() => {
      if(this.firststep1==1)
      {
      this.myCount1(5000);
      }
    }, 0.0001);
    setInterval(() => {
      if(this.firststep2==1)
      {
      this.myCount2(10);
      }
    }, 120);
  }
  
  myCount(n) {
    if (this.count >= n) {
          this.count = n;
    }
    else
    this.count++;
  }

  myCount1(n) {
    if (this.count1 >= n) {
          this.count1 = n;
    }
    else
    this.count1=this.count1+10;
  }
  myCount2(n) {
    if (this.count2 >= n) {
          this.count2 = n;
    }
    else
    this.count2++;
  }

  removeTags(str) {
    if ((str===null) || (str===''))
        return false;
    else
        str = str.toString();
          
    // Regular expression to identify HTML tags in
    // the input string. Replacing the identified
    // HTML tag with a null string.
    return str.replace( /(<([^>]+)>)/ig, '');
}
  
}
