


<section class="service-style-four pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-8">
                <div class="service-details-area">
                  
                    <div>
                        <div style="margin-top:4px; margin: 0px 8px 0px 8px; padding-left:14px; width:651px; padding-right: 5px;"><br>
    
                            <span class="slogon-bare subtitle2"> <strong>Duration : </strong></span>
                            <p style="padding-left:20px;margin-top:2px;font-family:Arial, Helvetica, sans-serif"><label>5 Days.</label></p>
                            <span class="slogon-bare subtitle2"> <strong>Description :  </strong></span>
                            <p style="padding-left:20px;margin-top:2px;">
                            <label class="titleinside">Exceliacom Solutions <strong>Certificate in Retail Market Supervision </strong>is an extensive five day program that covers the topics of market theory, regulation, and costing for the retail market. Indeed, to ensure global fairness among all telecommunications players, wholesale market regulation has to be jointly performed with a continuous supervision policy of the retail market.</label>
                            </p>
                           <p style="padding-left:20px;">
                         <label class="titleinside">
                            The main focus of this program is put on best supervision practices for the retail segment. The program is designed by regulatory experts and covers three main topics:   </label></p>
                         
                          
                                <ul style="padding-left:50px; font-family:Arial, Helvetica, sans-serif;list-style-type:square">
                               <li> <label><strong>Market Theory&nbsp;:&nbsp;</strong>concepts related to retail market supervision such as dumping and squeezing.</label> 
                               </li>
                               <li><label><strong>Regulation&nbsp;:&nbsp;</strong>regulatory frameworks adapted to the retail segment.</label>
                               </li>
                               <li> <label><strong>Costing&nbsp;:&nbsp;</strong>Analysis of offers submitted by operators and preparation for approval.</label> 
                               </li>
                             </ul>
                              <br>
                          <p style="padding-left:20px;">
                         <label class="titleinside">
                            The three topics are linked together through workshops to deepen the understanding of the concepts introduced during the different sessions, and allow the delagates apply them into practical case studies.</label>
                          </p>
                         
                             <span class="slogon-bare subtitle2"> <strong> Target Audience : </strong></span>
                           <ul style="padding-left:50px; font-family:Arial, Helvetica,sans-serif;list-style-type:square">
                               <li><label class="titleinside">
                            Regulatory and competition council professionals involved in the retail market segment.</label>
                               </li> <li><label class="titleinside">Business development consultants and managers working for operators and equipment suppliers.</label>
                               </li></ul>
                             <p style="padding-left:20px;margin-top:2px;"> </p>
                            <span class="slogon-bare subtitle2"> <strong>Medium of Teaching : </strong></span>
                            <p style="padding-left:20px;margin-top:2px;"> <label class="titleinside">
                             Our instructors have perfect command of both <strong>French</strong> and <strong>English</strong>.</label>
                             </p>
                           <p>&nbsp;</p></div>
                    </div>
                </div>
            </div>

            <div class="col-lg-4">
                
                <div class="service-category">
                    <h3>Training Solutions </h3>

                    <ul>
                        <li><a routerLink="/training">LTE Training <i class="icofont-arrow"></i></a></li>
                        <li><a routerLink="/trainingb">WiMAX Training <i class="icofont-arrow"></i></a></li>
                        <li><a routerLink="/trainingc">UMTS/HSPA Training <i class="icofont-arrow"></i></a></li>
                        <li><a routerLink="/trainingd">CDMA/EVDO Training <i class="icofont-arrow"></i></a></li>
                        <li><a routerLink="/traininge">Regulation Training <i class="icofont-arrow"></i></a></li>
                        <li><a routerLink="/trainingf">Certificate in Business Development for Mobile Broadband <i class="icofont-arrow"></i></a></li>
                        <li><a routerLink="/trainingg">Certificate in Retail Market Supervision <i class="icofont-arrow"></i></a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>