import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-trainingb',
  templateUrl: './trainingb.component.html',
  styleUrls: ['./trainingb.component.scss']
})
export class TrainingbComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
