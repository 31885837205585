import { Component, OnInit } from '@angular/core';
import { ServiceService } from '../service.service';

@Component({
  selector: 'app-newsroom',
  templateUrl: './newsroom.component.html',
  styleUrls: ['./newsroom.component.scss']
})
export class NewsroomComponent implements OnInit {

  
  constructor(private service: ServiceService) { }

  error=true
  news:any
  getNews()
  {
    this.error=true
    this.service.getNews().subscribe(
      data => {
        this.news=data
        this.error=false
        console.log(data)
      },
      error => {
        this.news=[]
        this.error=false
        console.log(error)
      }
    )
  }

  ngOnInit(): void {
    this.getNews()
  }

  removeTags(str) {
    if ((str===null) || (str===''))
        return false;
    else
        str = str.toString();
          
    // Regular expression to identify HTML tags in
    // the input string. Replacing the identified
    // HTML tag with a null string.
    return str.replace( /(<([^>]+)>)/ig, '');
}

}
