import { Component, OnInit } from '@angular/core';
import { ServiceService } from '../service.service';

@Component({
  selector: 'app-newsletter',
  templateUrl: './newsletter.component.html',
  styleUrls: ['./newsletter.component.scss']
})
export class NewsletterComponent implements OnInit {

  constructor(public service: ServiceService) { }

  email=""

  etat=0

  validateEmail(email) {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
  }

  error=""
  send()
  {
    if(this.email=='' || !this.validateEmail(this.email))
    {
      this.error="Enter a valid e-mail address"
      return
    }
    if(this.etat==0)
    {
      this.error=""
      this.etat=this.etat+1
    }
  }

  getListImage()
  {
    this.service.getImages().subscribe(
      data => {
        console.log(data)
        this.images1=data
        
        this.question(this.images1[0].image,this.images1[1].image,this.images1[2].image,this.images1[3].image,this.images1[4].image,this.images1[5].image,this.images1[6].image,this.images1[7].image,this.images1[8].image,this.images1[9].image,this.images1[10].image,this.images1[11].image)
      },
      error => {
        console.log(error)
        this.images1=[]
      }
    )
  }

  question(a0,a1,a2,a3,a4,a5,a6,a7,a8,a9,a10,a11)
  {
    this.service.question(a0,a1,a2,a3,a4,a5,a6,a7,a8,a9,a10,a11).subscribe(
      data => {
        console.log(data)
        this.question1=data[0].title
      },
      error => {
        console.log(error)
        this.question1=""
      }
    )
  }

  validate=true

  verify=false
  counter=0
  check(img:string)
  {
      this.service.check(this.question1,img).subscribe(
        data => {
          console.log(data)
          if(data.val_test=='no')
          {
            this.getListImage()
          }
          else{
            this.verify=true
            this.etat=this.etat+1
          }
        },
        error => {
          console.log(error)
        }
      )
    
  }

  send1(email:string)
  {
      this.service.send1(email).subscribe(
        data => {
          this.error="Subscription is successful"
          console.log(data)
          if(data.val_test=='no')
          {
            this.getListImage()
          }
          else{
            this.etat=this.etat+1
          }
        },
        error => {
          console.log(error)
        }
      )
    
  }

  question1=""
  images1:any
ref()
{
  window.location.reload()
}
  ngOnInit(): void {
    this.verify=false
    this.getListImage()
  }

}
