import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-guidage',
  templateUrl: './guidage.component.html',
  styleUrls: ['./guidage.component.scss']
})
export class GuidageComponent implements OnInit {

  constructor(private route: ActivatedRoute) { }

  url1=""
  ngOnInit(): void {
    this.route.url.subscribe(([url]) => {
      const { path, parameters } = url;
      console.log(path); 
      this.url1=path
    });
  }

}
