import { Component, OnInit } from '@angular/core';
import { ServiceService } from 'src/app/service.service';

@Component({
  selector: 'app-home-one',
  templateUrl: './home-one.component.html',
  styleUrls: ['./home-one.component.scss']
})
export class HomeOneComponent implements OnInit {

  constructor(private service: ServiceService) { }

  tiran:any
  ngOnInit() {
    this.service.getTiran()
    .subscribe(
      data => {
        console.log(data)
        this.tiran=data
      },
      error => {
        console.log(error)
      });
  }

}
