

<div class="service-title">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="title-text text-center">
                <h2>Service Details</h2>

                <ul>
                    <li><a routerLink="/">Home</a></li>
                    <li>Why Exceliacom</li>
                </ul>
            </div>
        </div>
    </div>
</div>

<div class="pricing-section pt-100 pb-70">
    <div class="container">
        <div class="section-title text-center">
            <h2>Why Exceliacom?</h2>
            <p>You may ask about the main reasons why to trust Exceliacom solutions with your needs! Bellow, you will find a sample list of facts:</p>
        </div>
        <div class="row">
            <div class="col-lg-4 col-sm-6">
                <div class="pricing-card">
                    <div class="price-header text-center">
                        <h2>Team</h2>
                        <i class="flaticon-startup"></i>
                    </div>

                    <div class="pricing-feature">
                        <ul>
                            <li><i class='bx bx-check'></i> Dedicated and highly experienced trainers and consultants with perfect command of both French and English.</li>
                            <li><i class='bx bx-check'></i> Entire coverage of major areas related to the Telecoms market: Technology, Regulation, Marketing, and Finance.</li>
                            <li><i class='bx bx-check'></i> Active members in international bodies such as ITU and IEEE.</li>
                            <li><i class='bx bx-check'></i> International technology patents and large publication record in archival journals and conferences.</li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="pricing-card price-card-two">
                    <div class="price-header text-center">
                        <h2>Solutions</h2>
                        <i class="flaticon-startup"></i>
                    </div>

                    <div class="pricing-feature">
                        <ul>
                            <li><i class='bx bx-check'></i> Cutting edge technology training for both business and technical professionals.</li>
                            <li><i class='bx bx-check'></i> Telecoms regulation training solutions covering up-to-date regulation problems.                            </li>
                            <li><i class='bx bx-check'></i> Professional and consultancy solutions for both Telecoms operators and regulation bodies.</li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6 offset-sm-3 offset-lg-0 ">
                <div class="pricing-card price-card-three">
                    <div class="price-header text-center">
                        <h2>Customers</h2>
                        <i class="flaticon-startup"></i>
                    </div>

                    <div class="pricing-feature">
                        <ul>
                            <li><i class='bx bx-check'></i> A large number of who's who of Telecoms operators including: MTN, Orange, Moov, MarocTelecom, MediTelecom, Wana.</li>
                            <li><i class='bx bx-check'></i> Major African Telecom regulation bodies: ANRT, ATCI, ARPTC, ARPCE, ICGB, ATRPT.</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>