import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ServiceService } from '../service.service';

@Component({
  selector: 'app-detaill-new',
  templateUrl: './detaill-new.component.html',
  styleUrls: ['./detaill-new.component.scss']
})
export class DetaillNewComponent implements OnInit {
 
  
  constructor(public service: ServiceService, private route: ActivatedRoute, private router: Router,private titleService: Title) { }

  error=true
  news:any
  index1:number
  getNews(id:number)
  {
    this.error=true
    this.service.getNews().subscribe(
      data => {
        this.news=data
        var count=0
        for(let a of this.news)
        {
          if(a.id==id)
          {
            this.index1=count
          }
          
          count=count+1
        }

        this.error=false
        console.log(this.index1)
        this.titleService.setTitle(this.removeTags(this.news[this.index1].title)+" — ∈-"+this.news[this.index1].type+" | Exceliacom")
      },
      error => {
        this.news=[]
        this.error=false
        console.log(error)
      }
    )
  }

  addOne()
  {
    this.index1=this.index1+1
    this.id=this.news[this.index1].id
    this.titleService.setTitle(this.removeTags(this.news[this.index1].title)+" — ∈-"+this.news[this.index1].type+" | Exceliacom")

  }
  supOne()
  {
    this.index1=this.index1-1
    this.id=this.news[this.index1].id
    this.titleService.setTitle(this.removeTags(this.news[this.index1].title)+" — ∈-"+this.news[this.index1].type+" | Exceliacom")

  }
newsFilter(a)
{
  return this.news.filter((val: any) => val.type == a && val.id != this.id)
}

  id:number
  load=true 
  
  ngOnInit(): void {
    setTimeout(() => {
      this.load=false
    }, 100);
    
    this.id=Number(this.route.snapshot.paramMap.get('id'))
    this.getNews(this.id)
  }

  removeTags(str) {
    if ((str===null) || (str===''))
        return false;
    else
        str = str.toString();
          
    // Regular expression to identify HTML tags in
    // the input string. Replacing the identified
    // HTML tag with a null string.
    return str.replace( /(<([^>]+)>)/ig, '');
}

changeId(id)
{
  this.error=true
  window.scroll(0, 0);
  var count=0
  for(let a of this.news)
        {
          if(a.id==id)
          {
            this.index1=count
            this.id=id
            this.titleService.setTitle(this.removeTags(this.news[this.index1].title)+" — ∈-"+this.news[this.index1].type+" | Exceliacom")

          }
          
          count=count+1
        }
        setTimeout(() => {
          this.error=false
      }, 2000)
        console.log(this.index1)
}

replacePbySpan(str)
{
  str=str.replaceAll('<p>','')
  str=str.replaceAll('</p>','')
  return str
}
}
