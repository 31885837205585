import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { ServiceService } from '../service.service';

@Component({
  selector: 'app-detaill',
  templateUrl: './detaill.component.html',
  styleUrls: ['./detaill.component.scss']
})
export class DetaillComponent implements OnInit {

  constructor(private service: ServiceService, private route: ActivatedRoute, private http : HttpClient,private titleService: Title) { }

  error=true
  getCarID(id:any)
  {
    this.error=true
    this.service.getCareersID(id).subscribe(
      data=> {
        this.error=false
        this.detailJob1=data[0]
        
    this.titleService.setTitle(this.removeTags(this.detailJob1.title)+" | Exceliacom")
      },
      error=> {
        this.error=false
      }
    )
  }

  apply=false
  enableApply()
  {
    this.apply=!this.apply
  }

  full_name=""
  phone=""
  email=""

  nfile2:Array<any>=[]

  fileEvent2(e:any){
    this.nfile2.push(e.target.files[0])
    console.log(this.nfile2)
   }
   /* Upload button functioanlity */
   loadfile=0
   errorM=""
   onSubmitform() {
    this.loadfile=1
     var myFormData = new FormData();
     const headers = new HttpHeaders();
     headers.append('Content-Type', 'multipart/form-data');
     headers.append('Accept', 'application/json');
     var c = 1
     for(let a of this.nfile2)
     {
      myFormData.append('img'+c,a);
      c+=1
     }
     console.log(this.id_job)
     myFormData.append('id', this.id_job);
     myFormData.append('email', this.email);
     myFormData.append('full_name', this.full_name);
     myFormData.append('phone', this.phone);
     console.log(this.id_job,this.email,this.full_name,this.phone)
     this.http.post(
  this.service.getPath1()+'/api/participation/apply', myFormData, {
     headers: headers
     }).subscribe(data => {
      console.log(data);
      this.errorM="Send Correct"
     },
     error => {
      console.log(error);
      
      this.errorM="Send Error"
      this.loadfile=-1
     });  
  
  }

  annee1=""

  id_job=""

  load=true 
  ngOnInit(): void {
    setTimeout(() => {
      this.load=false
    }, 100);
    let myDate: Date = new Date();
    this.annee1=myDate.getFullYear()+""
    this.id_job=this.route.snapshot.paramMap.get('id')
    this.getCarID(this.route.snapshot.paramMap.get('id'))

    this.getCareers()
    this.mois1=this.addZero(myDate.getMonth()+1)
    this.day1=this.addZero(myDate.getDate())
    this.today=this.annee1+'-'+this.mois1+'-'+this.day1
  }

  removeTags(str) {
    if ((str===null) || (str===''))
        return false;
    else
        str = str.toString();
          
    // Regular expression to identify HTML tags in
    // the input string. Replacing the identified
    // HTML tag with a null string.
    return str.replace( /(<([^>]+)>)/ig, '');
}


  detailJob1:any
  detailJob(a)
  {
    this.detailJob1=a
    this.id_job=a.id_car
    console.log(this.removeTags(this.detailJob1.title))
    this.titleService.setTitle(this.removeTags(this.detailJob1.title)+" | Exceliacom")

  }

  time=""
  category=""
  type=""
  location=""


  searched=false
  enablesearch()
  {
    this.searched=!this.searched
  }

  car:any
  getCareers()
  {
    this.error=true
    this.service.getCareers().subscribe(
      data => {
        this.car=data
        this.error=false
        console.log(data)
      },
      error => {
        this.car=[]
        this.error=false
        console.log(error)
      }
    )
  }

  addZero(n:any)
  {
    n=Number(n)
    if(n<10)
    {
      return '0'+n
    }
    else
    {
      return n
    }
  }

  generateDate(date:string)
  {
    let myDate: Date = new Date(date);
    return myDate.getFullYear()+'-'+this.addZero(myDate.getMonth()+1)+'-'+this.addZero(myDate.getDate())
  }

  mois1:number
  day1:number
  today=""
}
