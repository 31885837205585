import { Component, OnInit } from '@angular/core';
import { ServiceService } from '../service.service';

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.scss']
})
export class BlogComponent implements OnInit {

  a1:boolean=false
  a2:boolean=false
  a3:boolean=false
  a4:boolean=true
  search=""
    
    constructor(public service: ServiceService) { }
    searched=false
    enablesearch()
    {
      this.searched=!this.searched
    }
  
    error=true
    news:any
    getNews()
    {
      this.error=true
      this.service.getNews().subscribe(
        data => {
          this.news=data
          this.error=false
          console.log(data)
        },
        error => {
          this.news=[]
          this.error=false
          console.log(error)
        }
      )
    }
  
    load=true 
    ngOnInit(): void {
      this.loadf();
      setTimeout(() => {
        this.load=false
      }, 100);
      this.getNews()
    }
    removeTags(str) {
      if ((str===null) || (str===''))
          return false;
      else
          str = str.toString();
            
      // Regular expression to identify HTML tags in
      // the input string. Replacing the identified
      // HTML tag with a null string.
      return str.replace( /(<([^>]+)>)/ig, '');
  }

  loadmore=5
  public loadf() {
    this.loadmore=this.loadmore+3
  }
    
  clickMore1=false
  clickMore()
  {
    this.clickMore1=!this.clickMore1
  }
  }
  