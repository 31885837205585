import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeOneComponent } from './components/pages/home-one/home-one.component';
import { FooterComponent } from './components/layouts/footer/footer.component';
import { PreloaderComponent } from './components/layouts/preloader/preloader.component';
import { HomeTwoComponent } from './components/pages/home-two/home-two.component';
import { NavbarOneComponent } from './components/layouts/navbar-one/navbar-one.component';
import { NavbarTwoComponent } from './components/layouts/navbar-two/navbar-two.component';
import { AboutComponent } from './components/pages/about/about.component';
import { ServiceComponent } from './components/pages/service/service.component';
import { ServiceDetailsComponent } from './components/pages/service-details/service-details.component';
import { PortfolioComponent } from './components/pages/portfolio/portfolio.component';
import { PortfolioTwoComponent } from './components/pages/portfolio-two/portfolio-two.component';
import { PricingComponent } from './components/pages/pricing/pricing.component';
import { ErrorComponent } from './components/pages/error/error.component';
import { FaqComponent } from './components/pages/faq/faq.component';
import { TeamComponent } from './components/pages/team/team.component';
import { TeamTwoComponent } from './components/pages/team-two/team-two.component';
import { PrivacyComponent } from './components/pages/privacy/privacy.component';

import { ContactComponent } from './components/pages/contact/contact.component';

import {LocationStrategy, HashLocationStrategy} from '@angular/common';
import { TrainingbComponent } from './trainingb/trainingb.component';
import { TrainingcComponent } from './trainingc/trainingc.component';
import { TrainingdComponent } from './trainingd/trainingd.component';
import { TrainingeComponent } from './traininge/traininge.component';
import { TrainingfComponent } from './trainingf/trainingf.component';
import { TraininggComponent } from './trainingg/trainingg.component';
import { ProbComponent } from './prob/prob.component';
import { ProcComponent } from './proc/proc.component';
import { ProdComponent } from './prod/prod.component';
import { ProeComponent } from './proe/proe.component';
import { ProfComponent } from './prof/prof.component';
import { ProgComponent } from './prog/prog.component';
import { ProhComponent } from './proh/proh.component';
import { HttpClientModule } from '@angular/common/http';
import { About1Component } from './about1/about1.component';
import { CareersComponent } from './careers/careers.component';
import { EventsComponent } from './events/events.component';
import { FormsModule } from '@angular/forms';
import { JobsComponent } from './jobs/jobs.component';
import { NewsletterComponent } from './newsletter/newsletter.component';
import { DetaillComponent } from './detaill/detaill.component';
import { SearchPipe } from './search.pipe';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { NewsroomComponent } from './newsroom/newsroom.component';
import { NewsroomPipe } from './newsroom.pipe';
import { DetaillNewComponent } from './detaill-new/detaill-new.component';
import { SubmenuComponent } from './submenu/submenu.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { SearchComponent } from './search/search.component';
import { BlogComponent } from './blog/blog.component';
import { SearchloadPipe } from './searchload.pipe';
import { DetaillBlogComponent } from './detaill-blog/detaill-blog.component';
import { GuidageComponent } from './guidage/guidage.component';
@NgModule({
  declarations: [
    AppComponent,
    HomeOneComponent,
    FooterComponent,
    PreloaderComponent,
    HomeTwoComponent,
    NavbarOneComponent,
    NavbarTwoComponent,
    AboutComponent,
    ServiceComponent,
    ServiceDetailsComponent,
    PortfolioComponent,
    PortfolioTwoComponent,
    PricingComponent,
    ErrorComponent,
    FaqComponent,
    TeamComponent,
    TeamTwoComponent,
    PrivacyComponent,
    BlogComponent,
    ContactComponent,
    TrainingbComponent,
    TrainingcComponent,
    TrainingdComponent,
    TrainingeComponent,
    TrainingfComponent,
    TraininggComponent,
    ProbComponent,
    ProcComponent,
    ProdComponent,
    ProeComponent,
    ProfComponent,
    ProgComponent,
    ProhComponent,
    About1Component,
    CareersComponent,
    EventsComponent,
    JobsComponent,
    NewsletterComponent,
    DetaillComponent,
    SearchPipe,
    PrivacyPolicyComponent,
    NewsroomComponent,
    NewsroomPipe,
    DetaillNewComponent,
    SubmenuComponent,
    SearchComponent,
    SearchloadPipe,
    DetaillBlogComponent,
    GuidageComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    NgxPaginationModule,
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  providers: [
//    { provide: LocationStrategy, useClass: HashLocationStrategy },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }