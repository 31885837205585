<app-navbar-one></app-navbar-one>
<div *ngIf="load">  <div class="container-fluid">
      <div class="row justify-content-center">
          <div class="col-lg-12 m-3 lds-ripple"><div></div><div></div></div>
      </div>
  </div>
</div>
<div *ngIf="!load">

  <div class="eEYrZX fKVwoy gYvjdo">
    <div class="twJuE">
      <div>
        <div id="style-SOG5A" class="style-SOG5A">
          
          <div class="container">
            <div class="cjYGBp iTDWfP fEZJEa">
                <div class="row align-items-end">
                  <div class="col-lg-11">
                    <h2 class="fStkLY1 durGnI">
                      <img src="../../assets/img/logoblanc.png">-Blog<span class="colorblue">—&nbsp;&nbsp;Insights and up-to-date articles on technologies and innovations that empower the hyper-connected world, IoT, and renewable energies.</span>
                      
                    </h2>
                  </div>
                  <div class="col-lg-1 float-right">
                      <a class="more" (click)="clickMore()" *ngIf="clickMore1"><i class="fa-solid fa-caret-up"></i> More</a>
                      <a class="more" (click)="clickMore()" *ngIf="!clickMore1"><i class="fa-solid fa-caret-down"></i> More</a>
                  </div>
    
                </div>

                
        


            </div>
          </div>
        </div>
        <div id="style-Pxd86" class="style-Pxd86">
        </div>
        
      </div>
    </div>
  </div>
  
  <app-guidage *ngIf="!clickMore1"></app-guidage>
         <div class="padding1">
         
    
          <div class="sc-grxQYx iWEfMK">
          <div class="sc-grxQYx iDuKqi">
            
          <div class="NewsLandingSearchResultsComponent__NewsItemsContainer-sc-gxpu82-0 dpvSqo">
            <div class="elemRight" *ngIf="clickMore1">
              <div class="container">
                <div class="right">
                  <div class="css-1x2kh9a mt-3">
                      <div class="container">
                        <form>
                        <input type="search" [(ngModel)]="search" placeholder="Search Blog">
                        <button type="submit"><i class="fa-solid fa-magnifying-glass"></i></button>
                      </form>
                      </div>
                  </div>
                </div>
              </div> 
              <div class="ewzHNn">
              </div>  
          </div>
          <div class="css-b3pn3b" *ngIf="clickMore1">
             
              <div class="container2">
                    <input type="radio" checked>
                    <label for="tab1" > All Topics</label>
                    
                    <input type="radio">
                    <label for="tab1" >Broadband </label>
                    <br>
                    <input type="radio">
                    <label for="tab1" >IoT</label>
                    <br>
                    <input type="radio">
                    <label for="tab1" >Industrial </label>
                    <br>
                    <input type="radio">
                    <label for="tab1" >Renewables</label>
                    <br>
                    <input type="radio">
                    <label for="tab1" >Clean Tech</label>
              </div>
          </div>
         
         
         <div class="sc-bcXHqe cjYGBp sc-gWoSmj sc-hJVpLM fyKMRa dWSsmD snipcss0-7-11-12" *ngIf="(news | searchload:search:a1:a2:a3:a4:loadmore).length==0 && !error">List is empty</div>
 
         
 
         <div class="row">
           <div class="sc-grxQYx cFULeZ snipcss0-0-0-1 padding1" *ngFor="let a of news | searchload:search:a1:a2:a3:a4:loadmore" >
           <div class="gkduhq" *ngIf="a.type=='Blog'">
            <article class="emKZKu" routerLink="/e-blog-detail/{{a.id}}">
              <a class="cnhNwd">
                <div class="fPRcLx">
                  <div class="sc-bcXHqe fdUHvj snipcss0-5-5-6">
                    <p color="blue" data-test-id="news-item-label" class="sc-csuSiG dpkuuw snipcss0-6-6-7"><img src="../../assets/img/logoeblue.png">-{{a.type}}</p>
                    </div>
                    <span color="newsItemContentTextColor" data-test-id="news-item-date" class="sc-eDWCr hbeOZQ snipcss0-5-5-8">{{a.created_at | date :  "MMM dd, yyyy"}}</span>
           
                </div>
                <div class="cXLmts">
                  <img class="cqrtwT" [src]="this.service.getPathImage()+''+a.image">
                </div>
                <div class="fIlstS">
                </div>
                <div class="lahaJw">
                  <h2 class="ceEGPo bhvNhv">
                    <div class="clXQjQ">
                      <div class="eEYrZX isIbmM ldoCqD">
                        {{removeTags(a.title)}}
                      </div>
                    </div>
                  </h2>
                </div>
              </a>
            </article>
          </div>
           </div>
         </div>
         
     
          <div class="ewzHNn">
         </div>  
         
          </div>
          
          </div>
          </div>
          
          </div>
          <div class="row justify-content-center" *ngIf="error">
            <div class="lds-ripple"><div></div><div></div></div>
          </div>

                <div class="buttonLoad">
                    <button type="submit" (click)="loadf()" class="form-control css-28g3l0" height="40">
                        Read More
                    </button>
                </div>

        

        <app-newsletter></app-newsletter>
         <app-footer></app-footer>
         <div class="knotch_placeholder">
         </div>
         <div>
         </div>
         <div class="  aem-GridColumn aem-GridColumn--default--12">
         <div class="aem-container aem-Grid aem-Grid--12 aem-Grid--default--12">
         </div>
         <div>
         </div>
        </div>