

<footer class="footer-area">
    <div class="container">
            <div class="col-md-4 col-sm-6">
                <div class="footer-widget">
                    <div class="footer-logo">
                        <a routerLink="/"><img src="assets/img/logo-two.png" alt="logo"></a>
                    </div>
                    <div class="footer-social">
                        <a href="#" target="_blank"><i class="flaticon-facebook"></i></a>
                        <a href="#" target="_blank"><i class="flaticon-twitter"></i></a>
                        <a href="#" target="_blank"><i class="flaticon-instagram"></i></a>
                        <a href="#" target="_blank"><i class="flaticon-linkedin"></i></a>
                    </div>
                </div>
            </div>

            

            <div class="col-md-4 col-sm-6">
                <div class="footer-widget pl-75">
                    <h3>Quick Links</h3>

                    <ul>
                        <li><a routerLink="/"><i class="flaticon-right"></i> Home</a></li>
                        <li><a routerLink="/about" (click)="ref()"><i class="flaticon-right"></i> About Us</a></li>
                        <li><a routerLink="/contact" ><i class="flaticon-right"></i> Contact</a></li>
                    </ul>
                </div>
            </div>

            <div class="col-md-4 col-sm-6">
                <div class="footer-widget pl-75">
                    <h3>Find Us</h3>

                    <ul>
                        <li><i class="flaticon-right"></i> Av. Annakhil, Sect 9, Riad Soundous 2, No 18, Rabat, Morocco</li>
                        <li><a href="tel:+212 530 111 980"><i class="flaticon-right"></i>+212 530 111 980</a></li>
                        <li><a href="tel:+212 530 111 981"><i class="flaticon-right"></i>+212 530 111 981</a></li>
                        <li><i class="flaticon-right"></i>contact@exceliacom.com</li>
                        <li><i class="flaticon-right"></i>sales@exceliacom.com</li>
                    </ul>
                </div>
            </div>
    </div>

    <div class="copyright-area">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-md-9 lh-1 text-start">
                    <p>&copy;{{annee1}} Exceliacom. All Rights Reserved by  <a href="https://Exceliacom.com/" target="_blank">Exceliacom Engineering, SARL</a></p>
                </div>

                
            </div>
        </div>
    </div>
</footer>
