

<div class="aem-container  aem-Grid--default--12">
     <div class="aem-GridColumn aem-GridColumn--default--12">
     <div class="aem-container aem-Grid aem-Grid--12 aem-Grid--default--12">
     </div>
     <div>
     </div>
     </div>
     <div class="  aem-GridColumn aem-GridColumn--default--12">
     <div id="news-landing" class="sc-iJbNxu fhtQBX">
     <div class="sc-iJbNxu fiLYYP">
     <div class="sc-bcXHqe jXTyOx" tag="div">
     

     <div class="sc-grxQYx iWEfMK">
     <div class="sc-grxQYx iDuKqi">
     <div class="NewsLandingSearchResultsComponent__NewsItemsContainer-sc-gxpu82-0 dpvSqo">

        <div class="gtmCbe">
            <div class="hvApLJ">
              <div class="ewzHNn">
                <form class="iUnCYe">
                  <div class="etAYBx">
                    <svg viewBox="-2.5 -2.5 24 24" xmlns="http://www.w3.org/2000/svg" cursor="pointer" fill="black" height="24" width="24">
                      <defs>
                        <path d="M13.4167979,16.00015 C10.3787979,16.00015 7.91679789,13.53815 7.91679789,10.50015 C7.91679789,7.46215 10.3787979,5.00015 13.4167979,5.00015 C16.4537979,5.00015 18.9167979,7.46215 18.9167979,10.50015 C18.9167979,13.53815 16.4537979,16.00015 13.4167979,16.00015 M8.92069197,16.4935216 L4.56066017,20.8535534 C4.36539803,21.0488155 4.04881554,21.0488155 3.85355339,20.8535534 L3.14644661,20.1464466 C2.95118446,19.9511845 2.95118446,19.634602 3.14644661,19.4393398 L7.49369325,15.0920932 C6.50732577,13.8214322 5.91679789,12.2295447 5.91679789,10.50015 C5.91679789,6.36415 9.28079789,3.00015 13.4167979,3.00015 C17.5517979,3.00015 20.9167979,6.36415 20.9167979,10.50015 C20.9167979,14.63515 17.5517979,18.00015 13.4167979,18.00015 C11.7296036,18.00015 10.1746074,17.4367127 8.92069275,16.4935208 Z" fill="currentColor" id="path-1-search">
                        </path>
                      </defs>
                      <g>
                        <g>
                          <g>
                            <g>
                              <use xlink:href="#path-1-search">
                              </use>
                            </g>
                          </g>
                        </g>
                      </g>
                    </svg>
                  </div>
                  <input placeholder="Search All News &amp; Media" type="text" class="ImjkC cQjhQP" value="">
                </form>
              </div>
            </div>
          </div>
          
     <div class="sc-grxQYx cFULeZ snipcss0-0-0-1" *ngFor="let a of news">
     <div data-index="0" class="sc-iJbNxu cvSuHF snipcss0-1-1-2">
     <article data-test-id="news-item-link" location="[object Object]" [ngClass]="{'sc-eDWCr jRWwuk snipcss0-2-2-3': a.type === 'Press Notes', 'sc-eDWCr jRWwuk1 snipcss0-2-2-3' : a.type === 'Press Release', 'sc-eDWCr jRWwuk2 snipcss0-2-2-3' : a.type === 'In the Media'  }">
     <a class="sc-cHGmPC jFPjeA sc-dkrFOg kEzltY snipcss0-3-3-4" data-test-id="news-item-link" >
     <div tag="header" class="sc-dkrFOg PctUQ snipcss0-4-4-5 tether-target-attached-top tether-abutted tether-abutted-top tether-element-attached-top tether-element-attached-center tether-target-attached-center">
     <div class="sc-bcXHqe fdUHvj snipcss0-5-5-6">
     <p color="blue" data-test-id="news-item-label" class="sc-csuSiG dpkuuw snipcss0-6-6-7">{{a.type}}</p>
     </div>
     <span color="newsItemContentTextColor" data-test-id="news-item-date" class="sc-eDWCr hbeOZQ snipcss0-5-5-8">{{a.date}}</span>
     </div>
     <div class="sc-bcXHqe fOTKLw snipcss0-4-4-9 tether-target-attached-top tether-abutted tether-abutted-top tether-element-attached-top tether-element-attached-center tether-target-attached-center">
     <h2 class="sc-iBYQkv fStkLY sc-kvhKsl hLlol snipcss0-5-9-10" data-test-id="news-item-title">
     <div color="white" class="sc-eDWCr fUkExK snipcss0-6-10-11">
     <div class="sc-bcXHqe cjYGBp sc-gWoSmj sc-hJVpLM fyKMRa dWSsmD snipcss0-7-11-12">{{removeTags(a.title)}}</div>

     </div>
     </h2>
     <div class="sc-jxfubC dNaUyi snipcss0-5-9-24">
     </div>
     </div>
     </a>
     </article>
     </div>
     </div>


     </div>
     </div>
     </div>
     </div>
     </div>
     </div>
     <div class="knotch_placeholder">
     </div>
     <div>
     </div>
     </div>
     <div class="  aem-GridColumn aem-GridColumn--default--12">
     <div class="aem-container aem-Grid aem-Grid--12 aem-Grid--default--12">
     </div>
     <div>
     </div>
     </div>
     </div>

  
     <app-footer></app-footer>