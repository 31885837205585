<app-navbar-one>

</app-navbar-one>
<div *ngIf="load">
    <div class="container-fluid">
        <div class="row justify-content-center">
            <div class="col-lg-12 m-3 lds-ripple">
                <div></div>
                <div></div>
            </div>
        </div>
    </div>
</div>
<div *ngIf="!load">


    <div class="container2">
        <div class="container">
            <input type="radio">
            <label for="tab1" routerLink="/about-us"> About Us</label>
            <input type="radio" checked>
            <label for="tab2" routerLink="/careers"> Careers</label>
            <input type="radio">
            <label for="tab3" class="mediawidth" routerLink="/news-media"> News & Media</label>
        </div>

        <section class="portfolio-section1 portfolio-style-two">
            <div class=" mix online-business">
                <div class="portfolio-item">
                    <div class="portfolio-img">
                        <img src="../../assets/img/careers.jpg" class="imgCareers" height="100" alt="portfolio image">
                        <div class="portfolio-text2">
                            <h3>Your next career shift <br>starts here >>></h3>


                        </div>
                    </div>
                </div>
            </div>
        </section>
        <app-guidage></app-guidage>
        <section class="about-section pb-100">
            <div class="container">

                <div class="section-title text-left">

                    <div class="new5"></div>
                </div>
                <div class="row align-items-left">

                    <div class="col-lg-12">
                        <div class="about-text">
                            <h4 class="h4-title">At Exceliacom, your next career boost starts now</h4>
                            <h4 class="h4-desc">For more than ten years, Exceliacom has been deploying cutting edge technology and services. This has been made possible by a dedicated team of multidisciplinary people with diverse cultures and backgrounds all gathered around
                                one purpose : make the best technology experience to our customers.</h4>

                            <h4 class="h4-desc">Do you want to create a great impact through advanced technologies?</h4>
                            <div class="theme-button text-left" routerLink="/jobs"><button class="mbtn blue">Search openings</button></div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <app-footer></app-footer>

    </div>
</div>