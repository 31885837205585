<app-navbar-one></app-navbar-one>
<div *ngIf="load">    <div class="container-fluid">
        <div class="row justify-content-center">
            <div class="col-lg-12 m-3 lds-ripple"><div></div><div></div></div>
        </div>
    </div>
</div>
<div *ngIf="!load">
<!--<div class="container2">
    <label for="tab0" class="label1 padtabs"> </label>
    <input type="radio">
    <input type="radio">
    <label for="tab1" routerLink="/about"> About Us</label>
    <input type="radio" routerLink="/careers" checked>
    <label for="tab2" > Careers</label>
    <input type="radio">
    <label for="tab3" routerLink="/events"> Events</label>
</div>-->

<div id="mainContent" class="css-1vosg8">
    <div class="css-b4ple3">
        
        
            <div class="css-1wnbqgd">
                <div class="container padding1">
                    <div class="css-b3pn3b">
                        <div class="css-b3pn3b">
                            <div class="css-1ncquf9">
                                <button type="submit" (click)="enablesearch()" *ngIf="!searched" class="form-control css-28g3l0" height="40">
                                    Refine Your Search
                                </button>
                                <button type="submit" (click)="enablesearch()" *ngIf="searched" class="form-control clicked" height="40">
                                    Close Your Search
                                </button>
                            </div>
                        </div>
                    </div> 
                    <div class=" row css-1dw6sgr" *ngIf="searched">
                        <div class="css-1x2kh9a col-lg-2 m-3">
                            <select class="form-select css-19qf6mn" [(ngModel)]="location">
                                <option value="">Location</option>
                                <option>Rabat</option>
                                <option>Casablanca</option>
                                <option>Marrakech</option>
                                <option>Abidjan</option>
                                <option>Conakry</option>
                                <option>Dakar</option>
                                <option>Bamako</option>
                                
                            </select>
                        </div>
                        <div class="css-1x2kh9a col-lg-2 m-3">
                            <select class="form-select css-19qf6mn" [(ngModel)]="time">
                                <option value="">Full/Part-time</option>
                                <option>Full time</option>
                                <option>Part time</option>
                            </select>
                        </div>
                        <div class="css-1x2kh9a col-lg-2 m-3">
                            <select class="form-select css-19qf6mn" [(ngModel)]="category">
                                <option value="">Job Category</option>
                                <option>Engineering Group</option>
                                <option>Interns Group</option>
                                <option>Information Technology Group</option>
                                <option>Engineering Services Group</option>
                                <option>Operations Group</option>
                                <option>Sales, Business Development & Marketing Group</option>
                                <option>Finance & Accounting Group</option>
                                <option>Human Resources Group</option>
                                <option>Manufacturing Technicians Group</option>
                                <option>Service & Support Group</option>
                                <option>Manufacturing Operations Group</option>
                                <option>Administration Group</option>
                                <option>Legal Group</option>
                                <option>Miscellaneous Group</option>
                            </select>
                        </div>
                        <div class="css-1x2kh9a col-lg-2 m-3">
                            <select class="form-select css-19qf6mn" [(ngModel)]="type">
                                <option value="">Job Type</option>
                                <option>Regular</option>
                                <option>Regular Contract (Fixed Term)</option>
                                <option>Intern (Fixed Term)</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        <div class="container">
            <div class="css-uvpbop">
                    <div class="col-lg-8 m-3">
                        <span class="css-ttaaxj">Search for Jobs page is loaded</span>
                        <p class="css-12psxof" *ngIf="car">
                            {{(car | search: location:time:category:type).length}} JOBS FOUND
                        </p>
                        <div class="css-14l0ax5">
                            <button class="css-1lg3cw5">
                                <span class="css-pplshs">Jump to selected job details</span>
                            </button>
                        </div>
                        <div *ngIf="error">
                            <div class="container-fluid">
                                <div class="row justify-content-center">
                                    <div class="col-lg-12 m-3 lds-ripple"><div></div><div></div></div>
                                </div>
                            </div>
                        </div>

                        <ul>

                            <li class="css-1q2dra3" *ngFor="let a of car | search: location:time:category:type|paginate: { itemsPerPage: nElement, currentPage: p }">
                                <div class="css-qiqmbt" routerLink="/job-detail/{{a.id_car}}">
                                    <div class="css-b3pn3b">
                                        <div class="css-b3pn3b">
                                            <h3>
                                                <a class="css-19uc56f">{{a.title}}</a>
                                            </h3>
                                        </div>
                                    </div>
                                </div>
                                <div class="css-248241" routerLink="/job-detail/{{a.id_car}}">
                                    <div class="css-1y87fhn">
                                        <div class="css-k008qs">
                                            <div class="css-kij4qr">
                                                <span class="css-wwkk48"><i class="flaticon-maps-and-flags"></i></span>
                                            </div>
                                            <dl>
                                                <dt class="css-y8qsrx">
                                                    locations
                                                </dt>
                                                <dd class="css-129m7dg">
                                                    {{a.location}}
                                                </dd>
                                            </dl>
                                        </div>
                                    </div>
                                </div>
                                <div class="css-zoser8" routerLink="/job-detail/{{a.id_car}}">
                                    <div class="css-1y87fhn">
                                        <div class="css-k008qs">
                                            <div class="css-kij4qr">
                                                <span class="css-wwkk48"><img src="../../assets/img/time.png" width="15" height="15"></span>
                                            </div>
                                            <dl>
                                                <dt class="css-y8qsrx">
                                                    posted on
                                                </dt>
                                                <dd class="css-129m7dg">
                                                    <span *ngIf="today==generateDate(a.created_at)">Posted today</span>
                                                    <span *ngIf="today!=generateDate(a.created_at)">{{generateDate(a.created_at)}}</span>
                                                </dd>
                                            </dl>
                                        </div>
                                    </div>
                                </div>
                                <ul class="css-14a0imc" routerLink="/job-detail/{{a.id_car}}">
                                    <li class="css-h2nt8k">
                                        {{a.id_car}}
                                    </li>
                                </ul>
                            </li>
                            <div class="ngx-pagination1">
                                <pagination-controls (pageChange)="p = $event"></pagination-controls>
                            </div>
        
                        </ul>
                        
                    </div>
                    <div class="css-1s8xnc9">
                        <div class="col-lg-3 m-3">
                            <div class="css-9bbna8">
                                <div class="css-1gw0f0n">
                                    <div class="css-6qub7p">
                                        <h3 class="css-1uppmgb">
                                            About Us
                                        </h3>
                                        <img src="../../assets/img/logo.png" alt="Logo" class="css-1mh151z" />
                                        <div dir="ltr">
                                            <div class="css-vr2o4s">
                                                <div class="css-cx1qpg">
                                                    <div class="css-8idjik">
                                                        <span>Our team is a group of dedicated managers, administrators, engineers, and technicians. We are bringing diverse backgrounds together to build innovative solutions and make every day a better experience for our customers.</span>
                                                        <p class="privacy">
                                                            <span class="css-1uppmgb">Check our privacy policy</span><br>
                                                            <a routerLink="/privacy-policy" class="css-ldauis">Exceliacom Privacy Policy</a>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            </div>
            
        </div>
        
        </div>
        
</div>
<app-footer></app-footer>
</div>