import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-proc',
  templateUrl: './proc.component.html',
  styleUrls: ['./proc.component.scss']
})
export class ProcComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
