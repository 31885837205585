<div class="navbar-area">
    <div class="mobile-nav">
        <a routerLink="/" class="logo">
            <img src="assets/img/logo.png" alt="logo">
        </a>
    </div>

    <div class="main-nav">
        <div class="container">
            <nav class="navbar navbar-expand-md navbar-light">
                <a class="navbar-brand" routerLink="/">
                    <img width="150" src="assets/img/logo.png" alt="logo">
                </a>

                <div class="collapse navbar-collapse mean-menu" id="navbarSupportedContent">
                    <ul class="navbar-nav menu1">
                        <li class="nav-item">
                            <a href="javascript:void(0)" [class]="this.href==='/aboutus'||this.href==='/news-media'||this.href==='/careers'?'nav-link dropdown-toggle':'nav-link dropdown-toggle'">Company </a>

                            <ul class="dropdown-menu">
                                <li class="nav-item desktop">
                                    <div class="elementor-widget-wrap elementor-element-populated">
                                        <div class="elementor-element elementor-element-60b2932 elementor-position-left elementor-vertical-align-top elementor-widget elementor-widget-icon-box" data-element_type="widget">
                                            <div class="elementor-widget-container">
                                                <div class="row">
                                                    <div class="col-lg-4">
                                                        <div class="elementor-icon-box-wrapper">
                                                            <div class="elementor-icon-box-icon">
                                                                <a class="elementor-icon" (click)="redirectTo('/about-us')">
                                                                    <span style="margin-right: 8px; height: 30px;"><i class="fa-solid fa-people-group"></i></span>
                                                                </a>
                                                            </div>
                                                            <div class="elementor-icon-box-content13">
                                                                <div class="elementor-icon-box-title">
                                                                    <a (click)="redirectTo('/about-us')">
                                                                        About Us				
                                                                    </a>
                                                                </div>
                                                                <p class="elementor-icon-box-description">
                                                                    Exceliacom simplifies technology, it delivers you the software, the hardware and the tools you need to ensure efficient usage of either your communications service, IoT, or renewable energy infrastructure.
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-4">
                                                        <div class="elementor-icon-box-wrapper">
                                                            <div class="elementor-icon-box-icon">
                                                                <a class="elementor-icon" (click)="redirectTo('/careers')" routerLinkActive="active" [routerLinkActiveOptions]="{exact: false}">
                                                                    <span><i class="fa-solid fa-suitcase"></i></span>
                                                                </a>
                                                            </div>
                                                            <div class="elementor-icon-box-content">
                                                                <div class="elementor-icon-box-title">
                                                                    <a (click)="redirectTo('/careers')">
                                                                        Careers				
                                                                    </a>
                                                                </div>
                                                                <p class="elementor-icon-box-description">
                                                                    At Exceliacom, your next career boost starts now. Do you want to create a great impact through advanced technologies? Search our openings and apply online.
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-4">
                                                        <div class="elementor-icon-box-wrapper">
                                                            <div class="elementor-icon-box-icon">
                                                                <a class="elementor-icon" (click)="redirectTo('/news-media')">
                                                                    <span><i class="fa-regular fa-newspaper"></i></span>
                                                                </a>
                                                            </div>
                                                            <div class="elementor-icon-box-content13">
                                                                <div class="elementor-icon-box-title">
                                                                    <a (click)="redirectTo('/news-media')">
                                                                        News & Media				
                                                                    </a>
                                                                </div>
                                                                <p class="elementor-icon-box-description">
                                                                    Stay up to date with Exceliacom press releases, press notes, and news. Check our news and products updates. Also, subscribe to our newsletter and keep tuned.
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-4">
                                                        <div class="elementor-icon-box-wrapper">
                                                            <div class="elementor-icon-box-icon">
                                                                <a class="elementor-icon" (click)="redirectTo('/news-media')">
                                                                    <span><i class="fa-regular fa-calendar-days"></i></span>
                                                                </a>
                                                            </div>
                                                            <div class="elementor-icon-box-content13">
                                                                <div class="elementor-icon-box-title">
                                                                    <a (click)="redirectTo('/news-media')">
                                                                        Events				
                                                                    </a>
                                                                </div>
                                                                <p class="elementor-icon-box-description">
                                                                    Exceliacom regularly attends conferences and major events around the world. Check our events calendar to see when to meet us next.
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-4">
                                                        <div class="elementor-icon-box-wrapper">
                                                            <div class="elementor-icon-box-icon">
                                                                <a class="elementor-icon" (click)="redirectTo('/news-media')">
                                                                    <span><i class="fa-regular fa-address-card" style="color: #000000;"></i></span>
                                                                </a>
                                                            </div>
                                                            <div class="elementor-icon-box-content13">
                                                                <div class="elementor-icon-box-title">
                                                                    <a (click)="redirectTo('/news-media')">
                                                                        Contact Information				
                                                                    </a>
                                                                </div>
                                                                <p class="elementor-icon-box-description">
                                                                    Find contact information of our offices, sales, after sales, and technical support.
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li class="nav-item mobile"><a href="/about-us" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">About Us</a></li>
                                <li class="nav-item mobile"><a href="/careers" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Careers</a></li>
                                <li class="nav-item mobile"><a href="/news-media" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">News & Media</a></li>
                                <li class="nav-item mobile"><a href="/news-media" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Events</a></li>
                                <li class="nav-item mobile"><a href="/news-media" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Contact Information</a></li>

                            </ul>
                        </li>

                        <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link dropdown-toggle">Products </a>

                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/about1" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Company Profile</a></li>

                                <li class="nav-item"><a routerLink="/about2" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Why ExceliCom?</a></li>
                            </ul>
                        </li>

                        <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link dropdown-toggle">Solutions </a>

                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/training" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">LTE Training</a></li>

                                <li class="nav-item"><a routerLink="/trainingb" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">WiMAX Training</a></li>

                                <li class="nav-item"><a routerLink="/trainingc" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">UMTS/HSPA Training</a></li>

                                <li class="nav-item"><a routerLink="/trainingd" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">CDMA/EVDO Training</a></li>

                                <li class="nav-item"><a routerLink="/traininge" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Regulation Training</a></li>

                                <li class="nav-item"><a routerLink="/trainingf" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Certificate in Business Development for Mobile Broadband</a></li>

                                <li class="nav-item"><a routerLink="/trainingg" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Certificate in Retail Market Supervision</a></li>
                            </ul>
                        </li>
                        <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link dropdown-toggle">Industries </a>

                            <ul class="dropdown-menu">
                                <li class="nav-item desktop">
                                    <div class="elementor-widget-wrap elementor-element-populated">
                                        <div class="elementor-element elementor-element-60b2932 elementor-position-left elementor-vertical-align-top elementor-widget elementor-widget-icon-box" data-element_type="widget">
                                            <div class="elementor-widget-container">
                                                <div class="row">
                                                    <div class="col-lg-4">
                                                        <div class="elementor-icon-box-wrapper">
                                                            <div class="elementor-icon-box-icon">
                                                                <a class="elementor-icon" (click)="redirectTo('/about-us')">
                                                                    <span style="margin-right: 8px; height: 30px;"><i class="fa-solid fa-tower-cell"></i></span>
                                                                </a>
                                                            </div>
                                                            <div class="elementor-icon-box-content13">
                                                                <div class="elementor-icon-box-title">
                                                                    <a (click)="redirectTo('/about-us')">
                                                                        Telecommunications				
                                                                    </a>
                                                                </div>
                                                                <p class="elementor-icon-box-description">
                                                                    Fixed and wireless broadband infrastructure to empower a hyper-connected world 
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    
                                                    <div class="col-lg-4">
                                                        <div class="elementor-icon-box-wrapper">
                                                            <div class="elementor-icon-box-icon">
                                                                <a class="elementor-icon" (click)="redirectTo('/about-us')">
                                                                    <span style="margin-right: 8px; height: 30px;"><i class="fa-solid fa-satellite-dish"></i></span>
                                                                </a>
                                                            </div>
                                                            <div class="elementor-icon-box-content13">
                                                                <div class="elementor-icon-box-title">
                                                                    <a (click)="redirectTo('/about-us')">
                                                                        Broadcast				
                                                                    </a>
                                                                </div>
                                                                <p class="elementor-icon-box-description">
                                                                    Cutting edge transmission and IP technologies and software solutions for a new broadcast era     
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    
                                                    <div class="col-lg-4">
                                                        <div class="elementor-icon-box-wrapper">
                                                            <div class="elementor-icon-box-icon">
                                                                <a class="elementor-icon" (click)="redirectTo('/about-us')">
                                                                    <span style="margin-right: 8px; height: 30px;"><i class="fa-solid fa-city"></i></span>
                                                                </a>
                                                            </div>
                                                            <div class="elementor-icon-box-content13">
                                                                <div class="elementor-icon-box-title">
                                                                    <a (click)="redirectTo('/about-us')">
                                                                        Smart Cities				
                                                                    </a>
                                                                </div>
                                                                <p class="elementor-icon-box-description">
                                                                    Enable connected devices and ICT infrastructure to reduce emissions and transform cities and villages into smarter places    
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    
                                                    <div class="col-lg-4">
                                                        <div class="elementor-icon-box-wrapper">
                                                            <div class="elementor-icon-box-icon">
                                                                <a class="elementor-icon" (click)="redirectTo('/about-us')">
                                                                    <span style="margin-right: 8px; height: 30px;"><i class="fa-solid fa-solar-panel"></i></span>
                                                                </a>
                                                            </div>
                                                            <div class="elementor-icon-box-content13">
                                                                <div class="elementor-icon-box-title">
                                                                    <a (click)="redirectTo('/about-us')">
                                                                        Green Energy 				
                                                                    </a>
                                                                </div>
                                                                <p class="elementor-icon-box-description">
                                                                    Smart solar power solutions and IoT for improved power efficiency and reduced carbon emissions     
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    
                                                    <div class="col-lg-4">
                                                        <div class="elementor-icon-box-wrapper">
                                                            <div class="elementor-icon-box-icon">
                                                                <a class="elementor-icon" (click)="redirectTo('/about-us')">
                                                                    <span style="margin-right: 8px; height: 30px;"><img src="../../../../assets/img/icn10.svg"></span>
                                                                </a>
                                                            </div>
                                                            <div class="elementor-icon-box-content13">
                                                                <div class="elementor-icon-box-title">
                                                                    <a (click)="redirectTo('/about-us')">
                                                                        Smart Grids				
                                                                    </a>
                                                                </div>
                                                                <p class="elementor-icon-box-description">
                                                                    IoT and smart connected devices for power utilities and grids      
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    
                                                    <div class="col-lg-4">
                                                        <div class="elementor-icon-box-wrapper">
                                                            <div class="elementor-icon-box-icon">
                                                                <a class="elementor-icon" (click)="redirectTo('/about-us')">
                                                                    <span style="margin-right: 8px; height: 30px;"><i class="fa-regular fa-hospital"></i></span>
                                                                </a>
                                                            </div>
                                                            <div class="elementor-icon-box-content13">
                                                                <div class="elementor-icon-box-title">
                                                                    <a (click)="redirectTo('/about-us')">
                                                                        Healthcare				
                                                                    </a>
                                                                </div>
                                                                <p class="elementor-icon-box-description">
                                                                    Conneted smart healthcare devices and solutions for remote monitoring of patients and inclusive digital healthcare     
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    
                                                    <div class="col-lg-4">
                                                        <div class="elementor-icon-box-wrapper">
                                                            <div class="elementor-icon-box-icon">
                                                                <a class="elementor-icon" (click)="redirectTo('/about-us')">
                                                                    <span style="margin-right: 8px; height: 30px;"><i class="fa-solid fa-vault"></i></span>
                                                                </a>
                                                            </div>
                                                            <div class="elementor-icon-box-content13">
                                                                <div class="elementor-icon-box-title">
                                                                    <a (click)="redirectTo('/about-us')">
                                                                        Digital Security				
                                                                    </a>
                                                                </div>
                                                                <p class="elementor-icon-box-description">
                                                                    Digital security solutions using adavnced IoT hardware and digital platforms for security insurance    
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li class="nav-item mobile"><a href="/about-us" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Telecommunications</a></li>
                                <li class="nav-item mobile"><a href="/careers" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Broadcast</a></li>
                                <li class="nav-item mobile"><a href="/news-media" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Smart Cities</a></li>
                                <li class="nav-item mobile"><a href="/about-us" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Green Energy</a></li>
                                <li class="nav-item mobile"><a href="/careers" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Smart Grids</a></li>
                                <li class="nav-item mobile"><a href="/news-media" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Healthcare</a></li>
                                <li class="nav-item mobile"><a href="/about-us" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Digital Security</a></li>

                            </ul>
                        </li>

                        <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link dropdown-toggle">Ecosystem </a>

                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/pro" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">New Product Introduction</a></li>

                                <li class="nav-item"><a routerLink="/prob" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Network Design and Optimization</a></li>

                                <li class="nav-item"><a routerLink="/proc" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Financial and Economical Modeling</a></li>

                                <li class="nav-item"><a routerLink="/prod" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Telecom Operator Regulatory Auditing</a></li>

                                <li class="nav-item"><a routerLink="/proe" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Revenue Assurance Monitoring</a></li>

                                <li class="nav-item"><a routerLink="/prof" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Interconnect Fraud Monitoring</a></li>

                                <li class="nav-item"><a routerLink="/prog" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Operator Dispute Resolution</a></li>

                                <li class="nav-item"><a routerLink="/proh" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Market Survey</a></li>
                            </ul>
                        </li>

                        <li class="nav-item">
                            <a href="javascript:void(0)" [class]="'nav-link dropdown-toggle'">Resources<i class="fa-solid fa-screen-users"></i> </a>

                            <ul class="dropdown-menu">
                                <li class="nav-item desktop">
                                    <div class="elementor-widget-wraplast elementor-element-populated">
                                        <div class="elementor-element elementor-element-60b2932 elementor-position-left elementor-vertical-align-top elementor-widget elementor-widget-icon-box" data-element_type="widget">
                                            <div class="elementor-widget-container">
                                                <div class="row">


                                                    <div class="col-lg-12">
                                                        <div class="elementor-icon-box-wrapper">
                                                            <div class="elementor-icon-box-icon">
                                                                <a class="elementor-icon" (click)="redirectTo('/e-Blog')">
                                                                    <span style="margin-right: 8px; height: 30px;"><img src="../../../../assets/img/icons/screen-users.png"></span>
                                                                </a>
                                                            </div>
                                                            <div class="elementor-icon-box-content13">
                                                                <div class="elementor-icon-box-title">
                                                                    <a (click)="redirectTo('/e-Blog')">
                                                                        Trainings				
                                                                    </a>
                                                                </div>
                                                                <p class="elementor-icon-box-description">
                                                                    Enroll into the most recent technology training portfolio to empower your skills
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>


                                                    <div class="col-lg-12">
                                                        <div class="elementor-icon-box-wrapper">
                                                            <div class="elementor-icon-box-icon">
                                                                <a class="elementor-icon" (click)="redirectTo('/e-Blog')">
                                                                    <span style="margin-right: 8px; height: 30px;"><i class="fa-sharp fa-solid fa-blog"></i></span>
                                                                </a>
                                                            </div>
                                                            <div class="elementor-icon-box-content13">
                                                                <div class="elementor-icon-box-title">
                                                                    <a (click)="redirectTo('/e-Blog')">
                                                                        <img class="ebloggris iconimgblogdesktop" src="../../../../assets/img/eblogblack.png">-Blog
                                                                    </a>
                                                                </div>
                                                                <p class="elementor-icon-box-description">
                                                                    Insights and up-to-date articles on technologies and innovations that empower the hyper-connected world, IoT, and green energies
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="col-lg-12">
                                                        <div class="elementor-icon-box-wrapper">
                                                            <div class="elementor-icon-box-icon">
                                                                <a class="elementor-icon" (click)="redirectTo('/e-Blog')">
                                                                    <span style="margin-right: 8px; height: 30px;"><img src="../../../../assets/img/icons/memo-pad.png"></span>
                                                                </a>
                                                            </div>
                                                            <div class="elementor-icon-box-content13">
                                                                <div class="elementor-icon-box-title">
                                                                    <a (click)="redirectTo('/e-Blog')">
                                                                        Whitepapers			
                                                                    </a>
                                                                </div>
                                                                <p class="elementor-icon-box-description">
                                                                    Read latest papers on technology analysis for IoT, ICT and green energies
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>


                                                    <div class="col-lg-12">
                                                        <div class="elementor-icon-box-wrapper">
                                                            <div class="elementor-icon-box-icon">
                                                                <a class="elementor-icon" (click)="redirectTo('/e-Blog')">
                                                                    <span style="margin-right: 8px; height: 30px;"><i class="fa-solid fa-photo-film"></i></span>
                                                                </a>
                                                            </div>
                                                            <div class="elementor-icon-box-content13">
                                                                <div class="elementor-icon-box-title">
                                                                    <a (click)="redirectTo('/e-Blog')">
                                                                        Videos				
                                                                    </a>
                                                                </div>
                                                                <p class="elementor-icon-box-description">
                                                                    Watch videos from Exceliacom
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li class="nav-item mobile"><a class="nav-link" href="/e-Blog">Trainings</a></li>
                                <li class="nav-item mobile">
                                    <a class="nav-link" href="/e-Blog"><img class="img2" src="../../../../assets/img/ebloggris.png">-Blog</a>
                                </li>
                                <li class="nav-item mobile"><a class="nav-link" href="/e-Blog">Whitepapers</a></li>
                                <li class="nav-item mobile"><a class="nav-link" href="/e-Blog">Videos</a></li>

                            </ul>
                        </li>

                        <li class="nav-item desktop" style="background-color: #35A4D4;border-radius: 30px 30px 30px 30px; margin-left:10px;"><a (click)="redirectTo('/contact-us')" class="nav-link dropdown-toggle" style="color: #FFFFFF !important;" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Contact Us</a></li>
                        <li class="nav-item mobile">
                            <a class="center1">
                                <span alt="Login" style="color: #43c65e; font-size: 19px; margin-right: 60px;" (click)="redirectTo('/e-Blog')"><img class="img1" src="../../../../assets/img/logoevert.png">-Store</span>
                                <span alt="Login"><img src="../../../../assets/img/key.png" style="width: 40px; font-size: 25px;"></span>
                            </a>
                        </li>
                        <li class="nav-item mobile" style="background-color: #F1F3F2;">
                            <a href="/contact-us" class="nav-link dropdown-toggle" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" style="width: auto !important;padding:9px; background-color: #35A4D4;border-radius: 30px 30px 30px 30px; margin: 15px 0% 15px 28%; color: #FFFFFF;border: solid 3px #35A4D4;border-top: solid 3px #35A4D4;">Contact Us</a>
                            <a href="/search" class="nav-link dropdown-toggle" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" style="width: auto !important;padding:9px; background-color: #ffffff;border-radius: 30px 30px 30px 30px; margin: 15px 15% 15px 4%;border: solid 3px #35A4D4;border-top: solid 3px #35A4D4; color: #000000;">Search</a>
                        </li>
                        <li class="nav-item mobile" style="background-color: #ffffff;padding-bottom: 100px;"></li>
                    </ul>
                    <ul class="navbar-nav menu2">

                        <li class="nav-item desktop">
                            <a class="nav-link" (click)="redirectTo('/e-Blog')" style="color: #43c65e; font-size: 16px; padding-top: 3px;"><img class="img2" src="../../../../assets/img/logoevert.png">-Store</a>
                        </li>

                        <li class="nav-item desktop">
                            <a class="nav-link dropdown-toggle" title="Login"><img src="../../../../assets/img/key.png" style="height:24px; width:auto !important; margin-left: 0px;margin-right: 0px"></a>
                        </li>
                        <li class="nav-item desktop lasticonmenu"><a class="nav-link dropdown-toggle float-right" (click)="redirectTo('/search')" title="Search"><i class="fa-sharp fa-solid fa-magnifying-glass iconsize"></i></a></li>

                    </ul>

                </div>
            </nav>
        </div>
    </div>
</div>