

<div class="main-banner">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="banner-text">
                    <h1>Exceliacom Solutions</h1>
                    <p>is a leading provider of training and professional services for wireless and wire-line network operators and regulation bodies of the global telecommunications market.</p>

                    <div class="theme-button">
                        <a routerLink="/" class="default-btn">Get Started</a>
                        <a href="https://www.youtube.com/watch?v=Fbps7_mkIT0" class="video-btn popup-vimeo"><i class="bx bx-play"></i> Watch video</a>
                    </div>
                </div>

                <div class="banner-image">
                    <img src="assets/img/1.png" alt="banner image">
                </div>
            </div>
        </div>
    </div>
</div>

<div class="video-section pt-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-7">
                <div class="video-text">
                    <a href="https://www.youtube.com/watch?v=Fbps7_mkIT0" class="popup-vimeo"><i class='bx bx-play'></i></a>
                    <p>Watch our video and know more about us</p>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="theory-section">
    <div class="container">
        <div class="row theory-area">
            <div class="col-lg-7">
                <div class="theory-text">
                    <h2>My Theory is Customer Service is First</h2>
                    <p>Exceliacom Solutions is a leading provider of professional and consultancy services for both operators and regulation bodies. Its service offering includes the different life-cycle phases of telecommunications network engineering and regulation.</p>
                    <div class="signature-box">
                        <img src="assets/img/signature.png" alt="signature">
                        <p>tarik ait-idir</p>
                        <span>Founder and CEO</span>
                    </div>
                </div>
            </div>

            <div class="theory-img">
                <img src="assets/img/theory-img.png" alt="theory image">
            </div>
        </div>
    </div>
</div>

<section class="about-section pb-100"> 
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-5">
                <div class="about-image">
                    <img src="assets/img/2.jpg" alt="about image">
                </div>
            </div>
            
            <div class="col-lg-6 offset-lg-1">
                <div class="about-text">
                    <div class="section-title">
                        <span>About Us</span>
                        <h2>The Modern Way to Make Succesful Business</h2>
                    </div>

                    <div class="row">
                        <div class="col-md-3 col-sm-4">
                            <div class="about-us">
                                <i class="flaticon-winner icon-one"></i>
                                <p>Award Winner</p>
                            </div>
                        </div>

                        <div class="col-md-3 col-sm-4">
                            <div class="about-us">
                                <i class="flaticon-customer-service icon-two"></i>
                                <p>24/7 Support</p>
                            </div>
                        </div>

                        <div class="col-md-3 col-sm-4">
                            <div class="about-us">
                                <i class="flaticon-handshake icon-three"></i>
                                <p>100% Trusted</p>
                            </div>
                        </div>
                    </div>
                    <p>The company also offers a large number of technology training solutions for both business and engineering professionals. The company training portfolio covers current key technologies—such as 3G, LTE and WiMAX. and offers a comprehensive range of Telecoms regulation topics.
                        <br><br>
                        Exceliacom team includes highly qualified professionals who are well respected in their areas, and are very active in international bodies such as the IEEE, 3GPP, and ITU.</p>
                    <div class="theme-button">
                       <a routerLink="/about" class="default-btn">Read More</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<div class="pricing-section pt-100 pb-70">
    <div class="container">
        <div class="section-title text-center">
            <h2>Why Exceliacom?</h2>
            <p>You may ask about the main reasons why to trust Exceliacom solutions with your needs! Bellow, you will find a sample list of facts:</p>
        </div>
        <div class="row">
            <div class="col-lg-4 col-sm-6">
                <div class="pricing-card">
                    <div class="price-header text-center">
                        <h2>Team</h2>
                        <i class="flaticon-startup"></i>
                    </div>

                    <div class="pricing-feature">
                        <ul>
                            <li><i class='bx bx-check'></i> Dedicated and highly experienced trainers and consultants with perfect command of both French and English.</li>
                            <li><i class='bx bx-check'></i> Entire coverage of major areas related to the Telecoms market: Technology, Regulation, Marketing, and Finance.</li>
                            <li><i class='bx bx-check'></i> Active members in international bodies such as ITU and IEEE.</li>
                            <li><i class='bx bx-check'></i> International technology patents and large publication record in archival journals and conferences.</li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="pricing-card price-card-two">
                    <div class="price-header text-center">
                        <h2>Solutions</h2>
                        <i class="flaticon-startup"></i>
                    </div>

                    <div class="pricing-feature">
                        <ul>
                            <li><i class='bx bx-check'></i> Cutting edge technology training for both business and technical professionals.</li>
                            <li><i class='bx bx-check'></i> Telecoms regulation training solutions covering up-to-date regulation problems.                            </li>
                            <li><i class='bx bx-check'></i> Professional and consultancy solutions for both Telecoms operators and regulation bodies.</li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6 offset-sm-3 offset-lg-0 ">
                <div class="pricing-card price-card-three">
                    <div class="price-header text-center">
                        <h2>Customers</h2>
                        <i class="flaticon-startup"></i>
                    </div>

                    <div class="pricing-feature">
                        <ul>
                            <li><i class='bx bx-check'></i> A large number of who's who of Telecoms operators including: MTN, Orange, Moov, MarocTelecom, MediTelecom, Wana.</li>
                            <li><i class='bx bx-check'></i> Major African Telecom regulation bodies: ANRT, ATCI, ARPTC, ARPCE, ICGB, ATRPT.</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="contact-section pb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 p-0 contact-img">
                <img src="assets/img/contact.jpg" alt="contact">
            </div>

            <div class="col-lg-6 p-0">
                <div class="contact-form">
                    <div class="contact-text">
                        <h3>We Love to Hear From You</h3>
                        <p>Feel free and share with us. We will get you</p>
                    </div>
                   
                    <form id="contactForm">
                        <div class="row">
                            <div class="col-md-12 col-sm-6">
                                <div class="form-group mb-3">
                                    <input type="text" name="name" id="name" class="form-control" placeholder="Name">
                                </div>
                            </div>
                        
                            <div class="col-md-12 col-sm-6">
                                <div class="form-group mb-3">
                                    <input type="email" name="email" id="email" class="form-control" placeholder="Email">
                                </div>
                            </div>

                            <div class="col-md-12 col-sm-6">
                                <div class="form-group mb-3">
                                    <input type="text" name="msg_subject" id="msg_subject" class="form-control" placeholder="Subject">
                                </div>
                            </div>
                        
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group mb-3">
                                    <textarea name="message" class="message-field" id="message" cols="30" rows="5" placeholder="Message"></textarea>
                                </div>
                            </div>
                        
                            <div class="col-lg-12 col-md-12">
                                <button type="submit" class="default-btn contact-btn">Send Message</button>
                            </div>
                        </div>
                    </form>

                    <img src="assets/img/shapes/1.png" class="contact-shape" alt="shape">
                </div>
            </div>
        </div>
    </div>
</div>