import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  constructor() { }

  load=true
  ngOnInit(){ 
    setTimeout(() => {
      this.load=false
    }, 100);
    
  }

}
