


<section class="service-style-four pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-8">
                <div class="service-details-area">
                    <div class="service-details-img">
                        <img src="assets/img/e.png" alt="service details">
                    </div>
                </div>
            </div>

            <div class="col-lg-4">
                
                <div class="service-category">
                    <h3>Training Solutions </h3>

                    <ul>
                        <li><a routerLink="/training">LTE Training <i class="icofont-arrow"></i></a></li>
                        <li><a routerLink="/trainingb">WiMAX Training <i class="icofont-arrow"></i></a></li>
                        <li><a routerLink="/trainingc">UMTS/HSPA Training <i class="icofont-arrow"></i></a></li>
                        <li><a routerLink="/trainingd">CDMA/EVDO Training <i class="icofont-arrow"></i></a></li>
                        <li><a routerLink="/traininge">Regulation Training <i class="icofont-arrow"></i></a></li>
                        <li><a routerLink="/trainingf">Certificate in Business Development for Mobile Broadband <i class="icofont-arrow"></i></a></li>
                        <li><a routerLink="/trainingg">Certificate in Retail Market Supervision <i class="icofont-arrow"></i></a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>