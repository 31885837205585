<div  class="newsletter-section">
    <div  class="container1">
        <div  class="newsletter-area">
            <h2>Subscribe to our Newsletter</h2>
            <div  class="col-lg-6 offset-lg-3">
                <form  class="newsletter-form">
                    <input  type="email" placeholder="Enter Your Email" [(ngModel)]="email" name="EMAIL" class="form-control">
                    <button  type="submit" class="default-btn electronics-btn" (click)="send()" *ngIf="this.etat==0">Subscribe</button>
                    
                    <button  type="submit" class="default-btn electronics-btn" (click)="send1(email)" *ngIf="this.etat==2">Subscribe</button>
                    <h4>{{error}}</h4>
                    
                </form>
                
            </div>
            <img  src="assets/img/newsletter.png" alt="shape" width="200" class="subscribe-shape">
            
        </div>
        <div class="newsletter-area col-lg-12" *ngIf="this.etat==1 || this.etat==2">
                
            <div *ngIf="this.etat==1">
                <h4>Select {{question1}} from images bellow</h4>
            </div>
            <div class="checkbox" *ngIf="this.etat==1 || this.etat==2">
                <span style="color: #FFF;text-align: left;" *ngIf="this.etat==2">Captcha is valide</span>
            </div>
            <main class="container-fluid Select" *ngIf="this.etat==1">
                <div class="element" *ngFor="let a of images1">
                    <img src="{{service.getPath()}}/uploads/{{a.image}}" height="80" width="80" (click)="check(a.image)">
                </div>
            </main>
    
        </div>
    
    </div>
</div>