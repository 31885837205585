<section class="elementor-section elementor-element elementor-element-11628a0 breadcrumb elementor-section-boxed">
    <div class="container">
        <div class="elementor-column elementor-col-100 elementor-element">
            <div class="elementor-widget-wrap elementor-element-populated">
                <div class="elementor-element elementor-element-492f643 elementor-align-left elementor-widget elementor-widget-breadcrumbs">
                    <div class="elementor-widget-container">
                        <p id="breadcrumbs">
                            <span *ngIf="url1=='about-us'">
                    <span>
                      <a>
                        Home
                      </a>
                    </span> /
                            <span>
                        Company
                    </span> /
                            <span>
                      
                          About Us
                      
                    </span>
                            </span>

                            <span *ngIf="url1=='careers'">
                    <span>
                      <a>
                        Home
                      </a>
                    </span> /
                            <span>
                        Company
                    </span> /
                            <span>
                      
                        Careers
                      
                    </span>
                            </span>

                            <span *ngIf="url1=='news-media'">
                    <span>
                      <a>
                        Home
                      </a>
                    </span> /
                            <span>
                        Company
                    </span> /
                            <span>
                      
                        News & Media
                      
                    </span>
                            </span>
                            <span *ngIf="url1=='e-Blog'">
                    <span>
                      <a>
                        Home
                      </a>
                    </span> /
                            <span>
                        Resources
                    </span> /
                            <span>
                      
                        <img class="img" src="../../assets/img/elogogristext.png">-Blog
                      
                    </span>
                            </span>
                            <span *ngIf="url1=='jobs'">
                    <span>
                      <a>
                        Home
                      </a>
                    </span> /
                            <span>
                        Company
                    </span> /
                            <span>
                        Careers
                    </span> /
                            <span>
                      
                        Jobs
                      
                    </span>
                            </span>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>