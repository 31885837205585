import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-trainingc',
  templateUrl: './trainingc.component.html',
  styleUrls: ['./trainingc.component.scss']
})
export class TrainingcComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
