

<div class="service-title">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="title-text text-center">
                <h2>Company Profile</h2>

                <ul>
                    <li><a routerLink="/">Home</a></li>
                    <li>About Exceliacom</li>
                </ul>
            </div>
        </div>
    </div>
</div>

<section class="about-section pb-100"> 
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-5">
                <div class="about-image">
                    <img src="assets/img/2.jpg" alt="about image">
                </div>
            </div>
            
            <div class="col-lg-6 offset-lg-1">
                <div class="about-text">
                    <div class="section-title">
                        <span>About Us</span>
                        <h2>The Modern Way to Make Succesful Business</h2>
                    </div>

                    <div class="row">
                        <div class="col-md-3 col-sm-4">
                            <div class="about-us">
                                <i class="flaticon-winner icon-one"></i>
                                <p>Award Winner</p>
                            </div>
                        </div>

                        <div class="col-md-3 col-sm-4">
                            <div class="about-us">
                                <i class="flaticon-customer-service icon-two"></i>
                                <p>24/7 Support</p>
                            </div>
                        </div>

                        <div class="col-md-3 col-sm-4">
                            <div class="about-us">
                                <i class="flaticon-handshake icon-three"></i>
                                <p>100% Trusted</p>
                            </div>
                        </div>
                    </div>
                    <p>
                        Exceliacom Solutions is a leading provider of training and professional services for wireless and wire-line network operators and regulation bodies of the global telecommunications market.
                        <br><br>
                        Exceliacom Solutions is a leading provider of professional and consultancy services for both operators and regulation bodies. Its service offering includes the different life-cycle phases of telecommunications network engineering and regulation.
                        <br><br>
                        The company also offers a large number of technology training solutions for both business and engineering professionals. The company training portfolio covers current key technologies—such as 3G, LTE and WiMAX; and offers a comprehensive range of Telecoms regulation topics.
                        <br><br>
                        Exceliacom team includes highly qualified professionals who are well respected in their areas, and are very active in international bodies such as the IEEE, 3GPP, and ITU.
                    </p>
                </div>
            </div>
        </div>
    </div>
</section>