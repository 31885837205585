<app-navbar-one></app-navbar-one>
<div *ngIf="load">
    <div class="container-fluid">
        <div class="row justify-content-center">
            <div class="col-lg-12 m-3 lds-ripple">
                <div></div>
                <div></div>
            </div>
        </div>
    </div>
</div>
<div *ngIf="!load">
    <div class="padding-mobile"></div>
    <app-guidage></app-guidage>

    <app-submenu></app-submenu>


    <div class="padding1">


        <div class="sc-grxQYx iWEfMK">
            <div class="sc-grxQYx iDuKqi">

                <div class="NewsLandingSearchResultsComponent__NewsItemsContainer-sc-gxpu82-0 dpvSqo">

                    <div class="css-b3pn3b">
                        <div class="css-b3pn3b">
                            <div class="css-1ncquf9">
                                <button type="submit" (click)="enablesearch()" *ngIf="!searched" class="form-control css-28g3l0" height="40">
                            Search News & Media
                        </button>
                                <button type="submit" (click)="enablesearch()" *ngIf="searched" class="form-control clicked" height="40">
                            Close Search News & Media
                        </button>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12">
                        <div class="css-1dw6sgr" *ngIf="searched">
                            <div class="css-1x2kh9a mt-3">
                                <label class="container">Press Notes
               <input type="checkbox" [(ngModel)]="a1" [ngModelOptions]="{standalone: true}">
               <span class="checkmark"></span>
             </label>
                                <label class="container">Press Release
               <input type="checkbox" [(ngModel)]="a2" [ngModelOptions]="{standalone: true}">
               <span class="checkmark"></span>
             </label>
                                <label class="container">In the Media
               <input type="checkbox" [(ngModel)]="a3" [ngModelOptions]="{standalone: true}">
               <span class="checkmark"></span>
             </label>
                                <label class="container">∊-Blog
              <input type="checkbox" [(ngModel)]="a4" [ngModelOptions]="{standalone: true}">
              <span class="checkmark"></span>
            </label>

                                <input type="text" class="input1" name="firstname" [(ngModel)]="search" placeholder="Search all News & Media">

                            </div>
                        </div>
                    </div>
                    <div class="ewzHNn">
                    </div>
                    <div class="sc-bcXHqe cjYGBp sc-gWoSmj sc-hJVpLM fyKMRa dWSsmD snipcss0-7-11-12" *ngIf="(news | newsroom:search:a1:a2:a3:a4).length==0 && !error">List is empty</div>



                    <div class="row">
                        <div class="sc-grxQYx cFULeZ snipcss0-0-0-1 padding1" *ngFor="let a of news | newsroom:search:a1:a2:a3:a4">
                            <div data-index="0" class="sc-iJbNxu cvSuHF snipcss0-1-1-2" *ngIf="a.type!='Blog'" routerLink="/news-media-detail/{{a.id}}">
                                <article data-test-id="news-item-link" location="[object Object]" [ngClass]="{'sc-eDWCr jRWwuk snipcss0-2-2-3': a.type === 'Press Notes', 'sc-eDWCr jRWwuk1 snipcss0-2-2-3' : a.type === 'Press Release', 'sc-eDWCr jRWwuk2 snipcss0-2-2-3' : a.type === 'In the Media'  }">
                                    <a class="sc-cHGmPC jFPjeA sc-dkrFOg kEzltY snipcss0-3-3-4" data-test-id="news-item-link">
                                        <div tag="header" class="sc-dkrFOg PctUQ snipcss0-4-4-5 tether-target-attached-top tether-abutted tether-abutted-top tether-element-attached-top tether-element-attached-center tether-target-attached-center">
                                            <div class="sc-bcXHqe fdUHvj snipcss0-5-5-6">
                                                <p color="blue" data-test-id="news-item-label" class="sc-csuSiG dpkuuw snipcss0-6-6-7">{{a.type}}</p>
                                            </div>
                                            <span color="newsItemContentTextColor" data-test-id="news-item-date" class="sc-eDWCr hbeOZQ snipcss0-5-5-8">{{a.created_at | date :  "MMM dd, yyyy"}}</span>
                                        </div>
                                        <div class="sc-bcXHqe fOTKLw snipcss0-4-4-9 tether-target-attached-top tether-abutted tether-abutted-top tether-element-attached-top tether-element-attached-center tether-target-attached-center">
                                            <h2 class="sc-iBYQkv fStkLY sc-kvhKsl hLlol snipcss0-5-9-10" data-test-id="news-item-title">
                                                <div color="white" class="sc-eDWCr fUkExK snipcss0-6-10-11">
                                                    <div class="sc-bcXHqe cjYGBp sc-gWoSmj sc-hJVpLM fyKMRa dWSsmD snipcss0-7-11-12">{{removeTags(a.title)}}</div>
                                                </div>
                                            </h2>
                                            <div class="sc-jxfubC dNaUyi snipcss0-5-9-24">
                                            </div>
                                        </div>
                                    </a>
                                </article>
                            </div>

                            <div class="gkduhq" *ngIf="a.type=='Blog'">
                                <article class="emKZKu" routerLink="/e-blog-detail/{{a.id}}">
                                    <a class="cnhNwd">
                                        <div class="fPRcLx">
                                            <div class="sc-bcXHqe fdUHvj snipcss0-5-5-6">
                                                <p color="blue" data-test-id="news-item-label" class="sc-csuSiG dpkuuw snipcss0-6-6-7"><img src="../../assets/img/logoeblue.png">-{{a.type}}</p>
                                            </div>
                                            <span color="newsItemContentTextColor" data-test-id="news-item-date" class="sc-eDWCr hbeOZQ snipcss0-5-5-8">{{a.created_at | date :  "MMM dd, yyyy"}}</span>

                                        </div>
                                        <div class="cXLmts">
                                            <img class="cqrtwT" [src]="this.service.getPathImage()+''+a.image">
                                        </div>
                                        <div class="fIlstS">
                                        </div>
                                        <div class="lahaJw">
                                            <h2 class="ceEGPo bhvNhv">
                                                <div class="clXQjQ">
                                                    <div class="eEYrZX isIbmM ldoCqD">
                                                        {{removeTags(a.title)}}
                                                    </div>
                                                </div>
                                            </h2>
                                        </div>
                                    </a>
                                </article>
                            </div>
                        </div>
                    </div>


                    <div class="ewzHNn">
                    </div>

                </div>

            </div>
        </div>

    </div>
    <div class="row justify-content-center" *ngIf="error">
        <div class="lds-ripple">
            <div></div>
            <div></div>
        </div>
    </div>

    <app-newsletter></app-newsletter>
    <app-footer></app-footer>
    <div class="knotch_placeholder">
    </div>
    <div>
    </div>
    <div class="  aem-GridColumn aem-GridColumn--default--12">
        <div class="aem-container aem-Grid aem-Grid--12 aem-Grid--default--12">
        </div>
        <div>
        </div>
    </div>