import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'search'
})
export class SearchPipe implements PipeTransform {

  transform(items: any[], location="", time="", category="", type=""): any {
    if(!items) return [];
    
    location = location.toLowerCase();
    time = time.toLowerCase();
    category = category.toLowerCase();
    type = type.toLowerCase();
console.log(location, time, category, type)

    return items.filter(item => 
      (location==item.location.toLowerCase() || location=="") &&
      (time==item.type1.toLowerCase() || time=="") &&
      (category==item.category.toLowerCase() || category=="") &&
      (type==item.type2.toLowerCase() || type=="")
      )
  }
}