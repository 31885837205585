<app-navbar-one></app-navbar-one>
<div *ngIf="load">    <div class="container-fluid">
        <div class="row justify-content-center">
            <div class="col-lg-12 m-3 lds-ripple"><div></div><div></div></div>
        </div>
    </div>
</div>
<div *ngIf="!load">


<app-submenu></app-submenu>
<div class="row justify-content-center" *ngIf="error">
    <div class="lds-ripple"><div></div><div></div></div>
  </div>
<div *ngIf="!error && news[index1]">
    
    <div [ngClass]="{'gkOfNl': news[index1].type === 'Press Notes', 'gkOfNl1' : news[index1].type === 'Press Release', 'gkOfNl2' : news[index1].type === 'In the Media', 'gkOfNl3' : news[index1].type === 'Blog'  }">
        <div class="container">
            <div class="cjYGBp iTDWfP fEZJEa">
                <div class="row">
                        <h2 [ngClass]="{'fStkLY durGnI container': news[index1].type !== 'Blog','fStkLY1 durGnI container': news[index1].type === 'Blog'}">
                            <img src="../../assets/img/logoeblue.png" *ngIf="news[index1].type === 'Blog'"><span *ngIf="news[index1].type === 'Blog'">-</span>{{news[index1].type}}
                        </h2>
                    <div class="hYKiq">
                        <div class="cCvGCG">
                            <div class="huPiiH">
                                <div class="lfarTx">
                                    <a class="iZwoPP eitIQN" *ngIf="index1>0" (click)="supOne()">
                                    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" class="sc-bjfHbI imSDie TertiaryNavComponent__ArrowIcon-sc-1qgck4d-1 kvYaOs">
                                        <defs>
                                        </defs>
                                        <g>
                                        <g>
                                            <path d="M6.25216138,12.9385269 L12.7602031,19.4465686 C12.9554652,19.6418308 12.9554652,19.9584133 12.7602031,20.1536754 L12.0958081,20.8180704 C11.900546,21.0133325 11.5839635,21.0133325 11.3887014,20.8180704 L3.51324437,12.9426134 C3.19134046,12.6186662 3.03038851,12.2947191 3.03038851,11.970772 C3.03038851,11.6468248 3.19134046,11.3228777 3.51324437,10.9989306 L11.3887014,3.12347359 C11.5839635,2.92821144 11.900546,2.92821144 12.0958081,3.12347359 L12.7602031,3.78786854 C12.9554652,3.98313069 12.9554652,4.29971317 12.7602031,4.49497532 L6.25624783,10.9989306 L20.4696115,10.9989306 C20.7457539,10.9989306 20.9696115,11.2227882 20.9696115,11.4989306 L20.9696115,12.4385269 C20.9696115,12.7146693 20.7457539,12.9385269 20.4696115,12.9385269 L6.25216138,12.9385269 Z" fill="inherit" id="path-1-arrow-left" transform="translate(12.000000, 11.970772) scale(1, -1) translate(-12.000000, -11.970772) ">
                                            </path>
                                        </g>
                                        </g>
                                    </svg>
                                    </a>
                                    <a class="iZwoPP eitIQN" *ngIf="index1<(news.length-1)" (click)="addOne()">
                                    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" class="sc-bjfHbI imSDie TertiaryNavComponent__ArrowIcon-sc-1qgck4d-1 kvYaOs">
                                        <defs>
                                        </defs>
                                        <g>
                                        <g>
                                            <path d="M6.25216138,12.9385269 L12.7602031,19.4465686 C12.9554652,19.6418308 12.9554652,19.9584133 12.7602031,20.1536754 L12.0958081,20.8180704 C11.900546,21.0133325 11.5839635,21.0133325 11.3887014,20.8180704 L3.51324437,12.9426134 C3.19134046,12.6186662 3.03038851,12.2947191 3.03038851,11.970772 C3.03038851,11.6468248 3.19134046,11.3228777 3.51324437,10.9989306 L11.3887014,3.12347359 C11.5839635,2.92821144 11.900546,2.92821144 12.0958081,3.12347359 L12.7602031,3.78786854 C12.9554652,3.98313069 12.9554652,4.29971317 12.7602031,4.49497532 L6.25624783,10.9989306 L20.4696115,10.9989306 C20.7457539,10.9989306 20.9696115,11.2227882 20.9696115,11.4989306 L20.9696115,12.4385269 C20.9696115,12.7146693 20.7457539,12.9385269 20.4696115,12.9385269 L6.25216138,12.9385269 Z" fill="inherit" id="path-1-arrow-right" transform="translate(12.000000, 11.970772) scale(-1, -1) translate(-12.000000, -11.970772) ">
                                            </path>
                                        </g>
                                        </g>
                                    </svg>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
    </div>
        
    <div>
    <article class="brEgEx fZafGH">
        <div>
        </div>
        <header class="kiusKR">
        <h1 class="hlaZOO bxdMrS">
            {{removeTags(news[index1].title)}}
        </h1>
        </header>
        <div class="fqahvR">
        <p class="RdUAC">
            {{news[index1].created_at | date :  "MMM dd, yyyy"}}
            <span class="eoDnLD">
            {{news[index1].location}}
            </span>
        </p>
        </div>
    </article>
    </div>

    <div class="ioHAlX" style="margin: 0px;">
    <article class="brEgEx fZafGH" id="articleContainer">
        <div>
        <div>
            <div class="aem-Grid aem-Grid--12 aem-Grid--default--12">
            <div class="aem-GridColumn aem-GridColumn--offset--default--0 aem-GridColumn--default--none">
                <div class="aem-Grid">
                <div class="aem-GridColumn aem-GridColumn--offset--default--0 aem-GridColumn--default--none">
                    <div>
                    <a href="https://www.facebook.com/sharer.php?u=https%3A%2F%2Fwww.qualcomm.com%2Fnews%2Freleases%2F2023%2F01%2Fqualcomm-schedules-first-quarter-fiscal-2023-earnings-release-an" class="cjYGBp">
                        <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" class="sc-bjfHbI imSDie sc-bbDxtK hbSBqk snipcss0-10-10-11">
                        <defs>
                        </defs>
                        <g>
                            <use xlink:href="#path-1-facebook">
                            </use>
                            <g>
                            <path d="M13.6654171,9.5001562 L17.4167448,9.5001562 L16.7644486,13.2502343 L13.6654171,13.2502343 L13.6654171,22 L9.91658857,22 L9.91658857,13.2502343 L7,13.2502343 L7,9.5001562 L9.91658857,9.5001562 L9.91658857,6.9996876 C9.91658857,4.24679788 11.975945,2 14.497657,2 L17.4167448,2 L17.4167448,5.7500781 L14.497657,5.7500781 C14.0365511,5.7500781 13.6654171,6.30615433 13.6654171,6.9996876 L13.6654171,9.5001562 Z" fill="inherit" id="path-1-facebook">
                            </path>
                            </g>
                        </g>
                        </svg>
                    </a>
                    <a href="https://www.linkedin.com/sharearticle?url=https%3A%2F%2Fwww.qualcomm.com%2Fnews%2Freleases%2F2023%2F01%2Fqualcomm-schedules-first-quarter-fiscal-2023-earnings-release-an&amp;amp;title=Qualcomm%20Schedules%20First%20Quarter%20Fiscal%202023%20Earnings%20Release%20and%20Conference%20Call" class="cjYGBp">
                        <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" class="sc-bjfHbI imSDie sc-bbDxtK hbSBqk snipcss0-10-16-17">
                        <defs>
                        </defs>
                        <g>
                            <use xlink:href="#path-1-linkedin">
                            </use>
                            <g>
                            <path d="M3.32914199,21.00028 L3.32914199,8.86867731 L7.13479202,8.86867731 L7.13479202,21.00028 L3.32914199,21.00028 Z M13.080027,13.993686 L13.080027,21.00028 L9.41430892,21.00028 L9.41430892,11.6585711 C9.41430892,11.6585711 9.35433807,9.34469591 9.35433807,8.93489512 L12.9575865,8.93489512 L13.080027,10.5990861 C13.8121711,9.430904 14.912886,8.54508461 16.379673,8.54508461 C18.9459255,8.54508461 20.7787845,10.490389 20.7787845,13.993686 L20.7787845,21.00028 L17.1118171,21.00028 L17.1118171,14.3834965 C17.1118171,12.4369427 16.2160025,11.6585711 15.1177864,11.6585711 C14.0170715,11.6585711 13.080027,12.4369427 13.080027,13.993686 Z M7.6139341,5.3828718 C7.6139341,6.69723287 6.54695277,7.76296481 5.2325917,7.76296481 C3.91698123,7.76296481 2.8499999,6.69723287 2.8499999,5.3828718 C2.8499999,4.06726133 3.91698123,3.00028 5.2325917,3.00028 C6.54695277,3.00028 7.6139341,4.06726133 7.6139341,5.3828718 Z" fill="inherit" id="path-1-linkedin">
                            </path>
                            </g>
                        </g>
                        </svg>
                    </a>
                    <a href="https://twitter.com/share?url=https%3A%2F%2Fwww.qualcomm.com%2Fnews%2Freleases%2F2023%2F01%2Fqualcomm-schedules-first-quarter-fiscal-2023-earnings-release-an&amp;text=Qualcomm%20Schedules%20First%20Quarter%20Fiscal%202023%20Earnings%20Release%20and%20Conference%20Call" class="cjYGBp">
                        <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" class="sc-bjfHbI imSDie sc-bbDxtK hbSBqk snipcss0-10-22-23">
                        <defs>
                        </defs>
                        <g>
                            <use xlink:href="#path-1-twitter">
                            </use>
                            <g>
                            <path d="M19.9627635,7.95293132 L19.9627635,8.48378222 C19.9627635,13.9015388 15.8421296,20.1517311 8.29635329,20.1517311 C5.9821511,20.1517311 3.80335436,19.6439607 2,18.4853209 C2.32158794,18.5314819 2.64625327,18.5545623 2.9709186,18.5545623 C4.89429143,18.5545623 6.67610402,17.7221266 8.06554855,16.6096477 C6.28219726,16.5865673 4.75273119,15.3848439 4.24496076,13.763056 C4.50038467,13.8092169 4.75273119,13.8322974 5.0081551,13.8322974 C5.37744268,13.8322974 5.75134636,13.7861364 6.09601477,13.6922758 C4.22188029,13.3229882 2.80935529,11.656578 2.80935529,9.66550248 L2.80935529,9.61934153 C3.36482536,9.91938769 3.98953685,10.1040315 4.66040929,10.1286507 C3.55100785,9.3869981 2.83243576,8.13757511 2.83243576,6.7250501 C2.83243576,5.96185577 3.04016002,5.33560557 3.38944453,4.73397455 C5.42668103,7.21127875 8.43483613,8.7638253 11.836898,8.94693039 C11.7676566,8.64842293 11.7461148,8.3222189 11.7461148,7.99755357 C11.7461148,5.73105103 13.5740883,3.9000001 15.8421296,3.9000001 C17.0238498,3.9000001 18.0870903,4.40930922 18.8533621,5.19712273 C19.7796584,5.01247894 20.6567164,4.66473313 21.4445299,4.20158495 C21.1444838,5.15096178 20.4951531,5.96185577 19.6380982,6.47116489 C20.4736113,6.3773043 21.2614248,6.14649956 22,5.82183422 C21.4445299,6.65580868 20.750577,7.37284207 19.9627635,7.95293132" fill="inherit" id="path-1-twitter">
                            </path>
                            </g>
                        </g>
                        </svg>
                    </a>
                    </div>
                    <div>
                    </div>
                </div>
                </div>
                <div>
                </div>
            </div>
            <div class="">
                <div class="aem-Grid aem-Grid--6 aem-Grid--default--6">
                <div class="aem-GridColumn aem-GridColumn--default--6">
                    <div class="gOGeHl rte-supplemental-blockQuote">
                    <div class="cMjyLO">
                        <div class="cjYGBp cmMLgH richText">
                        <div>
                            <img *ngIf="news[index1].type=='Blog'" class="cqrtwT1" [src]="this.service.getPathImage()+''+news[index1].image">
                                
                            <div id="richTextContainer"  *ngIf="news[index1]">
                                <span class="classp" [innerHTML]="news[index1].location+' - '+(news[index1].created_at | date :  'MMM dd, yyyy')+' - '+replacePbySpan(news[index1].description)"></span>
                                <div class="dRhZPz">
                                    <br>
                                    <p class="hBetOR">
                                        <strong style="font-size: 23px;">
                                            Exceliacom Contacts
                                        </strong>
                                    </p>
                                    <div class="euwHOQ">
                                        <strong>
                                            {{news[index1].posted_by}}
                                        </strong>
                                        <strong>
                                            {{news[index1].object}}
                                        </strong>
                                    <p class="hBetOR egfcME">
                                        <img src="../../assets/img/phone.png" class="imgIcon" width="20" height="20">
                                        <strong>
                                            {{news[index1].phone}}
                                        </strong>
                                    </p>
                                    <p class="hhnPty">
                                        <img src="../../assets/img/mail.png" class="egfcME imgIcon" width="20" height="20">
                                        <strong>
                                            <a href="mailto:ir@qualcomm.com " class="hBetOR">
                                                {{news[index1].email}} 
                                            </a>
                                        </strong>
                                    </p>
                                    </div>
                                    
                                </div>
                            
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                    <div>
                    </div>
                </div>
                </div>
                <div>
                </div>
            </div>
            <div class="aem-GridColumn aem-GridColumn--offset--default--0 aem-GridColumn--default--none">
                <div class="aem-Grid">
                </div>
                <div>
                </div>
            </div>
            <div class="">
                <div class="aem-Grid aem-Grid--6 aem-Grid--default--6">
                <div class="aem-GridColumn aem-GridColumn--default--6">
                    <div class="pressReleaseFooter">
                    <footer class="fsshvX">
                        <div>
                        <div>
                        </div>
                        </div>
                        <div>
                        <div class="cjYGBp cmMLgH">
                        </div>
                        </div>
                        <div class="beHfpg">
                        
                        </div>
                    </footer>
                    </div>
                    <div>
                    </div>
                </div>
                </div>
                <div>
                </div>
            </div>
            </div>
            <div>
            </div>
        </div>
        </div>
        
            <div class="hbXIxr">
                <div class="jxNaSW jLxSjI">
                    <div>
                        <h4 class="relatedtext" *ngIf="news[index1].type=='In the Media'"> Related Media News  </h4>
                        <h4 class="relatedtext" *ngIf="news[index1].type=='Press Notes'"> Related Press Notes  </h4>
                        <h4 class="relatedtext" *ngIf="news[index1].type=='Press Release'"> Related Press Releases  </h4>
                    </div>
                </div>
    </div>
    </article>
    </div>

    
    <div class="kOcQPs">
        
    <div class="tumMG">

            <div id="myCarousel" class="carousel slide mobile" data-ride="carousel" data-interval=1000000>
              <!-- Indicators -->
              <ol class="carousel-indicators">
                <li *ngIf="newsFilter(news[index1].type)[0]" data-target="#myCarousel" data-slide-to="0" class="active"></li>
                <li *ngIf="newsFilter(news[index1].type)[1]" data-target="#myCarousel" data-slide-to="1"></li>
                <li *ngIf="newsFilter(news[index1].type)[2]" data-target="#myCarousel" data-slide-to="2"></li>
                <li *ngIf="newsFilter(news[index1].type)[3]" data-target="#myCarousel" data-slide-to="3"></li>
                <li *ngIf="newsFilter(news[index1].type)[4]" data-target="#myCarousel" data-slide-to="4"></li>
              </ol>
          
              <!-- Wrapper for slides -->
              <div class="carousel-inner">
          
                <div class="item active" *ngIf="newsFilter(news[index1].type)[0]"  (click)="changeId(newsFilter(news[index1].type)[0].id)" routerLink="/news-media-detail/{{newsFilter(news[index1].type)[0].id}}" >
                  
                    <div class="kaVeKu" *ngIf="newsFilter(news[index1].type)[0].type!='Blog'">
                        <div class="dJuwOf">
                        <article [ngClass]="{'jRWwuk': newsFilter(news[index1].type)[0].type === 'Press Notes', 'jRWwuk1' : newsFilter(news[index1].type)[0].type === 'Press Release', 'jRWwuk2' : newsFilter(news[index1].type)[0].type === 'In the Media'  }">
                            <a class="kEzltY">
                            <div class="PctUQ">
                                <div class="fdUHvj">
                                <p class="dpkuuw">
                                    {{newsFilter(news[index1].type)[0].type}}
                                </p>
                                </div>
                                <span class="hbeOZQ">
                                {{newsFilter(news[index1].type)[0].created_at | date :  "MMM dd, yyyy"}}
                                </span>
                            </div>
                            <div class="fOTKLw">
                                <h2 class="fStkLY hLlol">
                                <div class="fUkExK">
                                    <div class="cjYGBp fyKMRa dWSsmD">
                                    {{removeTags(newsFilter(news[index1].type)[0].title)}}
                                    </div>
                                    
                                </div>
                                </h2>
                                <div class="dNaUyi">
                                </div>
                            </div>
                            </a>
                        </article>
                        </div>
                    </div>
                    <div class="gkduhq" *ngIf="newsFilter(news[index1].type)[0].type=='Blog'">
                        <article class="emKZKu" routerLink="/news-media-detail/{{newsFilter(news[index1].type)[0].id}}">
                          <a class="cnhNwd">
                            <div class="fPRcLx">
                              <div class="sc-bcXHqe fdUHvj snipcss0-5-5-6">
                                <p color="blue" data-test-id="news-item-label" class="sc-csuSiG dpkuuw snipcss0-6-6-7"><img class="elogo" src="../../assets/img/logoeblue.png">-{{newsFilter(news[index1].type)[0].type}}</p>
                                </div>
                                <span color="newsItemContentTextColor" data-test-id="news-item-date" class="sc-eDWCr hbeOZQ snipcss0-5-5-8">{{newsFilter(news[index1].type)[0].created_at | date :  "MMM dd, yyyy"}}</span>
                       
                            </div>
                            <div class="cXLmts">
                              <img class="cqrtwT" [src]="this.service.getPathImage()+''+newsFilter(news[index1].type)[0].image">
                            </div>
                            <div class="fIlstS">
                            </div>
                            <div class="lahaJw">
                              <h2 class="ceEGPo bhvNhv">
                                <div class="clXQjQ">
                                  <div class="eEYrZX isIbmM ldoCqD">
                                    {{removeTags(newsFilter(news[index1].type)[0].title)}}
                                  </div>
                                </div>
                              </h2>
                            </div>
                          </a>
                        </article>
                    </div>
              </div>
              <div class="item" *ngIf="newsFilter(news[index1].type)[1]"  (click)="changeId(newsFilter(news[index1].type)[1].id)" routerLink="/news-media-detail/{{newsFilter(news[index1].type)[1].id}}">
                  
                <div class="kaVeKu" *ngIf="newsFilter(news[index1].type)[0].type!='Blog'">
                    <div class="dJuwOf">
                    <article [ngClass]="{'jRWwuk': newsFilter(news[index1].type)[1].type === 'Press Notes', 'jRWwuk1' : newsFilter(news[index1].type)[1].type === 'Press Release', 'jRWwuk2' : newsFilter(news[index1].type)[1].type === 'In the Media'  }">
                        <a class="kEzltY">
                        <div class="PctUQ">
                            <div class="fdUHvj">
                            <p class="dpkuuw">
                                {{newsFilter(news[index1].type)[1].type}}
                            </p>
                            </div>
                            <span class="hbeOZQ">
                            {{newsFilter(news[index1].type)[1].created_at | date :  "MMM dd, yyyy"}}
                            </span>
                        </div>
                        <div class="fOTKLw">
                            <h2 class="fStkLY hLlol">
                            <div class="fUkExK">
                                <div class="cjYGBp fyKMRa dWSsmD">
                                {{removeTags(newsFilter(news[index1].type)[1].title)}}
                                </div>
                                
                            </div>
                            </h2>
                            <div class="dNaUyi">
                            </div>
                        </div>
                        </a>
                    </article>
                    </div>
                </div>
                
                <div class="gkduhq" *ngIf="newsFilter(news[index1].type)[1].type=='Blog'">
                    <article class="emKZKu" routerLink="/news-media-detail/{{newsFilter(news[index1].type)[1].id}}">
                      <a class="cnhNwd">
                        <div class="fPRcLx">
                          <div class="sc-bcXHqe fdUHvj snipcss0-5-5-6">
                            <p color="blue" data-test-id="news-item-label" class="sc-csuSiG dpkuuw snipcss0-6-6-7"><img class="elogo" src="../../assets/img/logoeblue.png">-{{newsFilter(news[index1].type)[1].type}}</p>
                            </div>
                            <span color="newsItemContentTextColor" data-test-id="news-item-date" class="sc-eDWCr hbeOZQ snipcss0-5-5-8">{{newsFilter(news[index1].type)[1].created_at | date :  "MMM dd, yyyy"}}</span>
                   
                        </div>
                        <div class="cXLmts">
                          <img class="cqrtwT" [src]="this.service.getPathImage()+''+newsFilter(news[index1].type)[1].image">
                        </div>
                        <div class="fIlstS">
                        </div>
                        <div class="lahaJw">
                          <h2 class="ceEGPo bhvNhv">
                            <div class="clXQjQ">
                              <div class="eEYrZX isIbmM ldoCqD">
                                {{removeTags(newsFilter(news[index1].type)[1].title)}}
                              </div>
                            </div>
                          </h2>
                        </div>
                      </a>
                    </article>
                </div>
              </div>
              <div class="item" *ngIf="newsFilter(news[index1].type)[2]"  (click)="changeId(newsFilter(news[index1].type)[2].id)" routerLink="/news-media-detail/{{newsFilter(news[index1].type)[2].id}}">
                  
                    <div class="kaVeKu" *ngIf="newsFilter(news[index1].type)[0].type!='Blog'">
                        <div class="dJuwOf">
                        <article [ngClass]="{'jRWwuk': newsFilter(news[index1].type)[2].type === 'Press Notes', 'jRWwuk1' : newsFilter(news[index1].type)[2].type === 'Press Release', 'jRWwuk2' : newsFilter(news[index1].type)[2].type === 'In the Media'  }">
                            <a class="kEzltY">
                            <div class="PctUQ">
                                <div class="fdUHvj">
                                <p class="dpkuuw">
                                    {{newsFilter(news[index1].type)[2].type}}
                                </p>
                                </div>
                                <span class="hbeOZQ">
                                {{newsFilter(news[index1].type)[2].created_at | date :  "MMM dd, yyyy"}}
                                </span>
                            </div>
                            <div class="fOTKLw">
                                <h2 class="fStkLY hLlol">
                                <div class="fUkExK">
                                    <div class="cjYGBp fyKMRa dWSsmD">
                                    {{removeTags(newsFilter(news[index1].type)[2].title)}}
                                    </div>
                                    
                                </div>
                                </h2>
                                <div class="dNaUyi">
                                </div>
                            </div>
                            </a>
                        </article>
                        </div>
                    </div>

                    <div class="gkduhq" *ngIf="newsFilter(news[index1].type)[2].type=='Blog'">
                        <article class="emKZKu" routerLink="/news-media-detail/{{newsFilter(news[index1].type)[2].id}}">
                          <a class="cnhNwd">
                            <div class="fPRcLx">
                              <div class="sc-bcXHqe fdUHvj snipcss0-5-5-6">
                                <p color="blue" data-test-id="news-item-label" class="sc-csuSiG dpkuuw snipcss0-6-6-7"><img class="elogo" src="../../assets/img/logoeblue.png">-{{newsFilter(news[index1].type)[2].type}}</p>
                                </div>
                                <span color="newsItemContentTextColor" data-test-id="news-item-date" class="sc-eDWCr hbeOZQ snipcss0-5-5-8">{{newsFilter(news[index1].type)[2].created_at | date :  "MMM dd, yyyy"}}</span>
                       
                            </div>
                            <div class="cXLmts">
                              <img class="cqrtwT" [src]="this.service.getPathImage()+''+newsFilter(news[index1].type)[2].image">
                            </div>
                            <div class="fIlstS">
                            </div>
                            <div class="lahaJw">
                              <h2 class="ceEGPo bhvNhv">
                                <div class="clXQjQ">
                                  <div class="eEYrZX isIbmM ldoCqD">
                                    {{removeTags(newsFilter(news[index1].type)[2].title)}}
                                  </div>
                                </div>
                              </h2>
                            </div>
                          </a>
                        </article>
                    </div>

              </div>
              <div class="item" *ngIf="newsFilter(news[index1].type)[3]"  (click)="changeId(newsFilter(news[index1].type)[3].id)" routerLink="/news-media-detail/{{newsFilter(news[index1].type)[3].id}}">
                  
                        <div class="kaVeKu" *ngIf="newsFilter(news[index1].type)[0].type!='Blog'">
                            <div class="dJuwOf">
                            <article [ngClass]="{'jRWwuk': newsFilter(news[index1].type)[3].type === 'Press Notes', 'jRWwuk1' : newsFilter(news[index1].type)[3].type === 'Press Release', 'jRWwuk2' : newsFilter(news[index1].type)[3].type === 'In the Media'  }">
                                <a class="kEzltY">
                                <div class="PctUQ">
                                    <div class="fdUHvj">
                                    <p class="dpkuuw">
                                        {{newsFilter(news[index1].type)[3].type}}
                                    </p>
                                    </div>
                                    <span class="hbeOZQ">
                                    {{newsFilter(news[index1].type)[3].created_at | date :  "MMM dd, yyyy"}}
                                    </span>
                                </div>
                                <div class="fOTKLw">
                                    <h2 class="fStkLY hLlol">
                                    <div class="fUkExK">
                                        <div class="cjYGBp fyKMRa dWSsmD">
                                        {{removeTags(newsFilter(news[index1].type)[3].title)}}
                                        </div>
                                        
                                    </div>
                                    </h2>
                                    <div class="dNaUyi">
                                    </div>
                                </div>
                                </a>
                            </article>
                            </div>
                        </div>

                        <div class="gkduhq" *ngIf="newsFilter(news[index1].type)[3].type=='Blog'">
                            <article class="emKZKu" routerLink="/news-media-detail/{{newsFilter(news[index1].type)[3].id}}">
                              <a class="cnhNwd">
                                <div class="fPRcLx">
                                  <div class="sc-bcXHqe fdUHvj snipcss0-5-5-6">
                                    <p color="blue" data-test-id="news-item-label" class="sc-csuSiG dpkuuw snipcss0-6-6-7"><img class="elogo" src="../../assets/img/logoeblue.png">-{{newsFilter(news[index1].type)[3].type}}</p>
                                    </div>
                                    <span color="newsItemContentTextColor" data-test-id="news-item-date" class="sc-eDWCr hbeOZQ snipcss0-5-5-8">{{newsFilter(news[index1].type)[3].created_at | date :  "MMM dd, yyyy"}}</span>
                           
                                </div>
                                <div class="cXLmts">
                                  <img class="cqrtwT" [src]="this.service.getPathImage()+''+newsFilter(news[index1].type)[3].image">
                                </div>
                                <div class="fIlstS">
                                </div>
                                <div class="lahaJw">
                                  <h2 class="ceEGPo bhvNhv">
                                    <div class="clXQjQ">
                                      <div class="eEYrZX isIbmM ldoCqD">
                                        {{removeTags(newsFilter(news[index1].type)[3].title)}}
                                      </div>
                                    </div>
                                  </h2>
                                </div>
                              </a>
                            </article>
                        </div>
              </div>
              <div class="item" *ngIf="newsFilter(news[index1].type)[4]"  (click)="changeId(newsFilter(news[index1].type)[4].id)" routerLink="/news-media-detail/{{newsFilter(news[index1].type)[4].id}}">
                  
                            <div class="kaVeKu" *ngIf="newsFilter(news[index1].type)[0].type!='Blog'">
                                <div class="dJuwOf">
                                <article [ngClass]="{'jRWwuk': newsFilter(news[index1].type)[4].type === 'Press Notes', 'jRWwuk1' : newsFilter(news[index1].type)[4].type === 'Press Release', 'jRWwuk2' : newsFilter(news[index1].type)[4].type === 'In the Media'  }">
                                    <a class="kEzltY">
                                    <div class="PctUQ">
                                        <div class="fdUHvj">
                                        <p class="dpkuuw">
                                            {{newsFilter(news[index1].type)[4].type}}
                                        </p>
                                        </div>
                                        <span class="hbeOZQ">
                                        {{newsFilter(news[index1].type)[4].created_at | date :  "MMM dd, yyyy"}}
                                        </span>
                                    </div>
                                    <div class="fOTKLw">
                                        <h2 class="fStkLY hLlol">
                                        <div class="fUkExK">
                                            <div class="cjYGBp fyKMRa dWSsmD">
                                            {{removeTags(newsFilter(news[index1].type)[4].title)}}
                                            </div>
                                            
                                        </div>
                                        </h2>
                                        <div class="dNaUyi">
                                        </div>
                                    </div>
                                    </a>
                                </article>
                                </div>
                            </div>

                            <div class="gkduhq" *ngIf="newsFilter(news[index1].type)[4].type=='Blog'">
                    <article class="emKZKu" routerLink="/news-media-detail/{{newsFilter(news[index1].type)[4].id}}">
                      <a class="cnhNwd">
                        <div class="fPRcLx">
                          <div class="sc-bcXHqe fdUHvj snipcss0-5-5-6">
                            <p color="blue" data-test-id="news-item-label" class="sc-csuSiG dpkuuw snipcss0-6-6-7"><img class="elogo" src="../../assets/img/logoeblue.png">-{{newsFilter(news[index1].type)[4].type}}</p>
                            </div>
                            <span color="newsItemContentTextColor" data-test-id="news-item-date" class="sc-eDWCr hbeOZQ snipcss0-5-5-8">{{newsFilter(news[index1].type)[4].created_at | date :  "MMM dd, yyyy"}}</span>
                   
                        </div>
                        <div class="cXLmts">
                          <img class="cqrtwT" [src]="this.service.getPathImage()+''+newsFilter(news[index1].type)[4].image">
                        </div>
                        <div class="fIlstS">
                        </div>
                        <div class="lahaJw">
                          <h2 class="ceEGPo bhvNhv">
                            <div class="clXQjQ">
                              <div class="eEYrZX isIbmM ldoCqD">
                                {{removeTags(newsFilter(news[index1].type)[4].title)}}
                              </div>
                            </div>
                          </h2>
                        </div>
                      </a>
                    </article>
                            </div>
              </div>
            
              </div>
          
             
              <a class="carousel-control-prev" href="#myCarousel" role="button" data-slide="prev">
                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                <span class="sr-only">Previous</span>
              </a>
              <a class="carousel-control-next" href="#myCarousel" role="button" data-slide="next">
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                <span class="sr-only">Next</span>
              </a>
            </div>
          </div>
          
      
              
        
          <!--//////////////////////////////////--> 
<div class="container">
            <div id="myCarousel1" class="carousel slide desktop" data-ride="carousel" data-interval=1000000>
              <!-- Indicators -->
              <ol class="carousel-indicators">
                <li *ngIf="newsFilter(news[index1].type)[0]" data-target="#myCarousel1" data-slide-to="0" class="active"></li>
                <li *ngIf="newsFilter(news[index1].type)[3]" data-target="#myCarousel1" data-slide-to="1"></li>
                <li *ngIf="newsFilter(news[index1].type)[4]" data-target="#myCarousel1" data-slide-to="2"></li>
              </ol>
          
              <div class="row">
                <div class="col-lg-2">
                    </div>
                <div class="col-lg-8">
                    
                <div class="carousel-inner">
                    <div class="item active" *ngIf="newsFilter(news[index1].type)[0]">
                        <span *ngIf="newsFilter(news[index1].type)[0]"><div [ngClass]="{'kaVeKu1': newsFilter(news[index1].type).length === 1,'kaVeKu2': newsFilter(news[index1].type).length === 2}"></div></span>
                        <span *ngIf="newsFilter(news[index1].type)[0]">
                          <div class="kaVeKu" *ngIf="this.id!=newsFilter(news[index1].type)[0].id && newsFilter(news[index1].type)[0].type!='Blog'" (click)="changeId(newsFilter(news[index1].type)[0].id)" routerLink="/news-media-detail/{{newsFilter(news[index1].type)[0].id}}">
                              <div class="dJuwOf">
                              <article [ngClass]="{'jRWwuk': newsFilter(news[index1].type)[0].type === 'Press Notes', 'jRWwuk1' : newsFilter(news[index1].type)[0].type === 'Press Release', 'jRWwuk2' : newsFilter(news[index1].type)[0].type === 'In the Media'  }">
                                  <a class="kEzltY">
                                  <div class="PctUQ">
                                      <div class="fdUHvj">
                                      <p class="dpkuuw">
                                          {{newsFilter(news[index1].type)[0].type}}
                                      </p>
                                      </div>
                                      <span class="hbeOZQ">
                                      {{newsFilter(news[index1].type)[0].created_at | date :  "MMM dd, yyyy"}}
                                      </span>
                                  </div>
                                  <div class="fOTKLw">
                                      <h2 class="fStkLY hLlol">
                                      <div class="fUkExK">
                                          <div class="cjYGBp fyKMRa dWSsmD">
                                          {{removeTags(newsFilter(news[index1].type)[0].title)}}
                                          </div>
                                          
                                      </div>
                                      </h2>
                                      <div class="dNaUyi">
                                      </div>
                                  </div>
                                  </a>
                              </article>
                              </div>
                          </div>
                          <div class="kaVeKu" *ngIf="this.id!=newsFilter(news[index1].type)[0].id && newsFilter(news[index1].type)[0].type=='Blog'" (click)="changeId(newsFilter(news[index1].type)[0].id)" routerLink="/news-media-detail/{{newsFilter(news[index1].type)[0].id}}">
                            <div class="dJuwOf">
                                <article class="emKZKu" routerLink="/news-media-detail/{{newsFilter(news[index1].type)[0].id}}">
                                    <a class="cnhNwd">
                                      <div class="fPRcLx">
                                        <div class="sc-bcXHqe fdUHvj snipcss0-5-5-6">
                                          <p color="blue" data-test-id="news-item-label" class="sc-csuSiG dpkuuw snipcss0-6-6-7"><img class="elogo" src="../../assets/img/logoeblue.png">-{{newsFilter(news[index1].type)[0].type}}</p>
                                          </div>
                                          <span color="newsItemContentTextColor" data-test-id="news-item-date" class="sc-eDWCr hbeOZQ snipcss0-5-5-8">{{newsFilter(news[index1].type)[0].created_at | date :  "MMM dd, yyyy"}}</span>
                                 
                                      </div>
                                      <div class="cXLmts">
                                        <img class="cqrtwT" [src]="this.service.getPathImage()+''+newsFilter(news[index1].type)[0].image">
                                      </div>
                                      <div class="fIlstS">
                                      </div>
                                      <div class="lahaJw">
                                        <h2 class="ceEGPo bhvNhv">
                                          <div class="clXQjQ">
                                            <div class="eEYrZX isIbmM ldoCqD">
                                              {{removeTags(newsFilter(news[index1].type)[0].title)}}
                                            </div>
                                          </div>
                                        </h2>
                                      </div>
                                    </a>
                                  </article>
                            </div>
                        </div>
                        </span>
                        <span *ngIf="newsFilter(news[index1].type)[1]">
                          <div class="kaVeKu" *ngIf="this.id!=newsFilter(news[index1].type)[1].id && newsFilter(news[index1].type)[1].type!='Blog'"  (click)="changeId(newsFilter(news[index1].type)[1].id)" routerLink="/news-media-detail/{{newsFilter(news[index1].type)[1].id}}">
                              <div class="dJuwOf">
                              <article [ngClass]="{'jRWwuk': newsFilter(news[index1].type)[1].type === 'Press Notes', 'jRWwuk1' : newsFilter(news[index1].type)[1].type === 'Press Release', 'jRWwuk2' : newsFilter(news[index1].type)[1].type === 'In the Media'  }">
                                  <a class="kEzltY">
                                  <div class="PctUQ">
                                      <div class="fdUHvj">
                                      <p class="dpkuuw">
                                          {{newsFilter(news[index1].type)[1].type}}
                                      </p>
                                      </div>
                                      <span class="hbeOZQ">
                                      {{newsFilter(news[index1].type)[1].created_at | date :  "MMM dd, yyyy"}}
                                      </span>
                                  </div>
                                  <div class="fOTKLw">
                                      <h2 class="fStkLY hLlol">
                                      <div class="fUkExK">
                                          <div class="cjYGBp fyKMRa dWSsmD">
                                          {{removeTags(newsFilter(news[index1].type)[1].title)}}
                                          </div>
                                          
                                      </div>
                                      </h2>
                                      <div class="dNaUyi">
                                      </div>
                                  </div>
                                  </a>
                              </article>
                              </div>
                          </div>
                          <div class="kaVeKu" *ngIf="this.id!=newsFilter(news[index1].type)[1].id && newsFilter(news[index1].type)[1].type=='Blog'"  (click)="changeId(newsFilter(news[index1].type)[1].id)" routerLink="/news-media-detail/{{newsFilter(news[index1].type)[1].id}}">
                            <div class="dJuwOf">
                                <article class="emKZKu" routerLink="/news-media-detail/{{newsFilter(news[index1].type)[1].id}}">
                                    <a class="cnhNwd">
                                      <div class="fPRcLx">
                                        <div class="sc-bcXHqe fdUHvj snipcss0-5-5-6">
                                          <p color="blue" data-test-id="news-item-label" class="sc-csuSiG dpkuuw snipcss0-6-6-7"><img class="elogo" src="../../assets/img/logoeblue.png">-{{newsFilter(news[index1].type)[1].type}}</p>
                                          </div>
                                          <span color="newsItemContentTextColor" data-test-id="news-item-date" class="sc-eDWCr hbeOZQ snipcss0-5-5-8">{{newsFilter(news[index1].type)[1].created_at | date :  "MMM dd, yyyy"}}</span>
                                 
                                      </div>
                                      <div class="cXLmts">
                                        <img class="cqrtwT" [src]="this.service.getPathImage()+''+newsFilter(news[index1].type)[1].image">
                                      </div>
                                      <div class="fIlstS">
                                      </div>
                                      <div class="lahaJw">
                                        <h2 class="ceEGPo bhvNhv">
                                          <div class="clXQjQ">
                                            <div class="eEYrZX isIbmM ldoCqD">
                                              {{removeTags(newsFilter(news[index1].type)[1].title)}}
                                            </div>
                                          </div>
                                        </h2>
                                      </div>
                                    </a>
                                  </article>
                            </div>
                        </div>
                        </span>
                        <span *ngIf="newsFilter(news[index1].type)[2]">
                          <div class="kaVeKu" *ngIf="this.id!=newsFilter(news[index1].type)[2].id && newsFilter(news[index1].type)[2].type!='Blog'"  (click)="changeId(newsFilter(news[index1].type)[2].id)" routerLink="/news-media-detail/{{newsFilter(news[index1].type)[2].id}}">
                              <div class="dJuwOf">
                              <article [ngClass]="{'jRWwuk': newsFilter(news[index1].type)[2].type === 'Press Notes', 'jRWwuk1' : newsFilter(news[index1].type)[2].type === 'Press Release', 'jRWwuk2' : newsFilter(news[index1].type)[2].type === 'In the Media'  }">
                                  <a class="kEzltY">
                                  <div class="PctUQ">
                                      <div class="fdUHvj">
                                      <p class="dpkuuw">
                                          {{newsFilter(news[index1].type)[2].type}}
                                      </p>
                                      </div>
                                      <span class="hbeOZQ">
                                      {{newsFilter(news[index1].type)[2].created_at | date :  "MMM dd, yyyy"}}
                                      </span>
                                  </div>
                                  <div class="fOTKLw">
                                      <h2 class="fStkLY hLlol">
                                      <div class="fUkExK">
                                          <div class="cjYGBp fyKMRa dWSsmD">
                                          {{removeTags(newsFilter(news[index1].type)[2].title)}}
                                          </div>
                                          
                                      </div>
                                      </h2>
                                      <div class="dNaUyi">
                                      </div>
                                  </div>
                                  </a>
                              </article>
                              </div>
                          </div>
                          <div class="kaVeKu" *ngIf="this.id!=newsFilter(news[index1].type)[2].id && newsFilter(news[index1].type)[2].type=='Blog'"  (click)="changeId(newsFilter(news[index1].type)[2].id)" routerLink="/news-media-detail/{{newsFilter(news[index1].type)[2].id}}">
                            <div class="dJuwOf">
                                <article class="emKZKu" routerLink="/news-media-detail/{{newsFilter(news[index1].type)[2].id}}">
                                    <a class="cnhNwd">
                                      <div class="fPRcLx">
                                        <div class="sc-bcXHqe fdUHvj snipcss0-5-5-6">
                                          <p color="blue" data-test-id="news-item-label" class="sc-csuSiG dpkuuw snipcss0-6-6-7"><img class="elogo" src="../../assets/img/logoeblue.png">-{{newsFilter(news[index1].type)[2].type}}</p>
                                          </div>
                                          <span color="newsItemContentTextColor" data-test-id="news-item-date" class="sc-eDWCr hbeOZQ snipcss0-5-5-8">{{newsFilter(news[index1].type)[2].created_at | date :  "MMM dd, yyyy"}}</span>
                                 
                                      </div>
                                      <div class="cXLmts">
                                        <img class="cqrtwT" [src]="this.service.getPathImage()+''+newsFilter(news[index1].type)[2].image">
                                      </div>
                                      <div class="fIlstS">
                                      </div>
                                      <div class="lahaJw">
                                        <h2 class="ceEGPo bhvNhv">
                                          <div class="clXQjQ">
                                            <div class="eEYrZX isIbmM ldoCqD">
                                              {{removeTags(newsFilter(news[index1].type)[2].title)}}
                                            </div>
                                          </div>
                                        </h2>
                                      </div>
                                    </a>
                                  </article>
                            </div>
                        </div>
                    </span>
                    </div>
                    <div class="item" *ngIf="newsFilter(news[index1].type)[3]">
                        <span *ngIf="newsFilter(news[index1].type)[0]"><div [ngClass]="{'kaVeKu1': newsFilter(news[index1].type).length === 2,'kaVeKu2': newsFilter(news[index1].type).length === 3}"></div></span>
                        <span *ngIf="newsFilter(news[index1].type)[1]">
                            <div class="kaVeKu" *ngIf="this.id!=newsFilter(news[index1].type)[1].id"  (click)="changeId(newsFilter(news[index1].type)[1].id)" routerLink="/news-media-detail/{{newsFilter(news[index1].type)[1].id}}">
                                <div class="dJuwOf">
                                <article [ngClass]="{'jRWwuk': newsFilter(news[index1].type)[1].type === 'Press Notes', 'jRWwuk1' : newsFilter(news[index1].type)[1].type === 'Press Release', 'jRWwuk2' : newsFilter(news[index1].type)[1].type === 'In the Media'  }">
                                    <a class="kEzltY">
                                    <div class="PctUQ">
                                        <div class="fdUHvj">
                                        <p class="dpkuuw">
                                            {{newsFilter(news[index1].type)[1].type}}
                                        </p>
                                        </div>
                                        <span class="hbeOZQ">
                                        {{newsFilter(news[index1].type)[1].created_at | date :  "MMM dd, yyyy"}}
                                        </span>
                                    </div>
                                    <div class="fOTKLw">
                                        <h2 class="fStkLY hLlol">
                                        <div class="fUkExK">
                                            <div class="cjYGBp fyKMRa dWSsmD">
                                            {{removeTags(newsFilter(news[index1].type)[1].title)}}
                                            </div>
                                            
                                        </div>
                                        </h2>
                                        <div class="dNaUyi">
                                        </div>
                                    </div>
                                    </a>
                                </article>
                                </div>
                            </div>
                            <div class="kaVeKu" *ngIf="this.id!=newsFilter(news[index1].type)[1].id && newsFilter(news[index1].type)[1].type=='Blog'"  (click)="changeId(newsFilter(news[index1].type)[1].id)" routerLink="/news-media-detail/{{newsFilter(news[index1].type)[2].id}}">
                                <div class="dJuwOf">
                                    <article class="emKZKu" routerLink="/news-media-detail/{{newsFilter(news[index1].type)[2].id}}">
                                        <a class="cnhNwd">
                                          <div class="fPRcLx">
                                            <div class="sc-bcXHqe fdUHvj snipcss0-5-5-6">
                                              <p color="blue" data-test-id="news-item-label" class="sc-csuSiG dpkuuw snipcss0-6-6-7"><img class="elogo" src="../../assets/img/logoeblue.png">-{{newsFilter(news[index1].type)[2].type}}</p>
                                              </div>
                                              <span color="newsItemContentTextColor" data-test-id="news-item-date" class="sc-eDWCr hbeOZQ snipcss0-5-5-8">{{newsFilter(news[index1].type)[2].created_at | date :  "MMM dd, yyyy"}}</span>
                                     
                                          </div>
                                          <div class="cXLmts">
                                            <img class="cqrtwT" [src]="this.service.getPathImage()+''+newsFilter(news[index1].type)[2].image">
                                          </div>
                                          <div class="fIlstS">
                                          </div>
                                          <div class="lahaJw">
                                            <h2 class="ceEGPo bhvNhv">
                                              <div class="clXQjQ">
                                                <div class="eEYrZX isIbmM ldoCqD">
                                                  {{removeTags(newsFilter(news[index1].type)[2].title)}}
                                                </div>
                                              </div>
                                            </h2>
                                          </div>
                                        </a>
                                      </article>
                                </div>
                            </div>
                        </span>
                        <span *ngIf="newsFilter(news[index1].type)[2]">
                            <div class="kaVeKu" *ngIf="this.id!=newsFilter(news[index1].type)[2].id"  (click)="changeId(newsFilter(news[index1].type)[2].id)" routerLink="/news-media-detail/{{newsFilter(news[index1].type)[2].id}}">
                                <div class="dJuwOf">
                                <article [ngClass]="{'jRWwuk': newsFilter(news[index1].type)[2].type === 'Press Notes', 'jRWwuk1' : newsFilter(news[index1].type)[2].type === 'Press Release', 'jRWwuk2' : newsFilter(news[index1].type)[2].type === 'In the Media'  }">
                                    <a class="kEzltY">
                                    <div class="PctUQ">
                                        <div class="fdUHvj">
                                        <p class="dpkuuw">
                                            {{newsFilter(news[index1].type)[2].type}}
                                        </p>
                                        </div>
                                        <span class="hbeOZQ">
                                        {{newsFilter(news[index1].type)[2].created_at | date :  "MMM dd, yyyy"}}
                                        </span>
                                    </div>
                                    <div class="fOTKLw">
                                        <h2 class="fStkLY hLlol">
                                        <div class="fUkExK">
                                            <div class="cjYGBp fyKMRa dWSsmD">
                                            {{removeTags(newsFilter(news[index1].type)[2].title)}}
                                            </div>
                                            
                                        </div>
                                        </h2>
                                        <div class="dNaUyi">
                                        </div>
                                    </div>
                                    </a>
                                </article>
                                </div>
                            </div>
                            <div class="kaVeKu" *ngIf="this.id!=newsFilter(news[index1].type)[2].id && newsFilter(news[index1].type)[2].type=='Blog'"  (click)="changeId(newsFilter(news[index1].type)[2].id)" routerLink="/news-media-detail/{{newsFilter(news[index1].type)[2].id}}">
                                <div class="dJuwOf">
                                    <article class="emKZKu" routerLink="/news-media-detail/{{newsFilter(news[index1].type)[2].id}}">
                                        <a class="cnhNwd">
                                        <div class="fPRcLx">
                                            <div class="sc-bcXHqe fdUHvj snipcss0-5-5-6">
                                            <p color="blue" data-test-id="news-item-label" class="sc-csuSiG dpkuuw snipcss0-6-6-7"><img class="elogo" src="../../assets/img/logoeblue.png">-{{newsFilter(news[index1].type)[2].type}}</p>
                                            </div>
                                            <span color="newsItemContentTextColor" data-test-id="news-item-date" class="sc-eDWCr hbeOZQ snipcss0-5-5-8">{{newsFilter(news[index1].type)[2].created_at | date :  "MMM dd, yyyy"}}</span>
                                    
                                        </div>
                                        <div class="cXLmts">
                                            <img class="cqrtwT" [src]="this.service.getPathImage()+''+newsFilter(news[index1].type)[2].image">
                                        </div>
                                        <div class="fIlstS">
                                        </div>
                                        <div class="lahaJw">
                                            <h2 class="ceEGPo bhvNhv">
                                            <div class="clXQjQ">
                                                <div class="eEYrZX isIbmM ldoCqD">
                                                {{removeTags(newsFilter(news[index1].type)[2].title)}}
                                                </div>
                                            </div>
                                            </h2>
                                        </div>
                                        </a>
                                    </article>
                                </div>
                            </div>
                        </span>
                        <span *ngIf="newsFilter(news[index1].type)[3]">
                            <div class="kaVeKu" *ngIf="this.id!=newsFilter(news[index1].type)[3].id"  (click)="changeId(newsFilter(news[index1].type)[3].id)" routerLink="/news-media-detail/{{newsFilter(news[index1].type)[3].id}}">
                                <div class="dJuwOf">
                                <article [ngClass]="{'jRWwuk': newsFilter(news[index1].type)[3].type === 'Press Notes', 'jRWwuk1' : newsFilter(news[index1].type)[3].type === 'Press Release', 'jRWwuk2' : newsFilter(news[index1].type)[3].type === 'In the Media'  }">
                                    <a class="kEzltY">
                                    <div class="PctUQ">
                                        <div class="fdUHvj">
                                        <p class="dpkuuw">
                                            {{newsFilter(news[index1].type)[3].type}}
                                        </p>
                                        </div>
                                        <span class="hbeOZQ">
                                        {{newsFilter(news[index1].type)[3].created_at | date :  "MMM dd, yyyy"}}
                                        </span>
                                    </div>
                                    <div class="fOTKLw">
                                        <h2 class="fStkLY hLlol">
                                        <div class="fUkExK">
                                            <div class="cjYGBp fyKMRa dWSsmD">
                                            {{removeTags(newsFilter(news[index1].type)[3].title)}}
                                            </div>
                                            
                                        </div>
                                        </h2>
                                        <div class="dNaUyi">
                                        </div>
                                    </div>
                                    </a>
                                </article>
                                </div>
                            </div>
                            <div class="kaVeKu" *ngIf="this.id!=newsFilter(news[index1].type)[3].id && newsFilter(news[index1].type)[3].type=='Blog'"  (click)="changeId(newsFilter(news[index1].type)[3].id)" routerLink="/news-media-detail/{{newsFilter(news[index1].type)[2].id}}">
                                <div class="dJuwOf">
                                    <article class="emKZKu" routerLink="/news-media-detail/{{newsFilter(news[index1].type)[2].id}}">
                                        <a class="cnhNwd">
                                        <div class="fPRcLx">
                                            <div class="sc-bcXHqe fdUHvj snipcss0-5-5-6">
                                            <p color="blue" data-test-id="news-item-label" class="sc-csuSiG dpkuuw snipcss0-6-6-7"><img class="elogo" src="../../assets/img/logoeblue.png">-{{newsFilter(news[index1].type)[2].type}}</p>
                                            </div>
                                            <span color="newsItemContentTextColor" data-test-id="news-item-date" class="sc-eDWCr hbeOZQ snipcss0-5-5-8">{{newsFilter(news[index1].type)[2].created_at | date :  "MMM dd, yyyy"}}</span>
                                    
                                        </div>
                                        <div class="cXLmts">
                                            <img class="cqrtwT" [src]="this.service.getPathImage()+''+newsFilter(news[index1].type)[2].image">
                                        </div>
                                        <div class="fIlstS">
                                        </div>
                                        <div class="lahaJw">
                                            <h2 class="ceEGPo bhvNhv">
                                            <div class="clXQjQ">
                                                <div class="eEYrZX isIbmM ldoCqD">
                                                {{removeTags(newsFilter(news[index1].type)[2].title)}}
                                                </div>
                                            </div>
                                            </h2>
                                        </div>
                                        </a>
                                    </article>
                                </div>
                            </div>
                        </span>
                    </div>
      
                    <div class="item" *ngIf="newsFilter(news[index1].type)[4]">
                        <span *ngIf="newsFilter(news[index1].type)[0]"><div [ngClass]="{'kaVeKu1': newsFilter(news[index1].type).length === 3,'kaVeKu2': newsFilter(news[index1].type).length === 4}"></div></span>
                        <span *ngIf="newsFilter(news[index1].type)[2]">
                            <div class="kaVeKu" *ngIf="this.id!=newsFilter(news[index1].type)[2].id"  (click)="changeId(newsFilter(news[index1].type)[2].id)" routerLink="/news-media-detail/{{newsFilter(news[index1].type)[2].id}}">
                                <div class="dJuwOf">
                                <article [ngClass]="{'jRWwuk': newsFilter(news[index1].type)[2].type === 'Press Notes', 'jRWwuk1' : newsFilter(news[index1].type)[2].type === 'Press Release', 'jRWwuk2' : newsFilter(news[index1].type)[2].type === 'In the Media'  }">
                                    <a class="kEzltY">
                                    <div class="PctUQ">
                                        <div class="fdUHvj">
                                        <p class="dpkuuw">
                                            {{newsFilter(news[index1].type)[2].type}}
                                        </p>
                                        </div>
                                        <span class="hbeOZQ">
                                        {{newsFilter(news[index1].type)[2].created_at | date :  "MMM dd, yyyy"}}
                                        </span>
                                    </div>
                                    <div class="fOTKLw">
                                        <h2 class="fStkLY hLlol">
                                        <div class="fUkExK">
                                            <div class="cjYGBp fyKMRa dWSsmD">
                                            {{removeTags(newsFilter(news[index1].type)[2].title)}}
                                            </div>
                                            
                                        </div>
                                        </h2>
                                        <div class="dNaUyi">
                                        </div>
                                    </div>
                                    </a>
                                </article>
                                </div>
                            </div>
                            <div class="kaVeKu" *ngIf="this.id!=newsFilter(news[index1].type)[2].id && newsFilter(news[index1].type)[2].type=='Blog'"  (click)="changeId(newsFilter(news[index1].type)[2].id)" routerLink="/news-media-detail/{{newsFilter(news[index1].type)[2].id}}">
                                <div class="dJuwOf">
                                    <article class="emKZKu" routerLink="/news-media-detail/{{newsFilter(news[index1].type)[2].id}}">
                                        <a class="cnhNwd">
                                        <div class="fPRcLx">
                                            <div class="sc-bcXHqe fdUHvj snipcss0-5-5-6">
                                            <p color="blue" data-test-id="news-item-label" class="sc-csuSiG dpkuuw snipcss0-6-6-7"><img class="elogo" src="../../assets/img/logoeblue.png">-{{newsFilter(news[index1].type)[2].type}}</p>
                                            </div>
                                            <span color="newsItemContentTextColor" data-test-id="news-item-date" class="sc-eDWCr hbeOZQ snipcss0-5-5-8">{{newsFilter(news[index1].type)[2].created_at | date :  "MMM dd, yyyy"}}</span>
                                    
                                        </div>
                                        <div class="cXLmts">
                                            <img class="cqrtwT" [src]="this.service.getPathImage()+''+newsFilter(news[index1].type)[2].image">
                                        </div>
                                        <div class="fIlstS">
                                        </div>
                                        <div class="lahaJw">
                                            <h2 class="ceEGPo bhvNhv">
                                            <div class="clXQjQ">
                                                <div class="eEYrZX isIbmM ldoCqD">
                                                {{removeTags(newsFilter(news[index1].type)[2].title)}}
                                                </div>
                                            </div>
                                            </h2>
                                        </div>
                                        </a>
                                    </article>
                                </div>
                            </div>
                        </span>
                        <span *ngIf="newsFilter(news[index1].type)[3]">
                            <div class="kaVeKu" *ngIf="this.id!=newsFilter(news[index1].type)[3].id"  (click)="changeId(newsFilter(news[index1].type)[3].id)" routerLink="/news-media-detail/{{newsFilter(news[index1].type)[3].id}}">
                                <div class="dJuwOf">
                                <article [ngClass]="{'jRWwuk': newsFilter(news[index1].type)[3].type === 'Press Notes', 'jRWwuk1' : newsFilter(news[index1].type)[3].type === 'Press Release', 'jRWwuk2' : newsFilter(news[index1].type)[3].type === 'In the Media'  }">
                                    <a class="kEzltY">
                                    <div class="PctUQ">
                                        <div class="fdUHvj">
                                        <p class="dpkuuw">
                                            {{newsFilter(news[index1].type)[3].type}}
                                        </p>
                                        </div>
                                        <span class="hbeOZQ">
                                        {{newsFilter(news[index1].type)[3].created_at | date :  "MMM dd, yyyy"}}
                                        </span>
                                    </div>
                                    <div class="fOTKLw">
                                        <h2 class="fStkLY hLlol">
                                        <div class="fUkExK">
                                            <div class="cjYGBp fyKMRa dWSsmD">
                                            {{removeTags(newsFilter(news[index1].type)[3].title)}}
                                            </div>
                                            
                                        </div>
                                        </h2>
                                        <div class="dNaUyi">
                                        </div>
                                    </div>
                                    </a>
                                </article>
                                </div>
                            </div>
                            <div class="kaVeKu" *ngIf="this.id!=newsFilter(news[index1].type)[3].id && newsFilter(news[index1].type)[3].type=='Blog'"  (click)="changeId(newsFilter(news[index1].type)[2].id)" routerLink="/news-media-detail/{{newsFilter(news[index1].type)[3].id}}">
                                <div class="dJuwOf">
                                    <article class="emKZKu" routerLink="/news-media-detail/{{newsFilter(news[index1].type)[2].id}}">
                                        <a class="cnhNwd">
                                        <div class="fPRcLx">
                                            <div class="sc-bcXHqe fdUHvj snipcss0-5-5-6">
                                            <p color="blue" data-test-id="news-item-label" class="sc-csuSiG dpkuuw snipcss0-6-6-7"><img class="elogo" src="../../assets/img/logoeblue.png">-{{newsFilter(news[index1].type)[2].type}}</p>
                                            </div>
                                            <span color="newsItemContentTextColor" data-test-id="news-item-date" class="sc-eDWCr hbeOZQ snipcss0-5-5-8">{{newsFilter(news[index1].type)[2].created_at | date :  "MMM dd, yyyy"}}</span>
                                    
                                        </div>
                                        <div class="cXLmts">
                                            <img class="cqrtwT" [src]="this.service.getPathImage()+''+newsFilter(news[index1].type)[2].image">
                                        </div>
                                        <div class="fIlstS">
                                        </div>
                                        <div class="lahaJw">
                                            <h2 class="ceEGPo bhvNhv">
                                            <div class="clXQjQ">
                                                <div class="eEYrZX isIbmM ldoCqD">
                                                {{removeTags(newsFilter(news[index1].type)[2].title)}}
                                                </div>
                                            </div>
                                            </h2>
                                        </div>
                                        </a>
                                    </article>
                                </div>
                            </div>
                        </span>
                        <span *ngIf="newsFilter(news[index1].type)[4]">
                            <div class="kaVeKu" *ngIf="this.id!=newsFilter(news[index1].type)[4].id"  (click)="changeId(newsFilter(news[index1].type)[4].id)" routerLink="/news-media-detail/{{newsFilter(news[index1].type)[4].id}}">
                                <div class="dJuwOf">
                                <article [ngClass]="{'jRWwuk': newsFilter(news[index1].type)[4].type === 'Press Notes', 'jRWwuk1' : newsFilter(news[index1].type)[4].type === 'Press Release', 'jRWwuk2' : newsFilter(news[index1].type)[4].type === 'In the Media'  }">
                                    <a class="kEzltY">
                                    <div class="PctUQ">
                                        <div class="fdUHvj">
                                        <p class="dpkuuw">
                                            {{newsFilter(news[index1].type)[4].type}}
                                        </p>
                                        </div>
                                        <span class="hbeOZQ">
                                        {{newsFilter(news[index1].type)[4].created_at | date :  "MMM dd, yyyy"}}
                                        </span>
                                    </div>
                                    <div class="fOTKLw">
                                        <h2 class="fStkLY hLlol">
                                        <div class="fUkExK">
                                            <div class="cjYGBp fyKMRa dWSsmD">
                                            {{removeTags(newsFilter(news[index1].type)[4].title)}}
                                            </div>
                                            
                                        </div>
                                        </h2>
                                        <div class="dNaUyi">
                                        </div>
                                    </div>
                                    </a>
                                </article>
                                </div>
                            </div>
                            <div class="kaVeKu" *ngIf="this.id!=newsFilter(news[index1].type)[4].id && newsFilter(news[index1].type)[4].type=='Blog'"  (click)="changeId(newsFilter(news[index1].type)[2].id)" routerLink="/news-media-detail/{{newsFilter(news[index1].type)[4].id}}">
                                <div class="dJuwOf">
                                    <article class="emKZKu" routerLink="/news-media-detail/{{newsFilter(news[index1].type)[2].id}}">
                                        <a class="cnhNwd">
                                        <div class="fPRcLx">
                                            <div class="sc-bcXHqe fdUHvj snipcss0-5-5-6">
                                            <p color="blue" data-test-id="news-item-label" class="sc-csuSiG dpkuuw snipcss0-6-6-7"><img class="elogo" src="../../assets/img/logoeblue.png">-{{newsFilter(news[index1].type)[2].type}}</p>
                                            </div>
                                            <span color="newsItemContentTextColor" data-test-id="news-item-date" class="sc-eDWCr hbeOZQ snipcss0-5-5-8">{{newsFilter(news[index1].type)[2].created_at | date :  "MMM dd, yyyy"}}</span>
                                    
                                        </div>
                                        <div class="cXLmts">
                                            <img class="cqrtwT" [src]="this.service.getPathImage()+''+newsFilter(news[index1].type)[2].image">
                                        </div>
                                        <div class="fIlstS">
                                        </div>
                                        <div class="lahaJw">
                                            <h2 class="ceEGPo bhvNhv">
                                            <div class="clXQjQ">
                                                <div class="eEYrZX isIbmM ldoCqD">
                                                {{removeTags(newsFilter(news[index1].type)[2].title)}}
                                                </div>
                                            </div>
                                            </h2>
                                        </div>
                                        </a>
                                    </article>
                                </div>
                            </div>
                        </span>
                    </div>
                </div>
                </div>
                <div class="col-lg-2">
                    </div>
               </div>
    
          
             
              <a class="carousel-control-prev" href="#myCarousel1" role="button" data-slide="prev">
                <span class="carousel-control-prev-icon1" aria-hidden="true"></span>
                <span class="sr-only">Previous</span>
              </a>
              <a class="carousel-control-next" href="#myCarousel1" role="button" data-slide="next">
                <span class="carousel-control-next-icon1" aria-hidden="true"></span>
                <span class="sr-only">Next</span>
              </a>
            </div>
</div>

    </div>
    
<app-newsletter></app-newsletter>
    <app-footer></app-footer>
</div>
</div>